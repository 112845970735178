const TYPE_DESCRIPTIONS = {
  press: '｜ Press Release',
  latest: '｜ Latest information',
  notice: '｜ Notice',
};

const BASE_INFORMATION = [
  {
    imageSrc: '/2024-12-12_1048.webp',
    title: 'Thu. 19 Dec. 2024 OWNDAYS Le Quest Outlet Store New Open!',
    date: `2024.12.12`,
    link: '/sg/en/information/shops/1048',
    type: 'latest',
    alt: 'OWNDAYS Le Quest Outlet',
  },
  {
    imageSrc: '/2024-10-31_5002.webp',
    title: 'Announcement on Product Line Changes',
    date: `2024.10.31`,
    link: '/sg/en/information/5002',
    type: 'press',
  },
  {
    imageSrc: '/2024-08-14_1043.webp',
    title: 'Thu. 15 Aug. 2024 OWNDAYS Toa Payoh Store Now Open!',
    date: `2024.08.14`,
    link: '/sg/en/information/shops/1043',
    type: 'latest',
    alt: 'OWNDAYS Toa Payoh',
  },
  {
    imageSrc: '/2024-07-11_5001.webp',
    title:
      'OWNDAYS REVAMPS BRAND IDENTITY, DEBUTING NEW RETAIL DESIGN IN JAPAN AND SINGAPORE THIS JULY',
    date: `2024.07.11`,
    link: '/sg/en/information/5001',
    type: 'press',
  },
  {
    imageSrc: '/2023-05-08_432.webp',
    title: 'Tue. 9 May 2023 OWNDAYS Woodleigh Mall Store Now Open!',
    date: `2023.05.08`,
    link: '/sg/en/information/432',
    type: 'latest',
  },
  {
    imageSrc: '/2023-01-06_369.webp',
    title: '2023 Lunar New Year Operating Hours',
    date: `2023.01.06`,
    link: '/sg/en/information/369',
    type: 'notice',
  },
  {
    imageSrc: '/2022-05-17_287.webp',
    title: 'Fri. 20 May 2022 OWNDAYS Takashimaya S.C. Premium Concept Store Now Open!',
    date: `2022.05.17`,
    link: '/sg/en/information/287',
    type: 'latest',
  },
  {
    imageSrc: '/2022-01-14_244.webp',
    title: '2022 Lunar New Year Operating Hours',
    date: `2022.01.14`,
    link: '/sg/en/information/244',
    type: 'notice',
  },
  {
    imageSrc: '/2021-11-11_217.webp',
    title: 'Email Server Failure Notice',
    date: `2021.11.11`,
    link: '/sg/en/information/217',
    type: 'notice',
  },
  {
    imageSrc: '/2021-10-11_193.webp',
    title: 'Urgent System Maintenance Notification',
    date: `2021.10.11`,
    link: '/sg/en/information/193',
    type: 'notice',
  },
  {
    imageSrc: '/2021-09-22_184.webp',
    title: 'Wed. 29 Sep. 2021 OWNDAYS BUGIS JUNCTION Store Now Open!',
    date: `2021.09.22`,
    link: '/sg/en/information/184',
    type: 'latest',
  },
  {
    imageSrc: '/2021-08-03_162.webp',
    title: 'Urgent System Maintenance Notification',
    date: `2021.08.03`,
    link: '/sg/en/information/162',
    type: 'notice',
  },
  {
    imageSrc: '/2021-02-05_113.webp',
    title: '2021 Lunar New Year Operating Hours',
    date: `2021.02.05`,
    link: '/sg/en/information/113',
    type: 'notice',
  },
  {
    imageSrc: '/2021-02-04_112.webp',
    title: 'Fri. 5 Feb. 2021 OWNDAYS MBS Premium Concept Store Now Open',
    date: `2021.02.04`,
    link: '/sg/en/information/112',
    type: 'latest',
  },
  {
    imageSrc: '/2021-02-01_111.webp',
    title: 'Urgent System Maintenance Notification',
    date: `2021.02.01`,
    link: '/sg/en/information/111',
    type: 'notice',
  },
  {
    imageSrc: '/2020-12-17_97.webp',
    title: 'Wed. 23 Dec. 2020 OWNDAYS Parkway Parade Now Open',
    date: `2020.12.17`,
    link: '/sg/en/information/97',
    type: 'latest',
  },
  {
    imageSrc: '/2020-09-28_94.webp',
    title: 'Mon. 28 Sep. 2020 OWNDAYS Causeway Point Now Open',
    date: `2020.09.28`,
    link: '/sg/en/information/94',
    type: 'latest',
  },
];

export const INFORMATION_LIST = BASE_INFORMATION.map(item => ({
  ...item,
  description: `${item.date} ${TYPE_DESCRIPTIONS[item.type]}`,
}));

export const CATEGORY_LABELS = {
  all: 'ALL',
  latest: 'Latest information',
  notice: 'Notice',
  press: 'Press Release',
};
