import React, { useState, useEffect } from 'react';
import { INFORMATION_LIST, CATEGORY_LABELS } from './constants';
import './informationList.scss';
import { ContactInfo } from '../ContactInfo';

const InformationList = ({ baseImageUrl }) => {
  const getInitialCategory = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('category') || 'all';
  };

  const [activeCategory, setActiveCategory] = useState(getInitialCategory());
  const [filteredList, setFilteredList] = useState(INFORMATION_LIST);

  const ITEMS_PER_PAGE = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const handleSelectMenu = (category, e) => {
    e.preventDefault();
    setActiveCategory(category);
    setCurrentPage(1);
  };

  // Update list when category or page changes
  useEffect(() => {
    let newList = [];
    if (activeCategory === 'all') {
      newList = INFORMATION_LIST;
    } else {
      newList = INFORMATION_LIST.filter(item => {
        const categoryMap = {
          latest: 'latest',
          press: 'press',
          notice: 'notice',
        };
        return item.type === categoryMap[activeCategory];
      });
    }

    // Apply pagination
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setFilteredList(newList.slice(startIndex, endIndex));

    // Update URL
    const url = new URL(window.location);
    if (activeCategory === 'all') {
      url.searchParams.delete('category');
    } else {
      url.searchParams.set('category', activeCategory);
    }
    window.history.pushState({}, '', url);
  }, [activeCategory, currentPage]);

  const totalPages = Math.ceil(
    (activeCategory === 'all'
      ? INFORMATION_LIST.length
      : INFORMATION_LIST.filter(item => item.type === activeCategory).length) / ITEMS_PER_PAGE
  );

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <ContactInfo />

      <main className="l-content">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb--2">
            <li className="breadcrumb-item">
              <a href="/sg/en">Homepage</a>
            </li>
            <li aria-current="page" className="breadcrumb-item active">
              See all the Information
            </li>
          </ol>
        </nav>
        <div className="container">
          <section className="p-information p-information-list l-content--inner">
            <h1 className="c-title--en">Information</h1>

            <section className="p-information-list__categories">
              <ul>
                {Object.entries(CATEGORY_LABELS).map(([category, label]) => (
                  <li key={category} className={activeCategory === category ? 'is-current' : ''}>
                    <a
                      href={`/sg/en/information${
                        category === 'all' ? '' : `?category=${category}`
                      }`}
                      onClick={e => handleSelectMenu(category, e)}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </section>

            <section className="p-information-list__main">
              <ul className="p-information-list__block">
                {filteredList.map(item => (
                  <li key={item.link} data-category={item.type}>
                    <a href={item.link}>
                      <img
                        alt={item?.alt ? item?.alt : item?.title}
                        src={`${baseImageUrl}${item.imageSrc}`}
                        onError={e => {
                          e.target.src = `${baseImageUrl}/no-image.webp`;
                        }}
                      />
                    </a>
                    <div>
                      <p className="days">{item.description}</p>
                      <a href={item.link}>
                        <h3>{item.title}</h3>
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="p-information-list__pagination">
                <button
                  className={`prev-button ${currentPage === 1 ? 'disabled' : ''}`}
                  disabled={currentPage === 1}
                  onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                >
                  ‹
                </button>

                {Array.from({ length: totalPages }, (_, i) => i + 1)
                  .filter(page => {
                    return (
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1 ||
                      (page === 2 && currentPage <= 4) ||
                      (page === totalPages - 1 && currentPage >= totalPages - 3)
                    );
                  })
                  .map((page, index, array) => {
                    // Add ellipsis
                    if (index > 0 && page - array[index - 1] > 1) {
                      return [
                        <button key={`ellipsis-${page}`} disabled>
                          ...
                        </button>,
                        <button
                          key={page}
                          className={currentPage === page ? 'is-current' : ''}
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>,
                      ];
                    }
                    return (
                      <button
                        key={page}
                        className={currentPage === page ? 'is-current' : ''}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    );
                  })}

                <button
                  className={`next-button ${currentPage === totalPages ? 'disabled' : ''}`}
                  disabled={currentPage === totalPages}
                  onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                >
                  ›
                </button>
              </div>
            </section>
          </section>
        </div>
      </main>
    </>
  );
};

export { InformationList };
