import React from 'react';
import PropTypes from 'prop-types';

export const SnsFooter = ({ shareUrl }) => {
  return (
    <div className="l-product-details__sns-share">
      <ul className="sns-list">
        <li className="sns-item">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com${shareUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-facebook-square" />
          </a>
        </li>
        <li className="sns-item">
          <a
            href={`https://twitter.com/intent/tweet?url=https://www.owndays.com${shareUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-x-twitter" />
          </a>
        </li>
        <li className="sns-item">
          <a
            href={`https://line.me/R/msg/text/?https://www.owndays.com${shareUrl}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fab fa-line" />
          </a>
        </li>
      </ul>
    </div>
  );
};

SnsFooter.propTypes = {
  shareUrl: PropTypes.string.isRequired,
};
