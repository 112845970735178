import React, { useEffect } from 'react';
import AOS from 'aos';

import { HeaderOwndaysPc, BodyOwndaysPc, FooterOwndaysPc } from '../CommonComponents/OwndaysPc';

import '../CommonComponents/OwndaysPc/BaseOwndaysPc.scss';

const OwndaysPcHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-pc';

  useEffect(() => {
    AOS.init({ once: true, duration: 1000 });

    const handleScroll = () => {
      AOS.refresh(); // Refresh AOS on every scroll to ensure it triggers
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="base-owndays-pc__hidden-overflow-x">
        <HeaderOwndaysPc baseImgUrl={baseImgUrl} />

        <div className="base-owndays-pc__bg">
          <BodyOwndaysPc baseImgUrl={baseImgUrl} />
          <FooterOwndaysPc baseImgUrl={baseImgUrl} />
        </div>
      </div>
    </>
  );
};

export default OwndaysPcHTML;
