import React from 'react';
import { SHOPS_DATA, OPERATING_DATES } from './constants';
import './Lunar2022NewYearOperatingHours.scss';
import { ContactInfo } from '../ContactInfo';
import { LunarNewYearTable } from '../LunarNewYearTable';
import { SnsFooter } from '../SnsFooter';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export const Lunar2022NewYearOperatingHours = () => {
  const shareUrl = '/sg/en/information/244';
  const breadcrumbs = [
    { path: '/sg/en/information', label: 'See all the Information' },
    { path: '#', label: '2022 Lunar New Year Operating Hours' },
  ];

  return (
    <>
      <ContactInfo />
      <main className="lunar-new-year">
        <Breadcrumbs crumbs={breadcrumbs} />

        <div className="lunar-new-year__container">
          <section className="p-information p-information-details lunar-new-year__content--inner">
            <div className="p-information-details__content">
              <h1>2022 Lunar New Year Operating Hours</h1>

              <div className="p-information-details__inner">
                <h3>OWNDAYS Online Store</h3>
                <p>
                  Orders received from 6pm on 31 January to 02 February will be processed on 03
                  February.
                </p>

                <h3>Customer Service Hotline</h3>
                <p>
                  Customer Service Hotline will not be available from 6pm on 31 January to 02
                  February. Normal operating hours will resume on 03 February.
                </p>

                <h3>OWNDAYS Retail Stores</h3>
                <p className="u-mb__20">
                  Selected OWNDAYS stores will open throughout the Lunar New Year to serve your
                  eyewear needs. Please refer to the list below on each store's opening hours.
                </p>

                <LunarNewYearTable operatingDates={OPERATING_DATES} shopsData={SHOPS_DATA} />
              </div>

              <SnsFooter shareUrl={shareUrl} />

              <a className="back-button" href="/sg/en/information">
                <i className="fas fa-chevron-left"></i>Back
              </a>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
