import React from 'react';
import Slider from 'react-slick';

import './MainLineupFreakStore.scss';

export const MainLineupFreakStore = ({ baseImgUrl, data, isRowReversePC }) => {
  const mainSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    draggable: true,
    infinite: true,
    swipe: true,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
  };

  return (
    <div
      className={`main-lineup-freak-store__detail-2way ${
        isRowReversePC ? 'main-lineup-freak-store__detail-2way--row-reverse' : ''
      }`}
      data-aos="fade-up"
    >
      <div className="main-lineup-freak-store-slider" data-aos="fade-up" data-aos-duration="1000">
        <Slider {...mainSlider} className="main-lineup-freak-store__main-slide">
          {data.slides?.map((slide, index) => (
            <li key={index}>
              <picture>
                <source media="(min-width:768px)" srcSet={`${baseImgUrl}${slide.pc}`} />
                <img alt={data.subTitle} src={`${baseImgUrl}${slide.sp || slide.pc}`} />
              </picture>
            </li>
          ))}
        </Slider>
      </div>

      <div className="main-lineup-freak-store__content-slider">
        <div
          className={`main-lineup-freak-store__content-slider__title ${
            isRowReversePC ? 'main-lineup-freak-store__content-slider__title--reverse' : ''
          }`}
        >
          <p>
            <span>{data.title.small}</span>
          </p>
          <h2>
            {data.title.main.prefix && <span>{data.title.main.prefix}</span>}
            <span className="main-lineup-freak-store__content-slider__title--highlight">
              {data.title.main.highlight}
            </span>
            {data.title.main.text && <span>{data.title.main.text}</span>}
          </h2>
        </div>

        <div
          className={`main-lineup-freak-store__content-slider__detail ${
            isRowReversePC ? 'main-lineup-freak-store__content-slider__detail--reverse' : ''
          }`}
        >
          <h3 className="main-lineup-freak-store__content-slider__sub-title">{data.subTitle}</h3>
          <p className="main-lineup-freak-store__content-slider__message">{data.message}</p>
        </div>
      </div>
    </div>
  );
};
