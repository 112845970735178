import { generateShopsData, generateOperatingDates } from '../LunarNewYearStoreConstants';

// Default operating hours pattern for most stores
const DEFAULT_HOURS = {
  lunarEve: '11:00 - 18:00',
  day1: 'Closed',
  day2: 'Closed',
  day3: 'Business as usual',
};

// Special operating patterns
const SPECIAL_HOURS = {
  ION_JEWEL_PATTERN: {
    ...DEFAULT_HOURS,
    lunarEve: '10:00 - 18:00',
  },
  OPEN_PATTERN: {
    ...DEFAULT_HOURS,
    day1: '12:00 - 21:00',
    day2: '12:00 - 22:00',
  },
  MBS_PATTERN: {
    lunarEve: '11:00 - 22:00',
    day1: '11:00 - 22:00',
    day2: '11:00 - 22:00',
    day3: 'Business as usual',
  },
  JEWEL_PATTERN: {
    lunarEve: '10am - 6pm',
    day1: '12:00 - 21:00',
    day2: '12:00 - 21:00',
    day3: 'Business as usual',
  },
};

const DATES_2022 = {
  lunarEve: '31 January',
  day1: '1 February',
  day2: '2 February',
  day3: '3 February',
};

export const SHOPS_DATA = generateShopsData(DEFAULT_HOURS, SPECIAL_HOURS);
export const OPERATING_DATES = generateOperatingDates(DATES_2022);
