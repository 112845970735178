import React from 'react';
import './UrgentSystemOct2021.scss';
import { ContactInfo } from '../ContactInfo';
import { SnsFooter } from '../SnsFooter';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export const UrgentSystemOct2021 = () => {
  const shareUrl = '/sg/en/information/217';
  const BREADCRUMBS = [
    { path: '/sg/en/information', label: 'See all the Information' },
    { path: '#', label: 'Urgent System Maintenance Notification' },
  ];

  return (
    <>
      <ContactInfo />
      <main className="urgent-system">
        <Breadcrumbs crumbs={BREADCRUMBS} />
        <div className="urgent-system__container">
          <section className="p-information p-information-details urgent-system__content--inner">
            <div className="p-information-details__content">
              <h1>Urgent System Maintenance Notification</h1>

              <div className="p-information-details__inner">
                <p>
                  Please note that we have scheduled a server upgrade at the following date and
                  time.
                  <br />
                  During this time, our website will be unavailable.
                </p>

                <h3>Date/Time</h3>

                <p>
                  12 October 2021 (Tuesday) from 0:00 to 2:00
                  <br />
                  <small>
                    *The scheduled completion time is subject to change without prior notice
                  </small>
                </p>

                <p>
                  We apologise for any inconvenience caused and thank you for your kind
                  understanding.
                </p>
              </div>

              <SnsFooter shareUrl={shareUrl} />

              <a className="back-button" href="/sg/en/information">
                <i className="fas fa-chevron-left"></i>Back
              </a>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
