import React, { useState, useEffect } from 'react';

import './LensColourFreakStore.scss';

export const LensColourFreakStore = ({ baseImgUrl, list, model, selectedKey = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(list[0]?.key);
  const [stepImages, setStepImages] = useState(list[0]?.stepImages);
  const [stepLens, setStepLens] = useState(list[0]?.stepLens);
  const [descList, setDescList] = useState(list[0]?.descList);

  const onSelectedTab = key => {
    setSelectedTab(key);
  };

  const renderDesc = () => {
    let newDescList = descList;

    if (model === 'FK2002N-4S') {
      newDescList = list.find(({ key }) => key === selectedKey).descList;
    }

    return (
      <ul className="lens-colour-freak-store__desc-list">
        {newDescList.map(({ title, value }, index) => {
          return (
            <li key={index}>
              <h5 className="lens-colour-freak-store__desc-list__title">{title}</h5>
              <p className="lens-colour-freak-store__desc-list__value">{value}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    const len = list.find(({ key }) => key === selectedTab);

    console.log('onSelectedTab', len);

    setStepImages(len.stepImages);
    setStepLens(len.stepLens);
    setDescList(len.descList);
  }, [list, selectedTab]);

  if (!list) {
    return null;
  }

  return model === 'FK2002N-4S' ? (
    <div className="lens-colour-freak-store__desc-list--only-one">{renderDesc()}</div>
  ) : (
    <div className="lens-colour-freak-store">
      <div className="lens-colour-freak-store__title">
        <h2>LENS COLOUR</h2>
      </div>

      <ul className="lens-colour-freak-store__tabs">
        {list?.map(({ key }) => (
          <li key={key} onClick={() => onSelectedTab(key)}>
            <a className={`${key === selectedTab ? 'lens-colour-freak-store__tabs--active' : ''}`}>
              <span>{key}</span>
            </a>
          </li>
        ))}
      </ul>

      <div className="lens-colour-freak-store__content">
        <ul className="lens-colour-freak-store__step-images">
          {stepImages.map(({ src }, index) => (
            <li key={index}>
              <img alt={`${model} ${selectedTab}`} src={`${baseImgUrl}${src}`} />
            </li>
          ))}
        </ul>

        <ul className="lens-colour-freak-store__step-lens">
          {stepLens.map(({ name }) => (
            <li key={name}>{name}</li>
          ))}
        </ul>

        {renderDesc()}
      </div>
    </div>
  );
};
