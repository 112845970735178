import React from 'react';
import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';
import '../../../../theme/pages/campaign.scss';
import './BlackFriday2024.scss';

const BlackFriday2024 = () => {
  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'Black Friday Spectacle' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="campaign-page campaign-page--black-friday-2024">
        <div className="campaign-page__container">
          <h1 className="campaign-page__title">Black Friday Spectacle</h1>
        </div>
        <img
          alt="OWNDAYS Black Friday Spectacle"
          className="campaign-page__thumbnail"
          loading="lazy"
          src="https://static1.lenskart.com/media/owndays/img/news/black-friday-2024/thumbnail.webp"
        />
        <div className="campaign-page__container">
          <p className="campaign-page__text">
            <strong>Duration:</strong> 25 November - 2 December 2024
          </p>
          <p className="campaign-page__text">
            Up to 50% Off on Select Glasses & Sunglasses - Grab Your Perfect Pair Before They're
            Gone!
            <small className="campaign-page__text-comment">* While stocks last. T&Cs apply.</small>
          </p>
          <a className="button button--secondary" href="/sg/en/campaign/black-friday.html">
            See All Products
          </a>
          <h2 className="campaign-page__subtitle">Terms & Conditions</h2>
          <ul className="campaign-page__terms">
            <li>Items are available while stocks last</li>
            <li>Items sold are not refundable nor exchangeable</li>
            <li>Frame prices are final</li>
            <li>
              Regular top up fee applies for optional lens upgrades in which the standard 1-year
              warranty on lens visual performance will be valid
            </li>
            <li>Offer is valid at the OWNDAYS online store only</li>
            <li>Standard OWNDAYS T&Cs also apply for products in this campaign</li>
            <li>
              Offer cannot be used in conjunction with other discounts, vouchers and/ or promotions
            </li>
            <li>
              The management reserves the right to amend the offer and the terms and conditions
              without prior notice
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};

export default BlackFriday2024;
