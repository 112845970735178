import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">
    <link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
    <link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
    <link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
    <link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
    <link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
    <link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
    <link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
    <link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
    <link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
    <link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
    <link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
    <link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
    <link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
    <link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
    <link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="Site Map | OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                    <meta name="description" content="OWNDAYS Online Store Site Map Page.">
                                            <meta name="keywords" content="Site Map,glasses,spectacles,optical shop,online store,eyeglasses,OWNDAYS,frame,sunglasses">
                                                                                                        <meta property="og:description" content="OWNDAYS Online Store Site Map Page.">
                                            <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/sitemap">
                <meta property="og:image" content="https://www.owndays.com/images/common/ogp_1200x630.jpg?1690859228">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
        <link href="https://www.owndays.com/web/css/sitemap.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    Site Map | OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
    <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/sitemap">Site Map</a>
                                                                                </li>
        
            </ul>
</div>

    <div class="l-guide-sitemap">
        <div class="p-main">
            <div class="p-main__title">
                <h1>SITE MAP</h1>
            </div>
        </div>
        <section id="glasses">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>Frames</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul class="no-partition">
                        <li><a href="/sg/en/eyeglasses">Frames Overview</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1">All Frames</a></li>
                    </ul>
                    <h3>Frame Shape</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=1">Rectangle Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=12">Boston Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=2">Oval Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=14">Browline Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=3">Round Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=16">Rimless Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=11">Wellington Frames</a></li>
                                                    <li><a href="/sg/en/products?productTypes[]=1&frameTypes[]=18&frameTypes[]=19&frameTypes[]=20&frameTypes[]=21">Other Frames</a></li>
                                            </ul>
                    <h3>Gender</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=1&targetMarkets[]=1">Frames for Men</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&targetMarkets[]=2">Frames for Women</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&targetMarkets[]=3">Frames for Kids</a></li>
                    </ul>
                    <h3>Frame Material</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=1">Plastic Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=2">Metal Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=3">Resin Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=4">Celluloid Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=5">Titanium Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=6">Stainless Steel Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&materials[]=11">Frames made of Other Materials</a></li>
                    </ul>
                    <h3>Frame Colour</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=1">Clear Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=2">White Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=3">Grey Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=4">Black Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=5">Orange Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=6">Yellow Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=7">Green Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=8">Blue Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=9">Brown Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&olorAttributes[]=10">Gold Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=11">Silver Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=12">Red Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=13">Purple Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=14">Pink Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=15">Beige Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=1&colorAttributes[]=16">Patterned Frames</a></li>
                    </ul>
                </dd>
            </dl>
        </section>
        <section id="sunglasses">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>Sunglasses</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul class="no-partition">
                        <li><a href="/sg/en/sunglasses">Sunglasses Overview</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2">All Sunglasses</a></li>
                    </ul>
                    <h3>Frame Shape</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=1">Rectangle Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=12">Boston Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=2">Oval Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=14">Browline Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=3">Round Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=16">Rimless Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=11">Wellington Frames</a></li>
                                                    <li><a href="/sg/en/products?productTypes[]=2&frameTypes[]=18&frameTypes[]=19&frameTypes[]=20&frameTypes[]=21">Other Frames</a></li>
                                            </ul>
                    <h3>Gender</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=2&targetMarkets[]=1">Frames for Men</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&targetMarkets[]=2">Frames for Women</a></li>

                    </ul>
                    <h3>Frame Material</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=1">Plastic Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=2">Metal Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=3">Resin Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=4">Celluloid Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=5">Titanium Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=6">Stainless Steel Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&materials[]=11">Frames made of Other Materials</a></li>
                    </ul>
                    <h3>Frame Colour</h3>
                    <ul>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=1">Clear Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=2">White Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=3">Grey Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=4">Black Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=5">Orange Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=6">Yellow Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=7">Green Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=8">Blue Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=9">Brown Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=10">Gold Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=11">Silver Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=12">Red Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=13">Purple Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=14">Pink Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=15">Beige Frames</a></li>
                        <li><a href="/sg/en/products?productTypes[]=2&colorAttributes[]=16">Patterned Frames</a></li>
                    </ul>
                </dd>
            </dl>
        </section>
        <section id="brand">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>Collections</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul class="no-partition">
                        <li><a href="/sg/en/brands">All Collections</a></li>
                    </ul>
                    <h3>All Collections</h3>
                    <ul>
                        <li><a href="/sg/en/products?productLines[]=32">All +NICHE Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=16">All AIR Ultem Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=24">All OWNDAYS SNAP Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=1">All john Dillinger Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=9">All Graph Belle Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=13">All FUWA CELLU Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=30">All lillybell Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=15">All OWNDAYS PC Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=6">All K.moriyama Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=10">All AIR FIT Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=2">All Sen-Ichi Saku Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=21">All eco²xy Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=5">All Junni Frames</a></li>
                        <li><a href="/sg/en/products?productLines[]=3">All Based Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=29">All OWNDAYS Frames</a></li>
                                                <li><a href="/sg/en/products?productLines[]=35">All DEMON SLAYER -KIMETSU NO YAIBA-</a></li>
                                                                                                <li><a href="/sg/en/products?productLines[]=48">All Kuromi × OWNDAYS</a></li>
                                                                        <li><a href="/sg/en/products?productLines[]=45">All OWNDAYS × HUAWEI Eyewear</a></li>
                                                                        <li><a href="/sg/en/products?productLines[]=46">All Pokémon Frame Collection</a></li>
                                                                    </ul>
                </dd>
            </dl>
        </section>
        <section id="service">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>Services</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul>
                        <li><a href="/sg/en/services">All Services</a></li>
                        <li><a href="/sg/en/services/warranty">OWNDAYS Customer Guarantees</a></li>
                        <li><a href="/sg/en/services/lens">Lens Guide</a></li>
                        <li><a href="/sg/en/services/staff">Staff</a></li>
                        <li><a href="/sg/en/services/lens-replacement">Lens Replacement</a></li>
                                            </ul>
                </dd>
            </dl>
        </section>
        <section id="support">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>Support</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul>
                        <li><a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops </a></li>
                        <li><a href="/sg/en/faq">FAQ</a></li>
                        <li><a href="/sg/en/contact">Contact Us</a></li>
                                                    <li><a href="/sg/en/services/onlinestore">New Users</a></li>
                            <li><a href="/sg/en/register">Sign Up an Account</a></li>
                            <li><a href="/sg/en/login">Log In</a></li>
                            <li><a href="/sg/en/requirement">System Requirements</a></li>
                                                <li><a href="/sg/en/sitemap">Site Map</a></li>
                    </ul>
                </dd>
            </dl>
        </section>
        <section id="about">
            <dl class="l-guide-sitemap__list">
                <dt class="l-guide-sitemap__list--genre"><h2>About</h2> <span class="plus"></span></dt>
                <dd class="l-guide-sitemap__list--links">
                    <ul>
                                                    <li><a href="/sg/en/account">OWNDAYS MEMBERSHIP</a></li>
                                                                        <li><a href="/sg/en/services/care">OWNDAYS care+</a></li>
                                                <li><a href="/sg/en/company">Corporate Information</a></li>
                        <li><a href="/sg/en/company/eyecamp">EYE CAMP</a></li>
                                                <li><a href="https://www.odsaiyou.com/sg/" target="_blank">Recruitment</a></li>
                                                                        <li><a href="/sg/en/privacy">Privacy Policy</a></li>
                        <li><a href="/sg/en/terms">Terms & Conditions</a></li>
                        <li><a href="/sg/en/membership-terms">OWNDAYS Membership Programme Terms and Conditions</a></li>
                                            </ul>
                </dd>
            </dl>
        </section>
    </div>

        </main>
            </div>

  
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
    <script src="https://www.owndays.com/web/js/sitemap.js" defer></script>

</body>

</html>`;

const SiteMapHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Navigate effortlessly through OWNDAYS with our comprehensive site map. Discover eyewear, offers, and more. Your perfect pair is a click away. Explore now!',
          },
          {
            name: 'twitter:description',
            content:
              'Navigate effortlessly through OWNDAYS with our comprehensive site map. Discover eyewear, offers, and more. Your perfect pair is a click away. Explore now!',
          },
          {
            name: 'og:title',
            content: 'Site Map | OWNDAYS Singapore Online Store',
          },
          {
            name: 'twitter:title',
            content: 'Site Map | OWNDAYS Singapore Online Store',
          },
        ]}
        description="Navigate effortlessly through OWNDAYS with our comprehensive site map. Discover eyewear, offers, and more. Your perfect pair is a click away. Explore now!"
        title="Site Map | OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default SiteMapHTML;
