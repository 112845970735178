import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { FREAKS_STORE_MENU } from './constants';

import './HeaderFreaksStore.scss';

export const HeaderFreaksStore = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: `OWNDAYS × FREAK'S STORE` },
  ];

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);
    if (element) {
      const topOffset = element.offsetTop;
      const customOffset = id === 'lineup' ? -100 : 100;

      window.scrollTo({
        top: topOffset + customOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToSection(window.location.hash);
  }, [window.location.hash]);

  const conceptHeaderText = `Elevate your everyday\nstyle with chic eyewear\nfrom the latest season`;

  const conceptHeader = (
    <span>
      {conceptHeaderText.split('\n').map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line.split(' ').map((word, wordIndex) => (
            <span key={wordIndex}>
              {word.split('').map((char, charIndex) => (
                <span
                  key={charIndex}
                  className="header-freaks-store__concept__animate-typing__text-split"
                  style={{
                    transitionDelay: `${
                      (lineIndex * line.length + wordIndex * word.length + charIndex) * 0.04
                    }s`,
                  }}
                >
                  {char}
                </span>
              ))}
              {wordIndex < line.split(' ').length - 1 && (
                <span
                  className="header-freaks-store__concept__animate-typing__text-split"
                  style={{ transitionDelay: `${(lineIndex * line.length + wordIndex) * 0.04}s` }}
                >
                  &nbsp;
                </span>
              )}
            </span>
          ))}
          {lineIndex < conceptHeaderText.split('\n').length - 1 && <br />}{' '}
        </React.Fragment>
      ))}
    </span>
  );

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-freaks-store__main">
        <picture className="header-freaks-store__main__bg" data-aos="fade" data-aos-duration="600">
          <source media="(min-width:1439px)" srcSet={`${baseImgUrl}/main-2x-pc.webp`} />
          <source media="(min-width:768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
          <img alt="Freak's Store" src={`${baseImgUrl}/main-sp.webp`} />
        </picture>

        <div className="header-freaks-store__main__logo-container">
          <h1
            className="header-freaks-store__main__logo"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-offset="-100"
          >
            <img alt="OWNDAYS x Freak's Store" src={`${baseImgUrl}/logo.svg`} />
          </h1>
        </div>
      </section>

      <section className="header-freaks-store__concept" id="concept">
        <div className="base-freaks-store__container">
          <h2
            data-aos
            className="header-freaks-store__concept__title header-freaks-store__concept__animate-typing"
            data-aos-offset="0"
          >
            {conceptHeader}
          </h2>
          <div data-aos="fade-up" data-aos-delay="800">
            <p>
              Joining forces with FREAK’S STORE, a Japanese select store with an American spirit at
              heart, we bring to you a capsule collection built on the concept of “items that bring
              abundance and excitement to your lifestyle”. Find eyewear in trendsetting styles from
              this season that you will have fun incorporating into different looks.
            </p>

            <a className="base-freaks-store__btn base-freaks-store__hide" href="">
              See Product Lineup
            </a>
          </div>
        </div>

        <a
          className="header-freaks-store__concept__vdo"
          data-fancybox="etc"
          href="https://youtu.be/k1uGNAeU-JY"
        >
          <picture>
            <source media="(min-width:1439px)" srcSet={`${baseImgUrl}/video-thumb-2x-pc.webp`} />
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/video-thumb-pc.webp`} />
            <img alt="video icon" src={`${baseImgUrl}/video-thumb-sp.webp`} />
          </picture>
        </a>
      </section>

      <section className="header-freaks-store__menu" id="menu">
        <div className="base-freaks-store__container">
          <ul>
            {FREAKS_STORE_MENU.map(({ id, href, icon, iconHover, title, desc, iconSize }) => (
              <li
                key={id}
                className={`header-freaks-store__menu__item header-freaks-store__menu__item--${id}`}
                onClick={() => scrollToSection(id)}
              >
                <span className="header-freaks-store__menu__icon">
                  <img
                    alt={title.toLowerCase()}
                    className=""
                    height={iconSize.height}
                    src={`${baseImgUrl}/${icon}`}
                    width={iconSize.width}
                  />
                  <img
                    alt={title.toLowerCase()}
                    className="header-freaks-store__menu__icon-hover"
                    height={iconSize.height}
                    src={`${baseImgUrl}/${iconHover}`}
                    width={iconSize.width}
                  />
                </span>
                <span className="header-freaks-store__menu__content">
                  <span className="header-freaks-store__menu__content-inner">
                    <h2 className="header-freaks-store__menu__content-title">{title}</h2>
                    <span className="header-freaks-store__menu__content-desc">{desc}</span>
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
