import React, { Fragment, useState } from 'react';
import Slider from 'react-slick';

import { RESIN, METAL, RESIN_LINEUP, METAL_LINEUP } from './constants';

import './BodyAir.scss';

export const BodyAir = ({ baseImgUrl }) => {
  const [linkAU2092T_2A, setLinkAU2092T_2A] = useState('/sg/en/air-au2092t-2a-c1-eyeglasses.html');
  const [linkAU2093T_2A, setLinkAU2093T_2A] = useState('/sg/en/air-au2093t-2a-c1-eyeglasses.html');
  const [linkAU2094T_2A, setLinkAU2094T_2A] = useState('/sg/en/air-au2094t-2a-c1-eyeglasses.html');

  const [linkMM1013B_3S, setLinkMM1013B_3S] = useState('/sg/en/air-mm1013b-3s-c1-eyeglasses.html');
  const [linkMM1014B_3S, setLinkMM1014B_3S] = useState('/sg/en/air-mm1014b-3s-c1-eyeglasses.html');
  const [linkMM1015B_3S, setLinkMM1015B_3S] = useState('/sg/en/air-mm1015b-3s-c1-eyeglasses.html');

  const [indexAU2092T_2A, setIndexLinkAU2092T_2A] = useState(1);
  const [indexAU2093T_2A, setIndexLinkAU2093T_2A] = useState(1);
  const [indexAU2094T_2A, setIndexLinkAU2094T_2A] = useState(1);

  const [indexMM1013B_3S, setIndexMM1013B_3S] = useState(1);
  const [indexMM1014B_3S, setIndexMM1014B_3S] = useState(1);
  const [indexMM1015B_3S, setIndexMM1015B_3S] = useState(1);

  const stylesSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    draggable: false,
    infinite: true,
    swipe: false,
    touchMove: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const defaultLineupSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: false,
    draggable: false,
    infinite: false,
    swipe: true,
    touchMove: true,
  };

  const renderLineupPagination = index => {
    return (
      <button>
        <img alt="C1" src={`${baseImgUrl}/colors/color-${index + 1}.webp`} />
      </button>
    );
  };

  const settingAU2092T_2A = {
    ...defaultLineupSetting,
    customPaging: i => renderLineupPagination(i),
    afterChange: async current => {
      await setLinkAU2092T_2A(`/sg/en/air-au2092t-2a-c${current + 1}-eyeglasses.html`);
      setIndexLinkAU2092T_2A(current + 1);
    },
  };

  const settingAU2093T_2A = {
    ...defaultLineupSetting,
    customPaging: i => renderLineupPagination(i + 8),
    afterChange: async current => {
      await setLinkAU2093T_2A(`/sg/en/air-au2093t-2a-c${current + 1}-eyeglasses.html`);
      setIndexLinkAU2093T_2A(current + 1);
    },
  };

  const settingAU2094T_2A = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 8,
        1: 12,
        2: 10,
        3: 13,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkAU2094T_2A(`/sg/en/air-au2094t-2a-c${current + 1}-eyeglasses.html`);
      setIndexLinkAU2094T_2A(current + 1);
    },
  };

  const settingMM1013B_3S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 4,
        2: 10,
        3: 5,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkMM1013B_3S(`/sg/en/air-mm1013b-3s-c${current + 1}-eyeglasses.html`);
      setIndexMM1013B_3S(current + 1);
    },
  };

  const settingMM1014B_3S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 6,
        1: 10,
        2: 7,
        3: 5,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkMM1014B_3S(`/sg/en/air-mm1014b-3s-c${current + 1}-eyeglasses.html`);
      setIndexMM1014B_3S(current + 1);
    },
  };

  const settingMM1015B_3S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 14,
        2: 16,
        3: 15,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkMM1015B_3S(`/sg/en/air-mm1015b-3s-c${current + 1}-eyeglasses.html`);
      setIndexMM1015B_3S(current + 1);
    },
  };

  const renderStyleTopAndFeature = item => {
    return (
      <div className="body-air__styles__item">
        <div className="body-air__styles__top">
          <div className="body-air__styles__top-img">
            <Slider pa {...stylesSetting} className="body-air__styles__top-img-slider">
              {item.images?.map(({ sp, pc, alt }, index) => (
                <picture key={index}>
                  <source media="(min-width: 768px)" srcSet={`${baseImgUrl}${pc}`} />
                  <img alt={alt} src={`${baseImgUrl}${sp}`} />
                </picture>
              ))}
            </Slider>
          </div>

          <div className="body-air__styles__top-text">
            <div className="base-air__container">
              <h2 data-aos>
                {item.header.map(({ title }) => (
                  <span key={title}>{title}</span>
                ))}
              </h2>
              <div className="body-air__styles__top-text-details">
                <h3 data-aos data-aos-delay="100">
                  <span>{item.frameType}</span>
                </h3>
                <p>{item.desc}</p>
                <div className="body-air__styles__top-text-details-img">
                  <span></span>
                  <img alt={item.detail.alt} src={`${baseImgUrl}${item.detail.src}`} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body-air__features">
          <div className="base-air__container">
            <h3>FEATURES</h3>
            <ul>
              {item.features.map(({ src, desc }, index) => (
                <li key={index} data-aos="fade-up" data-aos-delay="0">
                  <span>
                    <img alt={desc} src={`${baseImgUrl}${src}`} />
                  </span>
                  <small>#0{index + 1}</small>
                  <p>{desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="body-air__description">
          <div className="base-air__container">
            <a className="base-air__btn base-air__btn--primary" href={item.descSection.link}>
              See All Products
            </a>
            <h3 data-aos="fade-up">
              {item.descSection.title.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </h3>
            <p data-aos data-aos-delay="600">
              {item.descSection.desc}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderLineupSlide = model => {
    const array = [1, 2, 3, 4];
    const products = array.map((_, i) => (
      <img
        key={i}
        alt={`${model} C${i + 1}`}
        src={`${baseImgUrl}/products/${model}_C${i + 1}.webp`}
      />
    ));

    const lineupSettingsMap = {
      'AU2092T-2A': settingAU2092T_2A,
      'AU2093T-2A': settingAU2093T_2A,
      'AU2094T-2A': settingAU2094T_2A,
      'MM1013B-3S': settingMM1013B_3S,
      'MM1014B-3S': settingMM1014B_3S,
      'MM1015B-3S': settingMM1015B_3S,
    };

    const lineupSetting = lineupSettingsMap[model] || defaultLineupSetting;

    return <Slider {...lineupSetting}>{products}</Slider>;
  };

  const renderLineupCards = list => {
    return list.map(({ model, delay }) => {
      const linkMap = {
        'AU2092T-2A': linkAU2092T_2A,
        'AU2093T-2A': linkAU2093T_2A,
        'AU2094T-2A': linkAU2094T_2A,
        'MM1013B-3S': linkMM1013B_3S,
        'MM1014B-3S': linkMM1014B_3S,
        'MM1015B-3S': linkMM1015B_3S,
      };

      const newLink = linkMap[model] || '';

      const indexMap = {
        'AU2092T-2A': indexAU2092T_2A,
        'AU2093T-2A': indexAU2093T_2A,
        'AU2094T-2A': indexAU2094T_2A,
        'MM1013B-3S': indexMM1013B_3S,
        'MM1014B-3S': indexMM1014B_3S,
        'MM1015B-3S': indexMM1015B_3S,
      };

      const newIndex = indexMap[model] || '';

      return (
        <li
          key={model}
          className="body-air__lineup__item"
          data-aos="fade-left"
          data-aos-delay={delay}
          data-model={`${model} C${newIndex}`}
        >
          {renderLineupSlide(model)}
          <a className="base-air__btn base-air__btn--primary" href={newLink}>
            More Details
          </a>
        </li>
      );
    });
  };

  const renderLineup = cards => {
    return (
      <section className="body-air__lineup">
        <div className="base-air__container">
          <h3>LINEUP</h3>
          <ul className="body-air__lineup__list">{renderLineupCards(cards)}</ul>
        </div>
      </section>
    );
  };

  return (
    <Fragment>
      <section className="body-air__styles" id="resin">
        {renderStyleTopAndFeature(RESIN)}
      </section>

      {renderLineup(RESIN_LINEUP)}

      <section className="body-air__styles body-air__styles--reverse" id="metal">
        {renderStyleTopAndFeature(METAL)}
      </section>

      {renderLineup(METAL_LINEUP)}
    </Fragment>
  );
};
