import React, { Fragment } from 'react';

import { STORES, SHARE } from './constants';
import './FooterFreaksStore.scss';

export const FooterFreaksStore = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="footer-freaks-store__about">
        <div className="footer-freaks-store__about__main">
          <div className="base-freaks-store__container">
            <div className="footer-freaks-store__about__intro">
              <img
                alt="FREAK'S STORE"
                className="footer-freaks-store__about__intro__company-view"
                src={`${baseImgUrl}/about-store.webp`}
              />

              <div data-aos="fade-up" data-aos-duration="1500">
                <img
                  alt="FREAK’S STORE"
                  className="footer-freaks-store__about__intro__company-logo"
                  src={`${baseImgUrl}/about-logo-freaks.webp`}
                />
                <p className="footer-freaks-store__about__intro__text">
                  It all began with the aspiration of conveying the richness and excitement of the
                  American culture to the people in Japan back in 1986.
                  <br />
                  Advocating the idea of living life like an enthusiast, or some say a freak,
                  FREAK’S STORE offers a carefully-curated selection of apparels, arts and lifestyle
                  products that will enrich your way of living.
                </p>
                <a
                  className="footer-freaks-store__about__company-info__link"
                  href="https://www.daytona-park.com/shop/freaks_store/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="footer-freaks-store__about__company-info__link__text">
                    FREAK’S STORE Official Website
                  </span>
                  <img
                    alt="FREAK’S STORE Official Website"
                    className="footer-freaks-store__about__company-info__link__icon"
                    src={`${baseImgUrl}/about-link-icon.svg`}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-freaks-store__shop">
        <div className="base-freaks-store__container">
          <h2 className="base-freaks-store__title">
            <strong>AVAILABLE STORES</strong>
          </h2>
          <ul className="footer-freaks-store__shop__list">
            {STORES.map(store => (
              <li key={store.name}>
                <a href={store.link}>{store.name}</a>
              </li>
            ))}
          </ul>

          <div className="base-freaks-store__container">
            <div className="footer-freaks-store__shop__button">
              <a className="base-freaks-store__btn base-freaks-store__btn--black" href="/stores/sg">
                Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-freaks-store__share">
        <h3 className="base-freaks-store__title">
          <strong>SHARE</strong>
        </h3>
        <ul>
          {SHARE.map(({ link, className }, index) => (
            <li key={index}>
              <a href={link} rel="noreferrer" target="_blank">
                <i aria-hidden="true" className={className}></i>
              </a>
            </li>
          ))}
        </ul>
      </section>
    </Fragment>
  );
};
