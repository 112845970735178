import React from 'react';
import PropTypes from 'prop-types';
import './AuthPanel.scss';
import { Link } from 'react-router-dom';
import AuthModal from '../AuthModal/AuthModalLoadable';
import AuthModalNewLoadable from '../AuthModalNew/AuthModalNewLoadable';
import { deleteAllCookies } from '../../../utils/helper';

const AuthPanel = props => {
  const {
    dataLocale,
    section,
    loginStatus,
    userInfo,
    loginLoader,
    authenticate,
    registerActions,
    commonCallsAction,
    cartActions,
    redisCommonData,
    textClass = '',
    dropdownPositionY = 'bottom',
    dropdownPositionX = 'center',
    children,
  } = props;
  const {
    // MY_ORDERS,
    // MY_PRESCRIPTION,
    // MY_STORE_CREDIT,
    MY_DITTOS,
    ACCOUNT_INFORMATION,
    SPECSY,
    LOGOUT,
  } = dataLocale || {};

  const newLoginPopUp = !!(
    redisCommonData &&
    redisCommonData?.NEW_AUTH_POPUP_DESKTOP &&
    JSON.parse(redisCommonData?.NEW_AUTH_POPUP_DESKTOP).enable === 'ON'
  );

  const handleCTEvents = () => {
    // OD__CLEVERTAP__EVENT (cta_click)
  };

  return (
    <div className="">
      <div
        onClick={() => {
          if (window.dtm.LenskartRewamp)
            window.dtm.LenskartRewamp.header.click.headerClick(section, 'LOGIN', loginStatus);
        }}
      >
        {loginLoader ? (
          <p className="saving">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        ) : (
          <div>
            {loginStatus && userInfo !== null ? (
              <div className="auth-panel">
                {userInfo.result.firstName ? (
                  <div className={textClass}>
                    {userInfo.result.firstName}
                    <i className="fa fa-caret-down auth-panel__dropdown-toggler"></i>
                  </div>
                ) : (
                  <span className={textClass}>{SPECSY}</span>
                )}
                <div className="od-font-bold">
                  <div
                    className={`navbar-dropdown-menu auth-panel__dropdown-menu auth-panel__dropdown-menu--${dropdownPositionY} auth-panel__dropdown-menu--${dropdownPositionX}`}
                  >
                    <div className="auth-panel__dropdown-content">
                      {loginStatus === true ? (
                        <div>
                          {/* <Link
                            className="dropdown-list auth-panel__dropdown-list"
                            to="/customer/account/"
                          >
                            {MY_ORDERS}
                          </Link> */}
                          {/* <Link
                            className="dropdown-list auth-panel__dropdown-list"
                            to="/customer/prescriptions/"
                          >
                            {MY_PRESCRIPTION}
                          </Link> */}
                          {/* <Link
                            className="dropdown-list auth-panel__dropdown-list"
                            to="/customer/account/storecredit/"
                          >
                            {MY_STORE_CREDIT}
                          </Link> */}
                          {!redisCommonData?.ENABLE_CYGNUS && (
                            <Link
                              className="dropdown-list auth-panel__dropdown-list"
                              to="/customer/ditto-profile/"
                            >
                              {MY_DITTOS}
                            </Link>
                          )}
                          <Link
                            className="dropdown-list auth-panel__dropdown-list"
                            to="/customer/account/edit/"
                          >
                            {ACCOUNT_INFORMATION}
                          </Link>
                          <a
                            className="dropdown-list auth-panel__dropdown-list"
                            onClick={() => {
                              sessionStorage.removeItem('orderActivePage');
                              registerActions.logoutUser();
                              commonCallsAction.logoutUser();
                              cartActions.logoutUser();
                              deleteAllCookies();
                              window?.Freshbots?.resetCustomer(true);
                              if (window?.sprChat) {
                                window.sprChat('updateUserSettings');
                              }
                              document.location.href = '/';
                            }}
                          >
                            {LOGOUT}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="user-name"
                onClick={() => handleCTEvents()}
                onFocus={() => {
                  if (!newLoginPopUp) {
                    AuthModal.preload();
                  } else {
                    AuthModalNewLoadable.preload();
                  }
                }}
                onMouseOver={() => {
                  if (!newLoginPopUp) {
                    AuthModal.preload();
                  } else {
                    AuthModalNewLoadable.preload();
                  }
                }}
              >
                <span className={textClass} onClick={authenticate}>
                  {children}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AuthPanel.propTypes = {
  section: PropTypes.any,
  loginStatus: PropTypes.bool,
  userInfo: PropTypes.object,
  loginLoader: PropTypes.bool,
};

export default AuthPanel;
