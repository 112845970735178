import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderKuromiCollection.scss';

export const HeaderKuromiCollection = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/services', label: 'News' },
    { path: '#', label: 'Kuromi × OWNDAYS' },
  ];

  const scrollToIntro = () => {
    const introSection = document.getElementById('intro');
    if (introSection) {
      const topOffset = introSection.offsetTop;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <div className="header-kuromi-collection__checkered-pattern">
        <section className="header-kuromi-collection__main base-kuromi-collection__container">
          <div className="header-kuromi-collection__main__content" data-aos="fade-up">
            <h1>
              <img alt="Kuromi × OWNDAYS" src={`${baseImgUrl}/kuromi-x-owndays.svg`} />
            </h1>

            <figure data-aos="fade-up" data-aos-delay="100">
              <img alt="Kuromi × OWNDAYS" src={`${baseImgUrl}/main-image.svg`} />
            </figure>

            <div className="header-kuromi-collection__main__release-date">
              <p className="base-kuromi-collection__hide-pc">In-Store Release on</p>
              <h3 className="base-kuromi-collection__hide-pc">21 Oct 2022</h3>
              <p className="base-kuromi-collection__hide-sp">In-Store Release on 21 Oct 2022</p>
            </div>

            <img
              alt="#KUROMItheWorld"
              className="header-kuromi-collection__main__hashtag"
              src={`${baseImgUrl}/main-hashtag.svg`}
            />
          </div>

          <div className="header-kuromi-collection__main__scroll-layout" data-aos="fade-up">
            <a className="header-kuromi-collection__main__scroll-btn" onClick={scrollToIntro}>
              Scroll
            </a>
          </div>
        </section>

        <section className="header-kuromi-collection__intro" id="intro">
          <div className="base-kuromi-collection__container">
            <div className="header-kuromi-collection__intro__content-1" data-aos="fade-up">
              <div className="header-kuromi-collection__intro__content-1-inner">
                <img
                  alt=""
                  className="header-kuromi-collection__intro__content-1-top lazyload"
                  src={`${baseImgUrl}/wire-notebook.svg`}
                />
                <img
                  alt="Let’s #KUROMIfy girls who wear spectacles!"
                  className="header-kuromi-collection__intro__content-1-title lazyload"
                  src={`${baseImgUrl}/intro-1-title.svg`}
                />
                <p>
                  An OWNDAYS x Kuromi crossover in support of the{' '}
                  <img
                    alt=""
                    className="header-kuromi-collection__intro__content-1-highlight-text lazyload"
                    src={`${baseImgUrl}/intro-1-highlight-text.svg`}
                  />{' '}
                  project. Transform into the best self you can ever be with these cute,
                  accessory-like eyewear that are inspired by Kuromi and Baku!
                </p>
                <img
                  alt="Kuromi & Baku"
                  className="header-kuromi-collection__intro__content-1-character lazyload"
                  data-aos="fade-left"
                  data-aos-delay="200"
                  src={`${baseImgUrl}/intro-1-character.svg`}
                />
              </div>
            </div>

            <div className="header-kuromi-collection__intro__content-2" data-aos="fade-up">
              <div className="header-kuromi-collection__intro__content-2-inner base-kuromi-collection__item-corner-top">
                <img
                  alt="What is #KUROMIfy the World?"
                  className="header-kuromi-collection__intro__content-2-img lazyload"
                  src={`${baseImgUrl}/intro-2-img.svg`}
                />
                <div className="header-kuromi-collection__intro__content-2-detail">
                  <h2 className="header-kuromi-collection__intro__content-2-title">
                    What is #KUROMIfy the World?
                  </h2>
                  <p>
                    A project to fill the world with KUROMIES – anyone who supports Kuromi and wants
                    to be their best self like Kuromi. “Come on, don’t you want to be your best self
                    too?” Let’s spread Kuromi’s message and fill the world with KUROMIES!
                  </p>
                  <picture>
                    <source
                      media="(min-width:1024px)"
                      srcSet={`${baseImgUrl}/intro-2-character-pc.svg`}
                    />
                    <img
                      alt="KUROMIES"
                      className="header-kuromi-collection__intro__content-2-character lazyload"
                      src={`${baseImgUrl}/intro-2-character.svg`}
                    />
                  </picture>
                </div>
                <div className="base-kuromi-collection__item-corner-bottom"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
