import { useEffect } from 'react';

const defaultScripts = [];
const defaultStyles = ['https://static.lenskart.com/media/owndays/web/css/od-external.css'];

const ExternalResources = ({ scripts = [], styles = [] }) => {
  useEffect(() => {
    // Load stylesheets dynamically
    [...styles, ...defaultStyles].forEach(href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      link.type = 'text/css';
      link.crossOrigin = 'anonymous';
      document.head.appendChild(link);
    });

    // Load scripts dynamically
    [...scripts, ...defaultScripts].forEach(src => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    });

    // Cleanup: Remove added scripts and styles on unmount
    return () => {
      document
        .querySelectorAll('link[data-added="true"], script[data-added="true"]')
        .forEach(el => {
          el.remove();
        });
    };
  }, [scripts, styles]);

  return null; // This component does not render anything
};

export default ExternalResources;
