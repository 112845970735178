const IMG_URL = 'https://static1.lenskart.com/media/owndays/img/contacts';

const COMMON_FEATURES = {
  highWaterContent: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-01.svg',
    title: 'HIGH WATER CONTENT',
    description:
      'With higher water content, there is higher oxygen flow to the eye. This increases oxygen permeability of the lens, allowing the eye to feel hydrated and fresh all day long.',
  },
  hyaluronicAcid: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-02.svg',
    title: 'HYALURONIC ACID',
    description:
      'Hyaluronic acid functions as an ocular lubricant that cushions the eye and minimises friction while maintaining hydration.',
  },
  thinLens: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-03.svg',
    title: 'THIN LENS',
    description:
      'The thin lens design minimises discomfort of a foreign object being placed in contact with the eye. Smooth, rounded edges further reduce irritation and friction with the eyelid, offering enhanced wearing comfort.',
  },
  uvProtection: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-04.svg',
    title: 'UV PROTECTION',
    description: 'The lens protects the eye from harmful UV rays by blocking 80% UVA and 95% UVB.',
  },
  shapeRetention: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-05.svg',
    title: 'SHAPE RETENTION',
    description:
      'The lens is able to retain its shape when placed on the finger. This makes it easier to differentiate between the inner and outer surface of the lens, thereby allowing you to insert and remove the lens in a breeze.',
  },
  antiBlueLight: {
    icon: 'https://static1.lenskart.com/media/owndays/img/contacts/feature-03.svg',
    title: 'ANTI-BLUE LIGHT',
    description:
      'The lens protects the eye from harmful blue light emitted from digital screens by filtering up to 15% blue light of wavelength between 400nm and 450nm.',
  },
};

export const CONTACTS_DATA = {
  categories: [
    {
      id: 'clear-contactlens',
      name: 'OWNDAYS CLEAR VISION',
      logo: `${IMG_URL}/clear-contactlens/logo-main.svg`,
      subHeading: 'CONTACT LENS',
      bg: `${IMG_URL}/clear-contactlens/main-bg.webp`,
      color: '#508ab7',
      types: [
        {
          id: 'daily',
          name: 'OWNDAYS CLEAR VISION DAILY',
          thumbnail: `${IMG_URL}/clear-contactlens/package-daily.webp`,
          thumbnailShape: 'horizontal',
          tags: ['30 lenses per box', 'Daily'],
          price: 'S$30',
          type: 'Daily',
          features: [
            COMMON_FEATURES.highWaterContent,
            COMMON_FEATURES.hyaluronicAcid,
            COMMON_FEATURES.thinLens,
            COMMON_FEATURES.uvProtection,
            COMMON_FEATURES.shapeRetention,
          ],
          spec: {
            waterContent: '58%',
            dia: '14.2mm',
            bc: '8.5mm',
            dk: '30',
            centerThickness: '0.08mm',
            material: 'Etafilcon A',
            uvInhibitor: 'Yes',
            handingTint: 'Yes',
            packSize: '30 pieces',
            pwr: '-1.00 〜 -10.00',
          },
        },
        {
          id: 'monthly',
          name: 'OWNDAYS CLEAR VISION MONTHLY',
          thumbnail: `${IMG_URL}/clear-contactlens/package-monthly.webp`,
          thumbnailShape: 'horizontal',
          tags: ['6 lenses per box', 'Monthly'],
          price: 'S$30',
          type: 'Monthly',
          features: [
            COMMON_FEATURES.highWaterContent,
            COMMON_FEATURES.hyaluronicAcid,
            COMMON_FEATURES.thinLens,
            COMMON_FEATURES.uvProtection,
            COMMON_FEATURES.shapeRetention,
          ],
          spec: {
            waterContent: '58%',
            dia: '14.2mm',
            bc: '8.5mm',
            dk: '30',
            centerThickness: '0.08mm',
            material: 'Etafilcon A',
            uvInhibitor: 'Yes',
            handingTint: 'Yes',
            packSize: '6 pieces',
            pwr: '-0.50 〜 -10.00',
          },
        },
        {
          id: 'daily-blue',
          name: 'OWNDAYS CLEAR VISION BLUE DAILY',
          thumbnail: `${IMG_URL}/clear-contactlens/package-daily-blue.webp`,
          thumbnailShape: 'horizontal',
          tags: ['30 lenses per box', 'Daily'],
          price: 'S$40',
          type: 'Daily',
          features: [
            COMMON_FEATURES.antiBlueLight,
            COMMON_FEATURES.highWaterContent,
            COMMON_FEATURES.hyaluronicAcid,
            COMMON_FEATURES.uvProtection,
          ],
          spec: {
            waterContent: '58%',
            dia: '13.9mm',
            bc: '8.5mm',
            dk: '23.49 x 10-11 (cm2/s)(mlO2)/(ml*mmHg)',
            centerThickness: '0.08mm',
            material: 'Etafilcon A',
            uvInhibitor: 'Yes',
            handingTint: 'Yes',
            packSize: '30 pieces',
            pwr: '-0.75 〜 -10.00',
          },
        },
      ],
    },
    {
      id: 'color-contactlens',
      name: 'OWNDAYS COLOUR CONTACT LENS',
      logo: `${IMG_URL}/color-contactlens/logo.svg`,
      subHeading: null,
      bg: `${IMG_URL}/color-contactlens/top_bk.webp`,
      color: '#71475e',
      types: [
        {
          id: 'natural',
          name: 'NATURAL SERIES',
          productName: 'OWNDAYS NATURAL DAILY',
          thumbnail: `${IMG_URL}/color-contactlens/package-natural.webp`,
          thumbnailShape: 'horizontal',
          tags: ['10 lenses per box', 'Daily'],
          price: 'S$18',
          bg: `${IMG_URL}/color-contactlens/natural/bg-header.webp`,
          tagline: '1 Day Contact Lens',
          colorCount: 4,
          features: [
            {
              title: 'Embedded Colour Pigment',
              description:
                'Colour pigment is embedded within the lens material to avoid direct contact with the eye, thereby ensuring safer and more comfortable wear',
            },
            {
              title: 'Tri-Curve Edge',
              description:
                'Lens edge features a tri-curve design to enhance fit and comfort while allowing easier handling',
            },
          ],
          lineup: [
            { id: 'onyx-black', name: 'Onyx Black' },
            { id: 'onyx-brown', name: 'Onyx Brown' },
            { id: 'onyx-grey', name: 'Onyx Grey' },
            { id: 'onyx-hazel', name: 'Onyx Hazel' },
          ],
          spec: {
            waterContent: '40%',
            dia: '14.2mm',
            bc: '8.6mm',
            dk: '11.3',
            centerThickness: '0.08mm',
            material: 'HEMA',
            uvInhibitor: 'No',
            packSize: '10 pieces',
            pwr: 'Plano, -0.75 to -8.00',
          },
        },
        {
          id: 'horoscope',
          name: 'HOROSCOPE SERIES',
          productName: 'OWNDAYS HOROSCOPE DAILY',
          thumbnail: `${IMG_URL}/color-contactlens/package-horoscope.webp`,
          thumbnailShape: 'horizontal',
          tags: ['10 lenses per box', 'Daily'],
          price: 'S$18',
          bg: `${IMG_URL}/color-contactlens/horoscope/bg-header.webp`,
          tagline: '1 Day Contact Lens',
          colorCount: 12,
          features: [
            {
              title: 'Embedded Colour Pigment',
              description:
                'Colour pigment is embedded within the lens material to avoid direct contact with the eye, thereby ensuring safer and more comfortable wear',
            },
            {
              title: 'Tri-Curve Edge',
              description:
                'Lens edge features a tri-curve design to enhance fit and comfort while allowing easier handling',
            },
          ],
          lineup: [
            { id: 'aries-pink', name: 'Aries Pink', new: true },
            { id: 'virgo-choco', name: 'Virgo Choco', new: true },
            { id: 'gemini-green', name: 'Gemini Green', new: true },
            { id: 'sagittarius-hazel', name: 'Sagittarius Hazel', new: true },
            { id: 'leo-turquoise', name: 'Leo Turquoise' },
            { id: 'taurus-brown', name: 'Taurus Brown' },
            { id: 'cancer-silver', name: 'Cancer Silver Dark Grey' },
            { id: 'aquarius-blue', name: 'Aquarius Blue' },
            { id: 'capricorn-violet', name: 'Capricorn Violet' },
            { id: 'scorpio-grey', name: 'Scorpio Grey' },
            { id: 'libra-honey', name: 'Libra Honey' },
            { id: 'pisces-maroon', name: 'Pisces Maroon' },
          ],
          spec: {
            waterContent: '40%',
            dia: '14.2mm',
            bc: '8.6mm',
            dk: '11.3',
            centerThickness: '0.08mm',
            material: 'HEMA',
            uvInhibitor: 'No',
            packSize: '10 pieces',
            pwr: 'Plano, -0.75 to -8.00',
          },
        },
        {
          id: 'petal',
          name: 'PETAL SERIES',
          productName: 'OWNDAYS PETAL MONTHLY',
          thumbnail: `${IMG_URL}/color-contactlens/package-petal.webp`,
          thumbnailShape: 'vertical',
          tags: ['2 lenses per box', 'Monthly'],
          price: 'S$15',
          bg: `${IMG_URL}/color-contactlens/petal/bg-header.webp`,
          tagline: 'Monthly Contact Lens',
          colorCount: 6,
          features: [
            {
              title: 'Embedded Colour Pigment',
              description:
                'Colour pigment is embedded within the lens material to avoid direct contact with the eye, thereby ensuring safer and more comfortable wear',
            },
            {
              title: 'Tri-Curve Edge',
              description:
                'Lens edge features a tri-curve design to enhance fit and comfort while allowing easier handling',
            },
          ],
          lineup: [
            { id: 'petal-violet', name: 'Petal Violet' },
            { id: 'petal-blue', name: 'Petal Blue' },
            { id: 'petal-choco', name: 'Petal Choco' },
            { id: 'petal-grey', name: 'Petal Grey', new: true },
            { id: 'petal-green', name: 'Petal Green', new: true },
            { id: 'petal-brown', name: 'Petal Brown', new: true },
          ],
          spec: {
            waterContent: '40%',
            dia: '14.5mm',
            bc: '8.6mm',
            dk: '11.3',
            centerThickness: '0.08mm',
            material: 'HEMA',
            uvInhibitor: 'No',
            packSize: '2 pieces',
            pwr: 'Plano, -0.75 to -8.00',
          },
        },
        {
          id: 'velvet',
          name: 'VELVET SERIES',
          productName: 'OWNDAYS VELVET DAILY',
          thumbnail: `${IMG_URL}/color-contactlens/package-velvet.webp`,
          thumbnailShape: 'vertical',
          tags: ['2 lenses per box', 'Monthly'],
          price: 'S$15',
          bg: `${IMG_URL}/color-contactlens/velvet/bg-header.webp`,
          tagline: 'Monthly Contact Lens',
          colorCount: 7,
          features: [
            {
              title: 'Embedded Colour Pigment',
              description:
                'Colour pigment is embedded within the lens material to avoid direct contact with the eye, thereby ensuring safer and more comfortable wear',
            },
            {
              title: 'Tri-Curve Edge',
              description:
                'Lens edge features a tri-curve design to enhance fit and comfort while allowing easier handling',
            },
          ],
          lineup: [
            { id: 'velvet-grey', name: 'Velvet Grey' },
            { id: 'velvet-green', name: 'Velvet Grenn' },
            { id: 'velvet-pink', name: 'Velvet Pink' },
            { id: 'velvet-brown', name: 'Velvet Brown' },
            { id: 'velvet-violet', name: 'Velvet Violet' },
            { id: 'velvet-hazel', name: 'Velvet Hazel' },
            { id: 'velvet-blue', name: 'Velvet Blue' },
          ],
          spec: {
            waterContent: '40%',
            dia: '14.5mm',
            bc: '8.6mm',
            dk: '11.3',
            centerThickness: '0.08mm',
            material: 'HEMA',
            uvInhibitor: 'No',
            packSize: '2 pieces',
            pwr: 'Plano, -0.75 to -8.00',
          },
        },
      ],
    },
  ],
};

export const HOWTOUSE_DATA = {
  steps: [
    {
      icon: `${IMG_URL}/how-to-use/step1-1.webp`,
      title: 'Before lens insertion',
      items: [
        {
          icon: `${IMG_URL}/how-to-use/step1-1.webp`,
          title: 'Trim your fingernails',
          description:
            'Keep your fingernails short to avoid scratching the lens. Ensure the edges of your fingernails are smooth.',
        },
        {
          icon: `${IMG_URL}/how-to-use/step1-2.webp`,
          title: 'Wash your hands',
          description:
            'Wash your hands with soap before touching the lens. Ensure there is no soap remaining on your hands. <br/>Only handle the lens with thoroughly cleaned fingers.',
        },
      ],
    },
    {
      icon: `${IMG_URL}/how-to-use/step2-3.webp`,
      title: 'Opening lens packaging',
      items: [
        {
          icon: `${IMG_URL}/how-to-use/step2-1.webp`,
          title: 'Removing lens from the packaging',
          description: `Remove lens from the packaging box.<br/>
            - Ensure your fingers are dry<br/>
            - Always start with the lens for the right eye to prevent mixing up the two sides`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step2-2.webp`,
          title: 'Detaching a lens blister',
          description:
            'With the aluminium foil facing up, slowly tear to detach a lens blister from the rest.',
        },
        {
          icon: `${IMG_URL}/how-to-use/step2-3.webp`,
          title: 'Checking the lens blister',
          description: `Shake the lens blister lightly to ensure that the lens is immersed in the storage solution.<br/>
            Do not open if you notice any damage or hole on the blister.<br/>
            Please contact the shop of purchase immediately in such events.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step2-4.webp`,
          title: 'Peeling off the foil',
          description: `While holding the blister tightly, lift the edge of the foil and gradually peel off the whole foil.<br/>
            Take care not to spill the storage solution in the blister.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step2-5.webp`,
          title: 'Taking out the lens',
          description: `Using your index finger, gently slide the lens along the side of the blister and bring it up with your finger tip.<br/>
            - Take caution not to scratch the lens with your fingernails`,
        },
      ],
    },
    {
      icon: `${IMG_URL}/how-to-use/step3-6.webp`,
      title: 'Inserting the lens',
      items: [
        {
          icon: `${IMG_URL}/how-to-use/step3-1.webp`,
          title: 'Dry the lens',
          description: `Swing the lens lightly to remove any storage solution on the lens surface.<br/>
            - Take caution not to drop the lens`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-2.webp`,
          title: 'Place the lens on the fingertip',
          description: `Place the lens on the tip of the index finger of your dominant hand.<br/>
            - The lens may slide off if your fingertip or the lens is wet. Dry your fingers and remove any storage solution on the lens surface`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-3.webp`,
          title: 'Check for irregularities on lens',
          description: `Check to ensure the lens is clean and clear, without any deformation, decolouration, stain, scratch or damage.<br/>
            Please contact the shop of purchase immediately if you find the above mentioned irregularities on the lens. Do not use the lens.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-4.webp`,
          title: 'Check the lens orientation',
          description: `The inner and outer surface of the lens is different. Wearing the lens inside out may cause discomfort and poor vision.<br/>
            Place the lens on your fingertip and make sure it is in a bowl shape and correctly oriented.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-5.webp`,
          title: 'Keep the eye open',
          description: `Hold the upper eyelid firmly with one finger of your free hand and hold down the lower eyelid with one finger of your applying hand.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-6.webp`,
          title: 'Insert the lens',
          description: `Carefully place the lens on your cornea.`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-7.webp`,
          title: 'Close the eye',
          description: `Release the upper eyelid slowly and blink gently to ensure the lens is in the correct position.<br/>
            - Do not blink too hard as this may cause the lens to fall out`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step3-8.webp`,
          title: 'After lens insertion',
          description: `Cover the other eye while looking through the lens to ensure that it is inserted correctly and your vision is clear.`,
        },
      ],
    },
    {
      icon: `${IMG_URL}/how-to-use/step4-2.webp`,
      title: 'Lens removal',
      items: [
        {
          icon: `${IMG_URL}/how-to-use/step4-1.webp`,
          title: 'Remove the lens',
          description: `Hold down the lower eyelid with your middle finger and use the forefinger to slide the lens to the lower part of the eye.<br/>
            - Do not remove the lens by force. If the lens does not move, blink gently or apply eye drops to increase the moisture in the eye`,
        },
        {
          icon: `${IMG_URL}/how-to-use/step4-2.webp`,
          title: 'Pinch the lens',
          description: `Pinch the bottom edge of the lens gently with the tips of your thumb and index finger to remove the lens.<br/>
            - Take caution not to scratch your eye while removing the lens<br/>
            * Discontinue use if you experience any discomfort and consult your Eye Care Professional immediately.`,
        },
      ],
    },
  ],
  monthly: [
    {
      icon: `${IMG_URL}/how-to-use/step4-3.webp`,
      title: 'Rinse',
      description: `After removal, rinse lens with multi-purpose solution.`,
    },
    {
      icon: `${IMG_URL}/how-to-use/step4-4.webp`,
      title: 'Rubbing',
      description: `Rub both side of the lens gently in a circular motion for 20 secs.`,
    },
    {
      icon: `${IMG_URL}/how-to-use/step4-5.webp`,
      title: 'Disinfection & Storage',
      description: `Fill the case with clean multi-purpose solution and store the lens inside the case for at least 6 hours for disinfection.`,
    },
    {
      icon: `${IMG_URL}/how-to-use/step4-6.webp`,
      title: 'Insertion',
      description: `Rinse the lens with multi-purpose solution before insertion onto the eye.`,
    },
    {
      icon: `${IMG_URL}/how-to-use/step4-7.webp`,
      title: 'Case cleaning',
      description: `After insertion, empty the old solution and rinse it with fresh multi-purpose solution. Air dry the case in a dry environment.`,
    },
  ],
};
