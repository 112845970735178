import React from 'react';
import MapComponent from '../../CommonComponents/MapComponent/MapComponent';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
import '../../html/css/InformationDetails.scss';

const Shops1048 = () => {
  const crumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/information', label: 'See all the Information' },
    { path: '/sg/en/information/shops/1048', label: 'OWNDAYS Le Quest Outlet Store New Open' },
  ];

  const lat = 1.35594;
  const long = 103.74116;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <section className="information-details">
        <div className="information-details__container">
          <div className="information-details__title">
            <p className="days">2024.12.12 ｜ Latest information</p>
            <h1>Thu. 19 Dec. 2024 OWNDAYS Le Quest Outlet Store New Open!</h1>
          </div>
          <div className="information-details__cover">
            <img
              alt="OWNDAYS Le Quest Outlet"
              src="https://static1.lenskart.com/owndays/img/information/shops/1048/main.webp"
            />
          </div>
          <div className="information-details__content">
            <h2>Store Details</h2>
            <ul className="information-details__content-address">
              <li>
                <strong>Address</strong>4 Bukit Batok Street 41, #01-67. Singapore 657991
              </li>
              <li>
                <strong>Operating Hours</strong>11:00-20:30
              </li>
            </ul>
            <h2>Access</h2>
            <div className="information-details__map">
              <MapComponent lat={lat} long={long} />
              <a
                href={`https://www.google.com/maps/@${lat},${long},17z?entry=ttu`}
                rel="noreferrer"
                target="_blank"
              >
                View larger map
              </a>
            </div>
            <div className="information-details__btn">
              <a href="https://www.owndays.com/stores/sg">More Details</a>
            </div>
            <ul className="information-details__sns">
              <li>
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/information/shops/1048"
                  rel="noreferrer"
                  target="_blank"
                >
                  <svg
                    fill="#999"
                    height={22}
                    viewBox="0 0 448 512"
                    width={22}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/information/shops/1048"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    fill="#999"
                    height={22}
                    viewBox="0 0 512 512"
                    width={22}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/information/shops/1048"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    fill="#999"
                    height={22}
                    viewBox="0 0 512 512"
                    width={22}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="information-details__footer">
            <div className="information-details__back">
              <a href="/sg/en/information">
                <svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
                Back to list
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shops1048;
