import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';

import { GoodsModalFreaksStore } from './GoodsModalFreaksStore';
import { POINTS_GOODS, DESIGN_GOODS, COLLAPSE_GOODS } from '../constants';

import './GoodsFreaksStore.scss';

export const GoodsFreaksStore = ({ baseImgUrl }) => {
  const [isActiveStep1, setIsActiveStep1] = useState(true);
  const [isActiveStep2, setIsActiveStep2] = useState(false);
  const [isActiveStep3, setIsActiveStep3] = useState(false);
  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [dataModal, setDataModal] = useState({ isOpen: false, data: null });

  const pointSliderRef = useRef(null);
  const collapseRef = useRef(null);

  const handleClickStep1 = () => {
    setIsActiveStep1(true);
    setIsActiveStep2(false);
    setIsActiveStep3(false);
  };

  const handleClickStep2 = () => {
    setIsActiveStep1(false);
    setIsActiveStep2(true);
    setIsActiveStep3(false);
  };

  const handleClickStep3 = () => {
    setIsActiveStep1(false);
    setIsActiveStep2(false);
    setIsActiveStep3(true);
  };

  const pointSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    draggable: true,
    infinite: true,
    swipe: true,
    touchMove: true,
    beforeChange: async (prev, next) => {
      await pointSliderRef.current?.slickGoTo(next);

      const currentIndex = next + 1;

      if (currentIndex === 1) {
        handleClickStep1();
      }

      if (currentIndex === 2) {
        handleClickStep2();
      }

      if (currentIndex === 3) {
        handleClickStep3();
      }
    },
  };

  const setStepByIndex = async (index, setNewSteps) => {
    await pointSliderRef.current?.slickGoTo(index);

    setNewSteps();
  };

  const handleToggleCollapse = () => {
    setIsShowCollapse(!isShowCollapse);
  };

  useEffect(() => {
    if (collapseRef.current) {
      if (isShowCollapse) {
        collapseRef.current.style.maxHeight = collapseRef.current.scrollHeight + 'px';
      } else {
        collapseRef.current.style.maxHeight = '0';
      }
    }
  }, [isShowCollapse]);

  return (
    <section className="goods-freaks-store" id="goods">
      <div className="goods-freaks-store__button">
        <div className="base-freaks-store__container">
          <a className="base-freaks-store__btn base-freaks-store__btn--black" href="/stores/sg">
            Nearest OWNDAYS Stores
          </a>
        </div>
      </div>

      <div className="goods-freaks-store__header">
        <div className="base-freaks-store__container">
          <div className="goods-freaks-store__header__title-layout">
            <h1 className="goods-freaks-store__header__title-text">EXCLUSIVE MERCH</h1>
            <h4 className="goods-freaks-store__header__title-subtitle">オリジナルグッズ</h4>
          </div>

          <div className="goods-freaks-store__header__description-layout goods-freaks-store__header__description-layout--how-to">
            <h4
              className="goods-freaks-store__header__description-title goods-freaks-store__typing-effect"
              data-aos="goods-typing-animation"
            >
              <span className="goods-freaks-store__text--brown">Exclusive Merch</span> with eyewear
              purchase
            </h4>
          </div>
          <p
            className="goods-freaks-store__message aos-init aos-animate"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="1500"
          >
            All OWNDAYS x FREAK’S STORE collaboration eyewear comes with an exclusive 3-piece merch
            set consisting of a pouch, a spectacle cloth and a strap card.
            <br />
            Bandana print, an iconic feature of the American style, is given a brand-new iteration
            on the spectacle cloth. The pouch comes in a multiway design which can be used in both
            the vertical and horizontal orientations. The detachable crossbody strap doubles up as a
            spectacle chain. It also works as a handphone strap when used with the strap card.
          </p>
        </div>
      </div>

      <div className="goods-freaks-store__how-to">
        <div className="base-freaks-store__container">
          <div className="goods-freaks-store__how-to__main-layout">
            <img
              alt="original-goods"
              className="goods-freaks-store__how-to__image"
              src={`${baseImgUrl}/original-goods.webp`}
            />
            <img
              alt="original-goods-no-1"
              className="goods-freaks-store__how-to__image-step-01"
              src={`${baseImgUrl}/original-goods-no-1${isActiveStep1 ? '-active' : ''}.svg`}
              onClick={() => setStepByIndex(0, handleClickStep1)}
            />
            <img
              alt="original-goods-no-2"
              className="goods-freaks-store__how-to__image-step-02"
              src={`${baseImgUrl}/original-goods-no-2${isActiveStep2 ? '-active' : ''}.svg`}
              onClick={() => setStepByIndex(1, handleClickStep2)}
            />
            <img
              alt="original-goods-no-3"
              className="goods-freaks-store__how-to__image-step-03"
              src={`${baseImgUrl}/original-goods-no-3${isActiveStep3 ? '-active' : ''}.svg`}
              onClick={() => setStepByIndex(2, handleClickStep3)}
            />
          </div>
        </div>

        <Slider ref={pointSliderRef} {...pointSlider} className="goods-freaks-store__how-to__point">
          {POINTS_GOODS.map((point, index) => (
            <div key={point.title}>
              <img alt={point.title} src={`${baseImgUrl}/${point.src}`} />
              <div className="goods-freaks-store__how-to__point__label">
                <div className="goods-freaks-store__how-to__point__label--circle-bg">
                  <span>{String(index + 1).padStart(2, '0')}</span>
                </div>
                <span className="goods-freaks-store__how-to__point__label--name">
                  {point.title}
                </span>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="base-freaks-store__container">
        <div className="goods-freaks-store__multiway-design">
          <h4
            className="goods-freaks-store__header__description-title goods-freaks-store__typing-effect"
            data-aos="goods-typing-animation"
          >
            Multiway Design
          </h4>

          <h2
            className="goods-freaks-store__header__description-subtitle goods-freaks-store__typing-effect"
            data-aos="goods-typing-animation"
          >
            Ways to use the <span className="goods-freaks-store__text--brown">exclusive pouch</span>
          </h2>
        </div>
      </div>

      <div className="goods-freaks-store__design" data-aos="fade-up" data-aos-duration="1500">
        <ul className="goods-freaks-store__design-slider">
          {DESIGN_GOODS.map((item, index) => (
            <li key={item.tag} className="goods-freaks-store__design-slider__item">
              <img
                alt="original-goods-slide-1"
                className="goods-freaks-store__design-slider__image"
                src={`${baseImgUrl}/${item.src}`}
              />
              <div className="goods-freaks-store__design-slider__tag">
                <span>{item.tag}</span>
              </div>

              {item.modal && (
                <a
                  className="goods-freaks-store__design-slider__link-show-modal"
                  onClick={() => setDataModal({ isOpen: !dataModal.isOpen, data: item.modal })}
                >
                  <span>{item.modal.title}</span>
                  <img
                    alt={item.modal.title}
                    src={`${baseImgUrl}/original-goods-comment-icon.svg`}
                  />
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="base-freaks-store__container">
        <p className="goods-freaks-store__remark">
          Please read the precautions listed in the instruction manual before using the products
        </p>
      </div>

      <div className="base-freaks-store__container">
        <div className="goods-freaks-store__collapse">
          <div className="goods-freaks-store__collapse__button" onClick={handleToggleCollapse}>
            <h4>Instruction Manual </h4>
            <img
              alt="action-icon"
              src={`${baseImgUrl}/original-goods-collapse-${
                isShowCollapse ? 'hide' : 'show'
              }-icon.svg`}
            />
          </div>

          <div ref={collapseRef} className="goods-freaks-store__collapse__animation">
            <div className="goods-freaks-store__collapse__content">
              {COLLAPSE_GOODS.map(({ title, list }) => (
                <section key={title}>
                  <h4>{title}</h4>
                  <ul>
                    {list.map(({ desc }, index) => (
                      <li key={index}>{desc}</li>
                    ))}
                  </ul>
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>

      {dataModal.isOpen && (
        <GoodsModalFreaksStore
          baseImgUrl={baseImgUrl}
          data={dataModal.data}
          isOpen={dataModal.isOpen}
          setDataModal={setDataModal}
        />
      )}
    </section>
  );
};
