import React from 'react';
import { InformationList } from '../CommonComponents/Information';

const InformationHTML = () => {
  const baseImageUrl = 'https://static.lenskart.com/media/owndays/img/information';

  return <InformationList baseImageUrl={baseImageUrl} />;
};

export default InformationHTML;
