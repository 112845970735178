import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5WRT43B');</script>
<!-- End Google Tag Manager -->    
        <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
        <script>
    /*TAIWAN 20150119
     window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
     d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
     _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
     $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
     type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
     */
    window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
    _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
        $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
            type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
</script>
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    
    <!-- CSRF Token -->
    <meta name="csrf-token" content="ABFbHUwScfMk8C1kWWkP5XRx2wIljKVfERRkTF0c">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris." />
<meta name="keywords" content="相場慎吾,SHINGO AIBA,SAINT LAURENT,Limited Edition,collaboration,glasses,Lens Replacement,onlineshop,onlinestore,OWNDAYS" />
<meta name="og:description" content="A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris." />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/shingo-aiba">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/shingo-aiba/ogp_1200x630.jpg?1692884698">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">
<link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.min.css" />
<link href="https://www.owndays.com/web/css/specials-shingo-aiba.css?v=1707081086" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
    

<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en">Homepage</a>
                                                                                </li>
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION                    </li>
        
            </ul>
</div>


<section class="l-shingo-aiba" data-aos="fade" data-aos-duration="400">
    
    <section class="main">
        <div class="main__title" data-aos="fade-up-sm" data-aos-duration="700" data-aos-delay="750">
            <h1>
                <img src="https://www.owndays.com/images/specials/products/shingo-aiba/logo-main.svg" alt="SHINGO AIBA × OWNDAYS">
            </h1>
        </div>
        <div class="main__img" data-aos="scale-down" data-aos-duration="1000" data-aos-delay="400">
            <picture>
                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/shingo-aiba/main-img-pc.webp" type="image/webp">
                <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/shingo-aiba/main-img-pc.jpg" type="image/jpg">
                <source srcset="https://www.owndays.com/images/specials/products/shingo-aiba/main-img-sp.webp" type="image/webp">
                <img src="https://www.owndays.com/images/specials/products/shingo-aiba/main-img-sp.jpg" alt="SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION">
            </picture>
        </div>
        <div class="container-btn_bounce">

            <div class="btn_bounce">
                <div class="btn_bounce__illus">
                    <div class="btn-to-line-up">
                        <a href="#lineup">
                            <span class="link-2">Go to <br>LINE UP</span>
                        </a>
                    </div>
                </div>
                <div class="btn_bounce__selec">

                </div>
            </div>

        </div>
    </section>
    
    <section class="concept">
        <div class="concept-container">
            <div id="item-0" class="concept-detail" data-aos="fade-right-sm" data-aos-delay="500">
                <div class="l-shingo-aiba__container">
                    <div class="concept-detail__title">
                        <h2>Made-in-Japan Meets <br>Neoclassicism</h2>
                    </div>
                    <div class="concept-detail__sub">
                        <p>A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris.<br>Reflecting a neoclassic perspective that is not confined by trends, the collection brings to life spectacle frames that are reminiscent of the classics with its emphasis on icons from the 70s while being elevated with modern materials and colours. <br>Every piece in this Made-in-Japan collection is crafted to perfection through the superb skills of eyewear artisans from Sabae – also known as the City of Eyewear in Japan – who boast over 50 years of experience under their belt. Combined with the luxury of state-of-the-art technology, this is decidedly a collection that is made to last for years to come.  </p>
                    </div>
                </div>
            </div>
            <div id="item-1" class="concept-image" data-aos="fade-up" data-aos-duration="1300">
                <picture>
                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/shingo-aiba/concept-img-pc.webp">
                    <img src="https://www.owndays.com/images/specials/products/shingo-aiba/concept-img-sp.webp?Sdf=sdf" alt="AS2001Z-3S">
                </picture>
            </div>
        </div>
    </section>
    
    <section class="lineup" id="lineup">
        <div class="l-shingo-aiba__container">
            <div class="lineup__top">
                <h2 class="l-shingo-aiba__title">
                    LINE UP                    <small>4 models with 3 colours each                        <strong>S$258</strong>
                    </small>
                </h2>
                <ul class="lineup__link">
                    <li>
                        <a href="#stevie">
                            <small>No : 01</small>
                            <strong>STEVIE</strong>
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="49"></circle>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#david">
                            <small>No : 02</small>
                            <strong>DAVID</strong>
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="49"></circle>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#elston">
                            <small>No : 03</small>
                            <strong>ELSTON</strong>
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="49"></circle>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="#john-anthony">
                            <small>No : 04</small>
                            <strong>JOHN<br>ANTHONY</strong>
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" r="49"></circle>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="lineup__products">
                
                <div class="lineup__products-item" id="stevie">
                    <picture class="lineup__products-banner" data-aos="fade-up" data-aos-duration="1300">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-stevie-pc.webp" type="image/webp">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-stevie-pc.jpg" type="image/jpeg">
                        <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-stevie-sp.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-stevie-sp.jpg" data-sizes="auto" alt="STEVIE">
                    </picture>
                    <div class="lineup__products-text-container">
                        <h3 class="lineup__products-title"><small>No : 01</small>STEVIE</h3>
                        <p class="lineup__products-des">A crossover between the cat-eye frame and the Boston style, featuring metal rim embedded within an acetate front and temples with details inspired by earrings. This is a design that has the perfect vintage vibe but with a modern twist. </p>
                    </div>
                    <div class="lineup__products-slider-container">
                        <div class="lineup__products-slider-text">
                            <h4 class="lineup__products-type">BOSTON</h4>
                            <h5 class="lineup__products-code">AS2001Z-3S<small>C1 Black</small></h5>
                            <p class="lineup__products-detail">Materials: Acetate / Titanium<br>Size: 51□18-142〇46.4</p>
                            
                            <ul class="lineup__products-color nav" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" style="--c: #000" data-toggle="pill" href="#tab-1-1">C1 Black</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: #e3e3e3" data-toggle="pill" href="#tab-1-2">C2 Clear Gray</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: #e8e5d2" data-toggle="pill" href="#tab-1-3">C3 Clear Yellow</a>
                                </li>
                            </ul>
                        </div>
                        <div class="lineup__products-slider tab-content">
                            
                            <div class="tab-pane show active" id="tab-1-1">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_01.jpg" data-sizes="auto" alt="AS2001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_02.jpg" data-sizes="auto" alt="AS2001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_03.jpg" data-sizes="auto" alt="AS2001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_04.jpg" data-sizes="auto" alt="AS2001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C1/AS2001Z-3S_C1_05.jpg" data-sizes="auto" alt="AS2001Z-3S C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2001Z-3S?sku=7421">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-1-2">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_01.jpg" data-sizes="auto" alt="AS2001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_02.jpg" data-sizes="auto" alt="AS2001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_03.jpg" data-sizes="auto" alt="AS2001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_04.jpg" data-sizes="auto" alt="AS2001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C2/AS2001Z-3S_C2_05.jpg" data-sizes="auto" alt="AS2001Z-3S C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2001Z-3S?sku=7422">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-1-3">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_01.jpg" data-sizes="auto" alt="AS2001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_02.jpg" data-sizes="auto" alt="AS2001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_03.jpg" data-sizes="auto" alt="AS2001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_04.jpg" data-sizes="auto" alt="AS2001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2001Z-3S/C3/AS2001Z-3S_C3_05.jpg" data-sizes="auto" alt="AS2001Z-3S C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2001Z-3S?sku=7423">ONLINE STORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="lineup__products-item" id="david">
                    <picture class="lineup__products-banner" data-aos="fade-up" data-aos-duration="1300">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-david-pc.webp" type="image/webp">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-david-pc.jpg" type="image/jpeg">
                        <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-david-sp.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-david-sp.jpg" data-sizes="auto" alt="DAVID">
                    </picture>
                    <div class="lineup__products-text-container">
                        <h3 class="lineup__products-title"><small>No : 02</small>DAVID</h3>
                        <p class="lineup__products-des">A polygonal metal frame made of 2 types of titanium to achieve a sleek, classic edge that is unexpectedly chic at the same time. </p>
                    </div>
                    <div class="lineup__products-slider-container">
                        <div class="lineup__products-slider-text">
                            <h4 class="lineup__products-type">POLYGON</h4>
                            <h5 class="lineup__products-code">AS1001Z-3S<small>C1 Gold</small></h5>
                            <p class="lineup__products-detail">Materials: Titanium / β-Titanium<br>Size: 52□19-142〇46.7</p>
                            
                            <ul class="lineup__products-color nav" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" style="--c: #f6e1cb" data-toggle="pill" href="#tab-2-1">C1 Gold</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: #454545" data-toggle="pill" href="#tab-2-2">C2 Light Gun</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: #173623" data-toggle="pill" href="#tab-2-3">C3 Matte Green</a>
                                </li>
                            </ul>
                        </div>
                        <div class="lineup__products-slider tab-content">
                            
                            <div class="tab-pane show active" id="tab-2-1">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_01.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_02.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_03.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_04.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_05.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C1/AS1001Z-3S_C1_06.jpg" data-sizes="auto" alt="AS1001Z-3S C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS1001Z-3S?sku=7418">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-2-2">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_01.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_02.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_03.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_04.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_05.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C2/AS1001Z-3S_C2_06.jpg" data-sizes="auto" alt="AS1001Z-3S C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS1001Z-3S?sku=7419">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-2-3">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_01.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_02.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_03.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_04.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_05.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS1001Z-3S/C3/AS1001Z-3S_C3_06.jpg" data-sizes="auto" alt="AS1001Z-3S C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS1001Z-3S?sku=7420">ONLINE STORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="lineup__products-item" id="elston">
                    <picture class="lineup__products-banner" data-aos="fade-up" data-aos-duration="1300">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-elston-pc.webp" type="image/webp">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-elston-pc.jpg" type="image/jpeg">
                        <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-elston-sp.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-elston-sp.jpg" data-sizes="auto" alt="ELSTON">
                    </picture>
                    <div class="lineup__products-text-container">
                        <h3 class="lineup__products-title"><small>No : 03</small>ELSTON</h3>
                        <p class="lineup__products-des">An oversized Boston frame in acetate with an embedded metal rim and metal studs as accents.</p>
                    </div>
                    <div class="lineup__products-slider-container">
                        <div class="lineup__products-slider-text">
                            <h4 class="lineup__products-type">BOSTON</h4>
                            <h5 class="lineup__products-code">AS2002Z-3S<small>C1 Black</small></h5>
                            <p class="lineup__products-detail">Material: Acetate<br>Size: 51□20-142〇45.2</p>
                            
                            <ul class="lineup__products-color nav" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" style="--c: #000" data-toggle="pill" href="#tab-3-1">C1 Black</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: url(https://www.owndays.com/images/specials/products/shingo-aiba/cl-brown-demi.webp)" data-toggle="pill" href="#tab-3-2">C2 Brown Demi</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c:  url(https://www.owndays.com/images/specials/products/shingo-aiba/cl-gray-halftone.webp)" data-toggle="pill" href="#tab-3-3">C3 Gray Halftone</a>
                                </li>
                            </ul>
                        </div>
                        <div class="lineup__products-slider tab-content">
                            
                            <div class="tab-pane show active" id="tab-3-1">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_01.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_02.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_03.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_04.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_05.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C1/AS2002Z-3S_C1_06.jpg" data-sizes="auto" alt="AS2002Z-3S C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2002Z-3S?sku=7424">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-3-2">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_01.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_02.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_03.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_04.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_05.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C2/AS2002Z-3S_C2_06.jpg" data-sizes="auto" alt="AS2002Z-3S C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2002Z-3S?sku=7425">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-3-3">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_01.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_02.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_03.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_04.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_05.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2002Z-3S/C3/AS2002Z-3S_C3_06.jpg" data-sizes="auto" alt="AS2002Z-3S C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2002Z-3S?sku=7426">ONLINE STORE</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="lineup__products-item" id="john-anthony">
                    <picture class="lineup__products-banner" data-aos="fade-up" data-aos-duration="1300">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-john-anthony-pc.webp" type="image/webp">
                        <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-john-anthony-pc.jpg" type="image/jpeg">
                        <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-john-anthony-sp.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/lineup-john-anthony-sp.jpg" data-sizes="auto" alt="JOHN ANTHONY">
                    </picture>
                    <div class="lineup__products-text-container">
                        <h3 class="lineup__products-title"><small>No : 04</small>JOHN ANTHONY</h3>
                        <p class="lineup__products-des">A super-chic frame in the combination of Boston and Wellington styles, featuring a unique design on the lower rim and metallic accents on the nose bridge and temple tips.</p>
                    </div>
                    <div class="lineup__products-slider-container">
                        <div class="lineup__products-slider-text">
                            <h4 class="lineup__products-type">BOSS LYNTON</h4>
                            <h5 class="lineup__products-code">AS2003Z-3S<small>C1 Black</small></h5>
                            <p class="lineup__products-detail">Material: Acetate<br>Size: 50□20-142〇45.6</p>
                            
                            <ul class="lineup__products-color nav" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" style="--c: #000" data-toggle="pill" href="#tab-4-1">C1 Black</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c: url(https://www.owndays.com/images/specials/products/shingo-aiba/cl-brown-demi.webp)" data-toggle="pill" href="#tab-4-2">C2 Brown Demi</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="--c:  #e3e3e3" data-toggle="pill" href="#tab-4-3">C3 Clear</a>
                                </li>
                            </ul>
                        </div>
                        <div class="lineup__products-slider tab-content">
                            
                            <div class="tab-pane show active" id="tab-4-1">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_01.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_02.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_03.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_04.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_05.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_06.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_07.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C1/AS2003Z-3S_C1_07.jpg" data-sizes="auto" alt="AS2003Z-3S C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2003Z-3S?sku=7427">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-4-2">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_01.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_02.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_03.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_04.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_05.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C2/AS2003Z-3S_C2_06.jpg" data-sizes="auto" alt="AS2003Z-3S C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2003Z-3S?sku=7428">ONLINE STORE</a>
                            </div>
                            
                            <div class="tab-pane" id="tab-4-3">
                                <div class="swiper">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_01.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_02.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_02.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_03.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_03.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_04.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_04.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_05.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_05.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_06.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/products/AS2003Z-3S/C3/AS2003Z-3S_C3_06.jpg" data-sizes="auto" alt="AS2003Z-3S C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="lineup__products-nav">
                                        <button class="lineup__products-nav-button lineup__products-nav-button--prev"></button>
                                        <button class="lineup__products-nav-button lineup__products-nav-button--next"></button>
                                    </div>
                                </div>
                                <a class="l-shingo-aiba__button" href="/sg/en/products/AS2003Z-3S?sku=7429">ONLINE STORE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="case">
        <div class="case__inner">
            <div class="l-shingo-aiba__container">
                <div class="swiper">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/case-1.webp" type="image/webp">
                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/case-1.jpg" data-sizes="auto" alt="Original Case& Cleaning Cloth Set">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/case-2.webp" type="image/webp">
                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/case-2.jpg" data-sizes="auto" alt="Original Case">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/case-3.webp" type="image/webp">
                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/case-3.jpg" data-sizes="auto" alt="Cleaning Cloth">
                            </picture>
                        </li>
                    </ul>
                    <div class="swiper-pagination"></div>
                </div>
                <div class="case__text-container">
                    <h2 class="l-shingo-aiba__title">Original Case<br>& Cleaning Cloth Set</h2>
                    <p>Each frame in the collection comes with an exclusive foldable spectacle case and a lens cleaning cloth.</p>
                </div>
            </div>
        </div>
        <div class="l-shingo-aiba__button-gp">
            <a href="/sg/en/products?productLines%5B%5D=54" class="l-shingo-aiba__button">ALL ITEMS</a>
            <a href="#store" class="l-shingo-aiba__button l-shingo-aiba__button--small">PRODUCT AVAILABILITY</a>
        </div>
    </section>
    
    <section class="about">
        <div class="l-shingo-aiba__container">
            <div class="about-container">
                <div class="about-image">
                    <picture>
                        <source media="(min-width: 768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/about-img-pc.webp" type="image/webp">
                        <source media="(min-width: 768px)" data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/about-img-pc.jpg" type="image/jpg">
                        <source data-srcset="https://www.owndays.com/images/specials/products/shingo-aiba/about-img-sp.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/shingo-aiba/about-img-sp.jpg" alt="相場慎吾">
                    </picture>
                </div>
                <div class="about-detail">
                    <p class="sub-top">Artist</p>
                    <h2 class="l-shingo-aiba__title">SHINGO AIBA</h2>
                    <div class="about-txt">
                        <p>Shingo Aiba became the first Japanese designer at Saint Laurent after being discovered by Hedi Slimane, who is now the creative director of Celine. During his time in Saint Laurent, Shingo was involved in creating the Paris collections. Drawing on the artistic sensibility and experience acquired in Paris, Shingo has been working in a wide range of creative fields, including arts, creative direction, design, fashion and photography, since leaving the fashion house.</p>
                    </div>
                    <div class="about-link">
                        <a href="https://www.shingoaiba.com/" target="blank">SHINGO AIBA official site</a>
                    </div>
                    <div class="about-link">
                        <a href="https://www.instagram.com/shingoaibaparis/?hl=ja" target="blank">SHINGO AIBA Instagram</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
        <div class="stores" id="store">
        <div class="l-shingo-aiba__container">
            <h2 class="l-shingo-aiba__title">STORES <small>PRODUCT AVAILABILITY</small></h2>
                                                <ul class="l-shingo-aiba__shops l-content--inner c-list-decoration c-list-decoration--dot">
                    <li><a href="/sg/en/shops/909">OWNDAYS nex</a></li>
                    <li><a href="/sg/en/shops/920">OWNDAYS ION Orchard</a></li>
                    <li><a href="/sg/en/shops/948">OWNDAYS Compass One</a></li>
                    <li><a href="/sg/en/shops/928">OWNDAYS Jurong Point</a></li>
                    <li><a href="/sg/en/shops/931">OWNDAYS Vivo City</a></li>
                    <li><a href="/sg/en/shops/933">OWNDAYS Waterway Point</a></li>
                    <li><a href="/sg/en/shops/1005">OWNDAYS Causeway Point</a></li>
                    <li><a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a></li>
                    <li><a href="/sg/en/shops/1020">OWNDAYS Takashimaya S.C Premium Concept Store</a></li>
            </ul>
                                                                                </div>
    </div>
        
    <section class="share">
        <div class="l-shingo-aiba__container">
            <div class="detail-share">
                <h2 class="l-shingo-aiba__title share__title">SHARE</h2>
                <ul class="share__list">
                    <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/shingo-aiba" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/shingo-aiba" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                                    </ul>
            </div>
            <div class="copy-right">
                <p>©︎2020 SHINGO AIBA. ALL RIGHTS RESERVED</p>
            </div>
        </div>
    </section>
</section>
        </main>
            </div>

     
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/jp/zh_tw" class="">
                                            中文 (繁體)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/vi" class="">
                                            Tiếng Việt
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/vn/en" class="">
                                            English
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/sg/en" class="active">
                                            English
                                        </a>
                                    </li>
                                                                                                                                                                                                                    </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/th" class="">
                                            ภาษาไทย
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/en" class="">
                                            English
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/ja" class="">
                                            日本語
                                        </a>
                                    </li>
                                                                                                        <li>
                                        <a href="https://www.owndays.com/th/zh_cn" class="">
                                            中文 (简体)
                                        </a>
                                    </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://unpkg.com/aos@next/dist/aos.js"></script>





<script src="https://unpkg.com/swiper@8/swiper-bundle.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-shingo-aiba.js?v=1707081086"></script>
<script src="https://www.owndays.com/web/js/specials-shingo-aiba.js?v=1706779161"></script>
      
<!-- DO NOT MODIFY -->

<!-- User Insight PCDF Code Start : owndays.com -->
<script type="text/javascript">
    var _uic = _uic ||{}; var _uih = _uih ||{};_uih['id'] = 52715;
    _uih['lg_id'] = '';
    _uih['fb_id'] = '';
    _uih['tw_id'] = '';
    _uih['uigr_1'] = ''; _uih['uigr_2'] = ''; _uih['uigr_3'] = ''; _uih['uigr_4'] = ''; _uih['uigr_5'] = '';
    _uih['uigr_6'] = ''; _uih['uigr_7'] = ''; _uih['uigr_8'] = ''; _uih['uigr_9'] = ''; _uih['uigr_10'] = '';

    /* DO NOT ALTER BELOW THIS LINE */
    /* WITH FIRST PARTY COOKIE */
    (function() {
        var bi = document.createElement('script');bi.type = 'text/javascript'; bi.async = true;
        bi.src = '//cs.nakanohito.jp/b3/bi.js';
        var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(bi, s);
    })();
</script>
<!-- User Insight PCDF Code End : owndays.com -->

<script>
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CGHAVFBC77UA88R0IJ2G');
  ttq.page();
}(window, document, 'ttq');
</script>     <!-- Site 24/7 monitoring service -->
        
        

    <!-- Rtoaster Popup -->
                 <!-- /Rtoaster Popup -->
</body>

</html>`;

const ShingoAibaHTML = () => {
  useEffect(() => {
    const loadScripts = async () => {
      try {
        const scriptUrls = [
          'https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3',
          'https://code.jquery.com/jquery-3.5.1.min.js',
          'https://www.owndays.com/web/js/jquery.easing.1.3.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js',
          'https://www.owndays.com/web/js/helper.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js',
          'https://cdn.xapping.com/libs.js',
          'https://unpkg.com/aos@next/dist/aos.js',
          'https://unpkg.com/swiper@8/swiper-bundle.min.js',
          'https://www.owndays.com/web/js/specials-shingo-aiba.js?v=1707081086',
        ];

        const loadScript = url =>
          new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
          });

        for (const scriptUrl of scriptUrls) {
          await loadScript(scriptUrl);
        }

        window.AOS.init();

        $('.attention__item-q').on('click', function () {
          $(this).parent().toggleClass('open');
        });
      } catch (error) {
        console.error('Failed to load scripts:', error);
      }
    };

    loadScripts();
  }, []);
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris.',
          },
          {
            name: 'twitter:description',
            content:
              'A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris.',
          },
          {
            name: 'og:title',
            content:
              'SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
          {
            name: 'twitter:title',
            content:
              'SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
        ]}
        description="A capsule collection created in collaboration with Japanese designer, Shingo Aiba whose credentials include a tenure in a global fashion house in Paris."
        title="SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION | OWNDAYS ONLINE STORE - OPTICAL SHOP"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default ShingoAibaHTML;
