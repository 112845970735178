import React from 'react';
import Breadcrumb from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';
import { HOWTOUSE_DATA } from '../data';

import './HowToUse.scss';

const HowToUse = ({ match }) => {
  const { category } = match.params;

  // Define a mapping for categories to display text
  const categoryMapping = {
    'clear-contactlens': 'OWNDAYS CLEAR VISION',
    'color-contactlens': 'OWNDAYS COLOUR CONTACT LENS',
  };

  // Get the display text or fallback to "Invalid Category"
  const displayCategory = categoryMapping[category] || 'Invalid Category';

  const crumbs = [
    { label: 'Homepage', path: '/sg/en' },
    { label: 'Contacts', path: '#' },
    { label: displayCategory, path: `/sg/en/contacts/${category}` },
    { label: 'HOW TO USE', path: '/sg/en/contacts/how-to-use' },
  ];

  const scrollToContent = id => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <main className={`htu htu--${category}`}>
        <section className="htu__header">
          <div className="htu__container">
            <h1>How to use</h1>
            <p>
              Correct way of using OWNDAYS CLEAR VISION contact lenses.
              <br />
              To protect the health of your eyes, here’s a guide on how to use the contact lenses
              correctly.
            </p>
            <ul>
              {HOWTOUSE_DATA?.steps?.map((step, index) => (
                <li key={step?.title} onClick={() => scrollToContent(`step-${index + 1}`)}>
                  <h3>
                    <strong>STEP {index + 1}</strong>
                    {step?.title}
                  </h3>
                  <img alt={step?.title} loading="lazy" src={step?.icon} />
                  <svg
                    fill="#0094c8"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m320-116-34-34 330-330-330-330 34-34 364 364-364 364Z" />
                  </svg>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="htu__steps">
          <div className="htu__container">
            {HOWTOUSE_DATA?.steps?.map((step, index) => (
              <div key={step?.title} className="htu__steps-sec" id={`step-${index + 1}`}>
                <h2>
                  <strong>STEP {index + 1}</strong>
                  {step?.title}
                </h2>
                {step?.items && (
                  <ul>
                    {step?.items?.map((item, itemIdx) => (
                      <li key={item?.title}>
                        <img alt={item?.title} loading="lazy" src={item?.icon} />
                        <div>
                          <h3>
                            <strong>{itemIdx + 1}</strong>
                            {item?.title}
                          </h3>
                          <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            <div className="htu__steps-sec">
              <h2>
                <strong>Monthly</strong>
              </h2>
              <ul>
                {HOWTOUSE_DATA?.monthly?.map((item, itemIdx) => (
                  <li key={item?.title}>
                    <img alt={item?.title} loading="lazy" src={item?.icon} />
                    <div>
                      <h3>
                        <strong>{itemIdx + 3}</strong>
                        {item?.title}
                      </h3>
                      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HowToUse;
