export const RESIN = {
  header: [
    {
      title: 'Light and durable',
    },
    {
      title: 'Comfortable and gentle on the skin',
    },
  ],
  frameType: 'Resin Frames',
  desc: `Ultem, an ultra-flexible material used in spacecraft and aircraft parts, is known for being amazingly lightweight, flame-resistant and heat-resistant. It is highly durable, resistant to damage from impacts and is a trusted material used in medical devices.`,
  images: [
    {
      sp: '/resin-1_sp.webp',
      pc: '/resin-1_pc.webp',
      alt: 'Wearing/Resin frame',
    },
    {
      sp: '/resin-2_sp.webp',
      pc: '/resin-2_pc.webp',
      alt: 'Wearing/Resin frame',
    },
  ],
  detail: {
    alt: 'Glasses made of resin',
    src: '/resin-frame.webp',
  },
  features: [
    {
      src: '/feature-1.svg',
      desc: 'Lightweight, flexible and resistant to damage even from impacts or pressure',
    },
    {
      src: '/feature-2.svg',
      desc: 'High resistance to flames, heat and deformation under high temperatures',
    },
    {
      src: '/feature-3.svg',
      desc: 'Eco-friendly material recognised by FDA',
    },
  ],
  descSection: {
    title: 'High-Performance Eyewear: Cleared Durability Tests',
    desc: `Temples withstood over 10,000 bends without deformation in a durability test conducted with equipment developed by the Japan Spectacles and Popular Optics Inspection Institute.
Passed durability and bridge deformation tests based on Japanese Industrial Standards (JIS B 7285:2008).`,
    link: '/sg/en/eyeglasses/brands/air.html',
  },
};

export const METAL = {
  header: [
    {
      title: 'Lightweight, flexible and shape-retaining',
    },
    {
      title: 'Even though it’s metal',
    },
  ],
  frameType: 'Metal Frames',
  desc: `These Nitinol (NiTi) frames are made using the latest ion plating (IP) process. Nitinol is a shape memory alloy consisting of nickel and titanium. The material exhibits great elasticity and durability. It is able to retain its shape despite extensive bending. Ion plating is applied on the frame surface to enhance durability and resistance to rust. This prevents the frame coating from peeling or scratching, offering long-lasting quality.`,
  images: [
    {
      sp: '/metal-1_sp.webp',
      pc: '/metal-1_pc.webp',
      alt: 'Wearing/Metal frame',
    },
    {
      sp: '/metal-2_sp.webp',
      pc: '/metal-2_pc.webp',
      alt: 'Wearing/Metal frame',
    },
  ],
  detail: {
    alt: 'Metal made of resin',
    src: '/metal-frame.webp',
  },
  features: [
    {
      src: '/feature-4.svg',
      desc: 'NiTi alloy offers great elasticity and shape retention',
    },
    {
      src: '/feature-1.svg',
      desc: 'Lightweight and comfortable',
    },
    {
      src: '/feature-5.svg',
      desc: 'Durable and rust-resistant thanks to ion plating',
    },
  ],
  descSection: {
    title: 'Passed Japanese Industrial Standards (JIS)\nTemple Durability Test',
    desc: `The frames successfully passed bridge deformation and durability tests conducted based on Japanese Industrial Standards (JIS B 7285:2016). Additionally, a test conducted by the Japan Spectacles and Popular Optics Inspection Institute demonstrated that the temples endured over 10,000 bends without distortion.`,
    link: '/sg/en/eyeglasses/brands/air.html',
  },
};

export const RESIN_LINEUP = [
  { model: 'AU2092T-2A', delay: 0 },
  { model: 'AU2093T-2A', delay: 100 },
  { model: 'AU2094T-2A', delay: 200 },
];

export const METAL_LINEUP = [
  { model: 'MM1013B-3S', delay: 0 },
  { model: 'MM1014B-3S', delay: 100 },
  { model: 'MM1015B-3S', delay: 200 },
];

export const RECOMMENDED = [
  {
    src: '/pc.webp',
    title: 'Blue Light Lenses',
    desc: 'Protect your eyes from blue light when using the computer or smartphone',
    link: '/sg/en/services/lens#blue-light-lenses',
  },
  {
    src: '/color.webp',
    title: 'Colour Lenses',
    desc: 'Pair them with your favourite spectacle frame to elevate your style or use them as sunglasses',
    link: '/sg/en/services/lens#color',
  },
  {
    src: '/progressive.webp',
    title: 'Progressive Lenses',
    desc: 'Just one pair of glasses to see far, intermediate and reading zones',
    link: '/sg/en/services/lens#progressive',
  },
  {
    src: '/antifog.webp',
    title: 'Anti-Fog Lenses',
    desc: 'With a special coating that prevents the lenses from fogging up, keeping your vision crystal clear at all times',
    link: '/sg/en/services/lens#antifog',
  },
  {
    src: '/transition.webp',
    title: 'Transitions Lenses',
    desc: 'Lens colour changes based on amount of UV, allowing your spectacles to double as sunglasses',
    link: '/sg/en/services/lens#transitions-light',
  },
  {
    src: '/polarised.webp',
    title: 'Polarised Lenses',
    desc: 'Reduce glare on shiny surfaces like water, making them perfect for the outdoors',
    link: '/sg/en/services/lens#polarised',
  },
];
