import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderAir.scss';

export const HeaderAir = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS | AIR' },
  ];

  const links = [
    { href: '#resin', label: 'Resin Frames' },
    { href: '#metal', label: 'Metal Frames' },
    { href: '#recommended', label: 'Optional Lenses' },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);
    if (element) {
      const topOffset = element.offsetTop;

      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, [window.location.hash]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-air__main">
        <div className="header-air__main__bg">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main_pc.webp`} />
            <img alt="OWNDAYS | AIR" src={`${baseImgUrl}/main_sp.webp`} />
          </picture>
        </div>
        <div className="header-air__main__text">
          <div className="base-air__container">
            <h1>
              <img alt="OWNDAYS | AIR" src={`${baseImgUrl}/logo.svg`} />
            </h1>
            <h2>
              Incredibly Lightweight, <br />
              The Durability You Can Rely On
            </h2>
          </div>
        </div>
      </section>

      <section className="header-air__about">
        <div className="header-air__about__bg">
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={`${baseImgUrl}/about-video-poster_pc.webp`}
            width="100%"
          >
            <source src={`${baseImgUrl}/video.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="header-air__about__text">
          <div className="base-air__container">
            <p>
              Engineered with ultra-lightweight and highly durable materials to deliver a wearing
              comfort that feels like air, these spectacle frames offer an impeccable fit and can be
              worn comfortably for long hours.
            </p>
          </div>
        </div>

        <a
          className="header-air__about__play"
          data-fancybox="etc"
          href="https://youtu.be/AwWlrpNQeYg"
        >
          Play
        </a>
      </section>

      <section className="header-air__navs">
        <div className="base-air__container">
          <ul>
            {links.map(({ href, label }) => (
              <li key={href} onClick={() => scrollToSection(href)}>
                <a>{label}</a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
