import React from 'react';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import BundleSale from '../CommonComponents/BundleSale/BundleSale';

const BundleSaleHTML = () => {
  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS Contact Lens Promotion' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={breadcrumbs} />
      <BundleSale />
      <div id="modal-root"></div>
    </>
  );
};

export default BundleSaleHTML;
