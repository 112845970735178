import React, { useState, useEffect, useMemo } from 'react';
import './OdCartBillDetails.scss';
import OdApplyCoupon from './ApplyCouponCart/OdApplyCoupon';
import AddGiftCard from './../../../../own-days/Mobile/AddGiftCard/AddGiftCard';
import MembershipDiscount from './../../../../own-days/CommonComponents/MembershipDiscount/MembershipDiscount';
import OdDeliveryTruck1Icon from '../../../../../static/assets/svg/od-delivery-truck-1-icon.svg';
import OdShieldProtectIcon from '../../../../../static/assets/svg/od-shield-protect-icon.svg';
import OdCheckCircleIcon from '../../../../../static/assets/svg/od-check-circle-icon.svg';
import UnlockedDiscount from '../../../../../static/assets/svg/cart-unlocked-discount.svg';
import { setToastMessage } from '../../../../actionCreators/common';
import OdCartShimmer from '../../../../own-days/CommonComponents/Shimmer/OdCartShimmer/OdCartShimmer';
import AuthModalNewLoadable from '../../AuthModalNew/AuthModalNewLoadable';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
  applyMembershipPoints,
  getMembershipPoints,
} from '../../../../actionCreators/membershipPoints';
import { getCart } from '../../../../actionCreators/cart';
import GiftCardList from '../../../../own-days/CommonComponents/GiftCardList/GiftCardList';
import { odPushGA4Event } from '../../../../utils/gaFour';
import { odPushTTQEvent } from '../../../../utils/tiktokPixel';
import { odPushClevertapEvent } from '../../../../utils/clevertap';
import { getDiscountFromCart } from '../../../../own-days/helper';
// return if needed delivery section
// import DeliveryDetails from '../../../../own-days/CommonComponents/PaymentOrderSummury/DeliveryDetails/DeliveryDetails';
// import { Link } from 'react-router-dom';

const OdCartBillDetails = props => {
  const {
    cartObj,
    dataLocale,
    currencyMark,
    redisCommonData,
    cartData,
    history,
    handleProceed,
    isPayment = true,
    isLoading,
    hideCouponAndGiftCard,
  } = props;

  const {
    FREE,
    ORDER_TOTAL,
    BILL_DETAILS,
    SELECT_ADDRESS_CHECKOUT,
    CURRENCY_CODE,
    USE,
    MEMBERSHIP_POINTS,
    ITEM_TOTAL,
    MEMBERSHIP_DISCOUNT,
    FREE_SHIPPING,
    ONE_YEAR_WARRANTY,
    SECURE_PAYMENT,
  } = dataLocale;

  const dispatch = useDispatch();

  const membershipData = useSelector(state => state.membershipPoints.data);
  const applyMembershipData = useSelector(state => state.membershipPoints.applyMembershipData);
  const applyMembershipError = useSelector(state => state.membershipPoints.applyMembershipError);
  const removeMembership = useSelector(state => state.membershipPoints.removeMembership);
  const userInfo = useSelector(state => state.common.userInfo);
  const login = useSelector(state => state.common.login);
  const cartMembershipPoints = cartObj?.membershipPoints;
  // const membershipPointsApplied = cartData?.result?.membershipPointsApplied;

  // FIXME: Test data for gold membership
  const [isApplyMembershipDiscount, setIsApplyMembershipDiscount] = useState(false);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);

  const { NEW_CART_FLOW_DESKTOP } = redisCommonData;
  const currencyCode = cartObj?.currencyCode ?? CURRENCY_CODE;
  const currencySymbol = currencyMark[currencyCode];
  const showCoupon = !!(
    NEW_CART_FLOW_DESKTOP && JSON.parse(NEW_CART_FLOW_DESKTOP)?.enableApplyCoupon === 'ON'
  );
  const lastVisibleEntryIndex = cartObj?.finalTotal?.findLastIndex(
    entry => !(entry?.type === 'tax' && entry?.value === 0)
  );

  const path = window.location.pathname;

  const isCartPage = path.includes('/cart');
  const isPaymentPage = path.includes('/payment-page');
  const isShippingPage = path.includes('/checkout/onepage');

  useEffect(() => {
    dispatch(getMembershipPoints());
  }, []);

  useEffect(() => {
    if (applyMembershipError) {
      setIsApplyMembershipDiscount(false);
    }
  }, [applyMembershipError]);

  useEffect(() => {
    if (applyMembershipData) {
      dispatch(getCart());
      setIsApplyMembershipDiscount(false);
    }
  }, [applyMembershipData, dispatch]);

  useEffect(() => {
    if (isApplyMembershipDiscount) {
      dispatch(applyMembershipPoints());
    }
  }, [dispatch, isApplyMembershipDiscount]);

  const handleAddDiscountMembershipPoints = () => {
    setIsApplyMembershipDiscount(true);
  };

  useEffect(() => {
    console.log('🚀 ~ emitGAEvent ~ cartObj:', cartObj);
  }, [cartObj]);

  const emitGAEvent = () => {
    const gaPLPSelectItemData = JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

    const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

    odPushGA4Event('begin_checkout', {
      userInfo,
      cartData,
      gaPLPSelectItemData,
      gaAddToCartData,
    });

    odPushClevertapEvent('Proceed to checkout - cart', {
      userInfo,
      cartData,
    });

    odPushTTQEvent('InitiateCheckout', {
      userInfo,
      cartData,
    });
  };

  const giftVoucher = useMemo(() => {
    return (
      cartObj?.finalTotal &&
      cartObj?.finalTotal.length > 0 &&
      cartObj?.finalTotal.filter(el => el.id === 'gv')
    );
  }, [cartObj?.finalTotal]);

  const handleContinueShopping = () => {
    history.push('/');
  };

  let proceedButtonText = '';
  if (isCartPage) {
    proceedButtonText = 'Select address & checkout';
  } else if (isShippingPage) {
    proceedButtonText = 'Proceed to checkout';
  } else if (isPaymentPage) {
    proceedButtonText = 'Complete payment';
  } else {
    proceedButtonText = 'Proceed to checkout';
  }

  const eyeframeItems =
    cartObj &&
    cartObj?.items &&
    cartObj?.items?.length > 0 &&
    cartObj?.items.filter(item => item.classification === 'eyeframe');

  if (isLoading) {
    return <OdCartShimmer />;
  }

  return cartObj ? (
    <>
      <div className="od-cart-bill-details">
        <div className="od-cart-bill-details__title od-font-bold">{BILL_DETAILS}</div>

        <div className="od-cart-bill-details__rows">
          <div className="od-cart-bill-details__item od-font-reg">{ITEM_TOTAL}</div>
          {cartObj?.totals?.subTotal && (
            <div className="od-cart-bill-details__item-price od-font-reg">
              {`${cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol} ${
                cartObj?.totals?.subTotal
              }`}
            </div>
          )}

          {giftVoucher && giftVoucher.length > 0 && (
            <>
              <div className="od-cart-bill-details__item od-font-reg">{giftVoucher[0].label}</div>
              <div className="od-cart-bill-details__item-price od-font-reg">
                {`${
                  cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                } ${giftVoucher[0].value}`}
              </div>
            </>
          )}

          {/* <div className="od-cart-bill-details__item od-font-reg">{TAXES}</div>
          <div className="od-cart-bill-details__item-price od-font-reg">
            {cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol}
            {cartObj?.totals?.taxes &&
              cartObj?.totals?.taxes.length > 0 &&
              cartObj?.totals?.taxes[0]?.amount}
          </div> */}

          {/* Prices */}
          {cartObj?.finalTotal?.length > 0 &&
            cartObj?.finalTotal?.map(
              (entry, index) =>
                entry?.type === 'tax' && (
                  <React.Fragment key={`price-record-${index}`}>
                    <div className="od-cart-bill-details__item od-font-reg">
                      {entry.value === 0 ? 'Shipping Fee' : entry.label}
                    </div>
                    {entry.value === 0 ? (
                      <div className="od-cart-bill-details__item-price od-cart-bill-details__item-price--free od-font-reg">
                        {FREE}
                      </div>
                    ) : (
                      <div className="od-cart-bill-details__item-price od-font-reg">
                        {`${entry.type === 'discount' ? '-' : ''} ${
                          cartObj?.totals?.currencyCode
                            ? cartObj?.totals?.currencyCode
                            : currencySymbol
                        }
                        ${entry.value}`}
                      </div>
                    )}
                  </React.Fragment>
                )
            )}

          {/* {isPayment && applyMembershipData && !removeMembership && membershipPointsApplied && ( */}
          {isPayment && cartObj?.membershipPoints?.membershipPointsRedeemed && (
            <MembershipDiscount
              cartObj={cartObj}
              currencySymbol={
                cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
              }
              setIsApplyMembershipDiscount={setIsApplyMembershipDiscount}
            />
          )}

          {/* {cartMembershipPoints?.membershipPointsDetail?.earnPoints ? (
            <>
              <div className="od-cart-bill-details__item od-font-reg">{POINTS_EARNED}</div>
              <div className="od-cart-bill-details__item-price od-font-reg">
                {cartMembershipPoints?.membershipPointsDetail?.earnPoints} {POINTS}
              </div>
            </>
          ) : null} */}
          {isPayment && <GiftCardList />}
        </div>

        {isPayment &&
        isCartPage &&
        cartObj?.itemsCount > 0 &&
        membershipData?.balance &&
        !cartObj?.membershipPoints?.membershipPointsRedeemed ? (
          <div className="od-cart-bill-details-membership">
            <div className="od-cart-bill-details-membership__checkbox-wrapper">
              <div
                className={`od-cart-bill-details-membership__checkbox ${
                  isApplyMembershipDiscount && 'od-cart-bill-details-membership__checkbox--checked'
                }`}
                onClick={handleAddDiscountMembershipPoints}
              />
              <span
                className="od-cart-bill-details-membership__checkbox-label od-font-bold"
                onClick={() => setIsApplyMembershipDiscount(!isApplyMembershipDiscount)}
              >
                {USE} {membershipData.balance} {MEMBERSHIP_POINTS.toLowerCase()}
              </span>
            </div>
            {isApplyMembershipDiscount && (
              <div className="od-cart-bill-details-membership__discount od-font-reg">
                <span>{MEMBERSHIP_DISCOUNT}</span>
                <span>
                  -{' '}
                  {`
                  ${cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol}
                  ${membershipData.balance}`}
                </span>
              </div>
            )}
          </div>
        ) : null}

        {getDiscountFromCart(cartObj?.finalTotal) ? (
          <div className="od-cart-bill-details__rows">
            <div className="od-cart-bill-details__item od-font-reg">Discount</div>
            <div className="od-cart-bill-details__item-price od-font-bold">
              - {cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol}{' '}
              {getDiscountFromCart(cartObj?.finalTotal)}
            </div>
          </div>
        ) : null}

        <div className="od-cart-bill-details__rows od-cart-bill-details__total-wrapper">
          {/* Total price */}
          <div className="od-cart-bill-details__total od-font-reg">{ORDER_TOTAL}</div>
          {cartObj?.totals?.total && (
            <div className="od-cart-bill-details__total-price od-font-bold">
              {`${cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol} ${
                cartObj?.totals?.total
              }`}
            </div>
          )}
        </div>

        {/* Actions */}
        {isPayment && cartObj?.itemsCount > 0 ? (
          <>
            {/* {history.location.pathname !== '/payment-page' && handleProceed ? ( */}
            {handleProceed ? (
              <button
                className="od-cart-bill-details__button-proceed od-font-reg"
                onClick={() => {
                  emitGAEvent();
                  // odPushClevertapEvent('Proceed On Cart', {
                  //   amount: cartObj?.totals?.total,
                  //   noOfProducts: cartObj?.itemsCount || 0,
                  //   itemNo: cartObj?.itemsCount || 0,
                  //   cartValue: cartObj?.totals?.total,
                  //   isLoggedIn: login,
                  //   // coupon: (couponDetails && couponDetails?.coupon) || 'NA',
                  //   coupon:
                  //     cartObj?.totals?.discounts?.[0]?.type === 'gv'
                  //       ? cartObj?.totals?.discounts?.[0]?.code
                  //       : '' || 'NA',
                  //   membershipPoints: cartObj?.membershipPoints?.membershipPointsRedeemed
                  //     ? cartObj?.membershipPoints?.membershipPointsDiscount?.appliedAmount
                  //     : 0,
                  // });
                  if (!login) {
                    history.push({
                      pathname: '/customer/account/login',
                      state: { prevUrl: window.location.pathname },
                    });
                  } else {
                    handleProceed();
                  }
                }}
              >
                {proceedButtonText}
              </button>
            ) : null}

            {!hideCouponAndGiftCard && (
              <div className="od-cart-bill-details__coupon-gift-btns">
                {showCoupon && (
                  <OdApplyCoupon
                    cartData={cartData}
                    cartObj={cartObj}
                    currencySymbol={
                      cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                    }
                    dataLocale={dataLocale}
                    login={login}
                    redisCommonData={redisCommonData}
                    setCouponDetails={setCouponDetails}
                    style={{ width: '100%' }}
                  />
                )}

                <AddGiftCard
                  cartData={cartData}
                  cartObj={cartObj}
                  currencySymbol={
                    cartObj?.totals?.currencyCode ? cartObj?.totals?.currencyCode : currencySymbol
                  }
                  dataLocale={dataLocale}
                  login={login}
                  redisCommonData={redisCommonData}
                  style={{ width: '100%' }}
                />
              </div>
            )}
          </>
        ) : null}

        {eyeframeItems.length >= 2 && (
          <>
            <div className="od-cart-bill-details__unlocked-discount od-font-reg">
              <img alt="Unlocked Discount" src={UnlockedDiscount} />
              <span>Get 2nd eyeglass at 50% off</span>
            </div>
            <div className="od-cart-bill-details__unlocked-discount od-cart-bill-details__unlocked-discount--exclude od-font-reg">
              {' '}
              * Exclude sales items
            </div>
          </>
        )}

        {/* return if needed delivery section
        {isPaymentPage && (
          <>
            <div className="od-cart-bill-details__delivery">
              <div className="od-cart-bill-details__delivery__title od-font-bold">
                Delivering to
              </div>
              <Link
                className="od-cart-bill-details__delivery__change od-font-reg"
                to="/checkout/onepage"
              >
                Change address
              </Link>
            </div>
            <DeliveryDetails />
          </>
        )} */}
        <div className="od-cart-bill-details__features">
          <div className="od-cart-bill-details__feature">
            <img alt="true" className="od-cart-bill-details__image" src={OdCheckCircleIcon} />
            <div className="od-cart-bill-details__feature-title od-font-reg">
              {ONE_YEAR_WARRANTY}
            </div>
          </div>
          <div className="od-cart-bill-details__feature">
            <img alt="true" className="od-cart-bill-details__image" src={OdDeliveryTruck1Icon} />
            <div className="od-cart-bill-details__feature-title od-font-reg">{FREE_SHIPPING}</div>
          </div>
          <div className="od-cart-bill-details__feature">
            <img alt="true" className="od-cart-bill-details__image" src={OdShieldProtectIcon} />
            <div className="od-cart-bill-details__feature-title od-font-reg">{SECURE_PAYMENT}</div>
          </div>
        </div>
      </div>

      {showAuthPopup && (
        <AuthModalNewLoadable
          allowSignUp
          handleModalClose={() => setShowAuthPopup(false)}
          hideLogin={() => setShowAuthPopup(false)}
          login={login}
          openTab="signin"
        />
      )}
    </>
  ) : null;
};

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: data => dispatch(setToastMessage(data)),
  };
};

export default connect(null, mapDispatchToProps)(OdCartBillDetails);
