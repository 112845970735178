export const kuromiProductsSetting = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '30%',
  slidesToShow: 1,
  speed: 500,
  arrows: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1097,
      settings: {
        centerPadding: '20%',
      },
    },
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '18%',
      },
    },
    {
      breakpoint: 767,
      settings: {
        centerPadding: '10%',
        dots: false,
      },
    },
  ],
};

export const NAV_COLORS_KUROMI = [
  {
    name: 'black',
  },
  {
    name: 'pink-e8',
  },
];

export const NAV_COLORS_KUROMI_2 = [
  {
    name: 'white-e3',
  },
  {
    name: 'pink-e8',
  },
];

export const NAV_COLORS_BAKU = [
  {
    name: 'purple-cd',
  },
  {
    name: 'pink-e8',
  },
];

export const NAV_COLORS_BAKU_2 = [
  {
    name: 'white-e3',
  },
  {
    name: 'pink-e8',
  },
];

export const SR1005B_2A_C1_PRODUCTS = [
  { src: 'SR1005B-2A-C1_01.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_02.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_03.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_04.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_05.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_06.webp', alt: 'SR1005B-2A C1', color: 'black' },
  { src: 'SR1005B-2A-C1_07.webp', alt: 'SR1005B-2A C1', color: 'black' },
];

export const SR1005B_2A_C2_PRODUCTS = [
  { src: 'SR1005B-2A-C2_01.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_02.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_03.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_04.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_05.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_06.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
  { src: 'SR1005B-2A-C2_07.webp', alt: 'SR1005B-2A C2', color: 'pink-e8' },
];

export const SR1005B_2A_POINTS = [
  {
    alt: 'SR1005B-2A POINT 01',
    src: 'SR1005B-2A-point-1.webp',
    desc: 'Metal frame in polygonal shape inspired by Kuromi’s well-defined eyes',
  },
  {
    alt: 'SR1005B-2A POINT 02',
    src: 'SR1005B-2A-point-2.webp',
    desc: 'Intricate engravings in the shape of Kuromi’s ears featured around the rims',
  },
  {
    alt: 'SR1005B-2A POINT 03',
    src: 'SR1005B-2A-point-3.webp',
    desc: 'Temple tips designed to resemble Kuromi’s ears',
  },
];

export const SR1006B_2A_C1_PRODUCTS = [
  { src: 'SR1006B-2A-C1_01.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_02.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_03.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_04.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_05.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_06.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
  { src: 'SR1006B-2A-C1_07.webp', alt: 'SR1006B-2A C1', color: 'purple-cd' },
];

export const SR1006B_2A_C2_PRODUCTS = [
  { src: 'SR1006B-2A-C2_01.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_02.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_03.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_04.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_05.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_06.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
  { src: 'SR1006B-2A-C2_07.webp', alt: 'SR1006B-2A C2', color: 'pink-e8' },
];

export const SR1006B_2A_POINTS = [
  {
    alt: 'SR1006B-2A POINT 01',
    src: 'SR1006B-2A-C1-point-1.webp',
    desc: 'Oversized polygonal metal frame that hints at Baku’s massive body size',
  },
  {
    alt: 'SR1006B-2A POINT 02',
    src: 'SR1006B-2A-C1-point-2.webp',
    desc: 'Front of the frame is emblazoned on each side with a decorative feature in the shape of Baku’s eyes',
  },
  {
    alt: 'SR1006B-2A POINT 03',
    src: 'SR1006B-2A-C1-point-3.webp',
    desc: 'Temple tips shaped like Baku’s ears',
  },
];

export const SR2001B_2A_C1_PRODUCTS = [
  { src: 'SR2001B-2A-C1_01.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_02.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_03.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_04.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_05.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_06.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
  { src: 'SR2001B-2A-C1_07.webp', alt: 'SR2001B-2A C1', color: 'white-e3' },
];

export const SR2001B_2A_C2_PRODUCTS = [
  { src: 'SR2001B-2A-C2_01.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_02.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_03.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_04.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_05.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_06.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
  { src: 'SR2001B-2A-C2_07.webp', alt: 'SR2001B-2A C2', color: 'pink-e8' },
];

export const SR2001B_2A_POINTS = [
  {
    alt: 'SR2001B-2A POINT 01',
    src: 'SR2001B-2A-point-1.webp',
    desc: 'Boston frame with motifs inspired by Kuromi’s tail added as accents',
  },
  {
    alt: 'SR2001B-2A POINT 02',
    src: 'SR2001B-2A-point-2.webp',
    desc: 'Kuromi and skull engravings featured on metal wire cores with a heart-shaped mark added on each side for a touch of cuteness',
  },
  {
    alt: 'SR2001B-2A POINT 03',
    src: 'SR2001B-2A-point-3.webp',
    desc: 'Temple tips designed to resemble Kuromi’s ears',
  },
];

export const SR2002B_2A_C1_PRODUCTS = [
  { src: 'SR2002B-2A-C1_01.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
  { src: 'SR2002B-2A-C1_02.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
  { src: 'SR2002B-2A-C1_03.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
  { src: 'SR2002B-2A-C1_04.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
  { src: 'SR2002B-2A-C1_05.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
  { src: 'SR2002B-2A-C1_06.webp', alt: 'SR2002B-2A C1', color: 'white-e3' },
];

export const SR2002B_2A_C2_PRODUCTS = [
  { src: 'SR2002B-2A-C2_01.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
  { src: 'SR2002B-2A-C2_02.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
  { src: 'SR2002B-2A-C2_03.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
  { src: 'SR2002B-2A-C2_04.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
  { src: 'SR2002B-2A-C2_05.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
  { src: 'SR2002B-2A-C2_06.webp', alt: 'SR2002B-2A C2', color: 'pink-e8' },
];

export const SR2002B_2A_POINTS = [
  {
    alt: 'SR2002B-2A POINT 01',
    src: 'SR2002B-2A-C1-point-1.webp',
    desc: 'Wellington frame inspired by Baku’s large body and his two-toned body colour',
  },
  {
    alt: 'SR2002B-2A POINT 02',
    src: 'SR2002B-2A-C1-point-2.webp',
    desc: 'A motif bearing inspiration from Baku’s favourite food, Takoyaki, featured on each temple',
  },
  {
    alt: 'SR2002B-2A POINT 03',
    src: 'SR2002B-2A-C1-point-3.webp',
    desc: 'Temple tips shaped like Baku’s ears',
  },
];

export const ABOUT_ELEMENTS = [
  { id: 1, src: `/about-el-1.svg` },
  { id: 2, src: `/about-el-2.svg` },
  { id: 3, src: `/about-el-2.svg` },
  { id: 4, src: `/about-el-3.svg` },
];

export const SOCIAL_LINKS = [
  {
    platform: 'facebook',
    className: 'footer-kuromi-collection__share__social-item--fb',
    url: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/kuromi',
  },
  {
    platform: 'twitter',
    className: 'footer-kuromi-collection__share__social-item--tw',
    url: 'https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/kuromi',
  },
  {
    platform: 'line',
    className: 'footer-kuromi-collection__share__social-item--line',
    url: 'https://social-plugins.line.me/lineit/share?url=https://www.owndays.com/sg/en/news/kuromi',
  },
];
