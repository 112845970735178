import React, { Fragment, useRef, useState } from 'react';
import Slider from 'react-slick';

import { LensColourFreakStore } from './LensColourFreakStore';
import { LENS_COLOUR_FK2002N_4S } from '../constants';

import './PointLineupFreakStore.scss';

export const PointLineupFreakStore = props => {
  const { baseImgUrl, data, products, model, selectedKey, setSelectedKey } = props;

  const [newData, setNewData] = useState(data.find(({ key }) => key === selectedKey));

  const mainSliderRef = useRef(null);
  const pointSliderRef = useRef(null);
  const productSliderRef = useRef(null);

  const defaultSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    draggable: true,
    infinite: true,
    swipe: true,
    touchMove: true,
  };

  const renderPagination = (index, isNumber) => {
    const baseDotClass = 'point-lineup-freak-store__product__slider__dot';

    let dot2 = `${baseDotClass}--brown-ce`;
    let dot3 = `${baseDotClass}--bullet-grey`;

    if (model === 'FK1001B-4S') {
      dot3 = `${baseDotClass}--brown-ce`;
    }

    if (model === 'FK2002N-4S') {
      dot2 = `${baseDotClass}--brown-demi`;
    }

    if (model === 'FK1002B-4S') {
      dot2 = `${baseDotClass}--gold`;
      dot3 = `${baseDotClass}--silver`;
    }

    const classMapping = {
      0: `${baseDotClass}--black`,
      1: dot2,
      2: dot3,
    };

    if ((isNumber && model === 'FK1001B-4S') || (isNumber && model === 'FK1002B-4S')) {
      let indexSlide = index + 1;

      if (index === 0) {
        indexSlide = 2;
      }

      if (index === 1) {
        indexSlide = 1;
      }

      return <button>{String(indexSlide).padStart(2, '0')}</button>;
    }

    return isNumber ? (
      <button>{String(index + 1).padStart(2, '0')}</button>
    ) : (
      <button className={classMapping[index]}></button>
    );
  };

  const glassesMainSlider = {
    ...defaultSlider,
    initialSlide: model === 'FK1001B-4S' || model === 'FK1002B-4S' ? 1 : 0,
    customPaging: i => renderPagination(i, true),
    beforeChange: (prev, next) => {
      let newNext = next;

      if (model === 'FK1001B-4S' || model === 'FK1002B-4S') {
        if (next === 0) {
          newNext = 1;
        }

        if (next === 1) {
          newNext = 0;
        }
      }

      pointSliderRef.current?.slickGoTo(newNext);
    },
  };

  const glassesPointSlider = {
    ...defaultSlider,
    beforeChange: (prev, next) => {
      let newNext = next;

      if (model === 'FK1001B-4S' || model === 'FK1002B-4S') {
        if (next === 0) {
          newNext = 1;
        }

        if (next === 1) {
          newNext = 0;
        }
      }
      mainSliderRef.current?.slickGoTo(newNext);
    },
  };

  const productFK2001B_4S = {
    ...defaultSlider,
    initialSlide: data.findIndex(({ key }) => key === selectedKey),
    customPaging: i => renderPagination(i),
    beforeChange: async (prev, next) => {
      await productSliderRef.current?.slickGoTo(next);

      const newSelected = data[next];
      setSelectedKey(newSelected.key);
      setNewData(newSelected);
    },
  };

  if (!newData) {
    return null;
  }

  const { mainList, points, after } = newData;
  const alt = `${model} ${selectedKey}`;

  return (
    <Fragment>
      <div className="point-lineup-freak-store__glasses__container">
        <Slider
          ref={mainSliderRef}
          {...glassesMainSlider}
          className={`point-lineup-freak-store__glasses__main dot-lineup-freak-store__${model}_${selectedKey}`}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {mainList.map((item, index) => (
            <img key={index} alt={alt} src={`${baseImgUrl}/${item.src}`} />
          ))}
        </Slider>

        <Slider
          ref={pointSliderRef}
          {...glassesPointSlider}
          className="point-lineup-freak-store__glasses__point"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {points.map(point => (
            <div key={point.title}>
              <img alt={alt} src={`${baseImgUrl}/${point.src}`} />
              <h3>{point.title}</h3>
              <p>{point.desc}</p>
            </div>
          ))}
        </Slider>
      </div>

      <div
        className={`point-lineup-freak-store__product__container ${
          model === 'FK2002N-4S'
            ? 'point-lineup-freak-store__glasses__container--column-reverse'
            : ''
        }
          ${
            model === 'FK1001B-4S' || model === 'FK1002B-4S'
              ? 'point-lineup-freak-store__product__container--center'
              : ''
          }
        `}
      >
        <Slider
          ref={productSliderRef}
          {...productFK2001B_4S}
          className="point-lineup-freak-store__product__slider"
          data-aos="fade-up"
        >
          {products.map(product => (
            <div key={`${model} ${product.key}`}>
              <img alt={`${model} ${product.key}`} src={`${baseImgUrl}${product.src}`} />
              <div className="point-lineup-freak-store__product__slider__desc">
                <h3>
                  <span>{product.key}</span>
                  <span>{product.color}</span>
                </h3>
                {/* <p>{product.price}</p> */}
              </div>
            </div>
          ))}
        </Slider>

        {after && (
          <div className="point-lineup-freak-store__product__after">
            <p className="point-lineup-freak-store__product__after__title">AFTER</p>
            <img alt="FK2001B-4S C4" src={`${baseImgUrl}/${after.src}`} />

            <div className="point-lineup-freak-store__product__after__desc">
              <div className="point-lineup-freak-store__product__after__desc--tag">
                <p>{after.tag}</p>
              </div>
              <div className="point-lineup-freak-store__product__after__desc--color">
                <img alt="" src={`${baseImgUrl}/${after.color.src}`} />
                <span>{after.color.name}</span>
              </div>
            </div>
          </div>
        )}

        {model === 'FK2002N-4S' && (
          <LensColourFreakStore
            baseImgUrl={baseImgUrl}
            list={LENS_COLOUR_FK2002N_4S}
            model={model}
            selectedKey={selectedKey}
          />
        )}
      </div>
    </Fragment>
  );
};
