import React from 'react';
import { CONTACTS_DATA } from '../data';
import Breadcrumb from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './ContactCategory.scss';

const ContactCategory = ({ match }) => {
  const { category } = match.params;

  // Find the category data
  const categoryData = CONTACTS_DATA.categories.find(cat => cat.id === category);

  if (!categoryData) {
    return <p>Category not found.</p>;
  }

  const crumbs = [
    { label: 'Homepage', path: '/sg/en' },
    { label: 'Contacts', path: '#' },
    { label: `${categoryData.name}`, path: `/sg/en/contacts/${categoryData.id}` },
  ];

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <main className={`contact-category contact-category--${categoryData?.id}`}>
        <section
          className="contact-category__header"
          style={{ backgroundImage: `url(${categoryData?.bg})` }}
        >
          <h1>
            {categoryData?.name}
            <img alt={categoryData?.name} loading="lazy" src={categoryData?.logo} />
          </h1>
          {categoryData?.subHeading && <p>{categoryData?.subHeading}</p>}
          <svg preserveAspectRatio="none" viewBox="0 0 1500 50" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0,0 v25 q10,10 20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 v-25 Z"
              fill="#fff"
            ></path>
          </svg>
        </section>
        <section className="contact-category__banner">
          <div className="contact-category__container contact-category__container--lg">
            <a href="/sg/en/news/bundle-sale">
              <img
                alt="BUNDLE SALE"
                loading="lazy"
                src="https://static1.lenskart.com/media/owndays/desktop/img/contact-lens-contactlenspromo.webp"
              />
            </a>
          </div>
        </section>
        <section className="contact-category__lineup">
          <div className="contact-category__container">
            <h2>LINE UP</h2>
            <ul className="contact-category__lineup-list">
              {categoryData?.types?.map(type => (
                <li key={type?.id}>
                  <img
                    alt={type?.name}
                    className={type?.thumbnailShape}
                    loading="lazy"
                    src={type?.thumbnail}
                  />
                  <ul>
                    {type?.tags?.map(tag => (
                      <li key={tag}>{tag}</li>
                    ))}
                  </ul>
                  <h4>{type?.name}</h4>
                  <p>Price: {type?.price}</p>
                  <a
                    className="contact-category__btn contact-category__btn--primary"
                    href={`/sg/en${match.url}/${type?.id}`}
                  >
                    VIEW MORE
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="contact-category__htu">
          <div className="contact-category__container">
            <h2>How to use</h2>
            <img
              alt="How to use"
              loading="lazy"
              src="https://static1.lenskart.com/media/owndays/img/contacts/eye.webp"
            />
            <p>
              Correct way of using OWNDAYS CLEAR VISION contact lenses. <br />
              To protect the health of your eyes, here’s a guide on how to use the contact lenses
              correctly.
            </p>
            <a
              className="contact-category__btn contact-category__btn--secondary"
              href={`/sg/en/contacts/${categoryData?.id}/how-to-use`}
            >
              VIEW MORE
            </a>
          </div>
        </section>
        <section className="contact-category__note">
          <div className="contact-category__container">
            <p>
              For those with astigmatism, it is crucial to consult with an Eye Care Professional to
              determine the best contact lens options available. OWNDAYS Clear Vision is our premium
              in-house contact lens line. With our commitment to quality and choice of materials,
              users can trust in the reliability and durability of our contact lenses. Each pair is
              crafted with meticulous attention to detail, prioritizing both comfort and clarity,
              ensuring a seamless fit for all-day wear. Whether users have myopia or astigmatism,
              our Clear Vision lenses offer comfort and quality performance, allowing them to enjoy
              clear, crisp vision throughout their day.
            </p>
            <h2>Note</h2>
            <p>
              Contact lenses are considered medical devices. With proper handling and use, you will
              be able to enjoy a comfortable wearing experience. Please take note of the following
              when using the lenses.
            </p>
            <ol>
              <li>
                <h3>1. Pre-Consultation</h3>
                <p>
                  It is a must to go through a pre-consultation with an Eye Care Professional before
                  any purchase.
                </p>
              </li>
              <li>
                <h3>2. Proper use of contact lenses</h3>
                <p>
                  Please read through the Product Instruction Guide to understand it fully before
                  using the lenses. The recommended wearing schedule varies for each individual.
                  Please consult your Eye Care Professional. Daily contact lenses are meant for
                  one-time daily use only. Do not re-use them upon removal. Monthly contact lenses
                  expire 30 days after first opening. Do not re-use them after 30 days from first
                  opening.
                </p>
              </li>
              <li>
                <h3>3. Regular eye check</h3>
                <p>
                  To ensure good eye health, we recommend that you go for an eye test every half
                  yearly.
                </p>
              </li>
              <li>
                <h3>4. Proper lens care</h3>
                <p>
                  Improper handling of lenses may cause irritation and infections to the eye. Always
                  practise proper care when handling your contact lenses.
                </p>
              </li>
            </ol>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactCategory;
