import React from 'react';
import { Lunar2023NewYearOperatingHours } from '../CommonComponents/Lunar2023NewYearOperatingHours';

const Lunar2023NewYearOperatingHoursHTML = () => {
  return (
    <>
      <Lunar2023NewYearOperatingHours />
    </>
  );
};

export default Lunar2023NewYearOperatingHoursHTML;
