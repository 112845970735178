import React from 'react';

import { Success } from '../CommonComponents/ResultSubmit';

import '../CommonComponents/ResultSubmit/BaseResultSubmit.scss';

const ResultSubmitHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/result-submit';

  return (
    <>
      <div className="base-result-submit__container">
        <Success baseImgUrl={baseImgUrl} />
      </div>
    </>
  );
};

export default ResultSubmitHTML;
