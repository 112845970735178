import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderEyeCamp.scss';

export const HeaderEyeCamp = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: 'Eye Camp' },
  ];

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-eye-camp">
        <div className="base-eye-camp__bg">
          <picture>
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/csr_main-pc.webp`} />
            <img alt="Eye Camp" src={`${baseImgUrl}/csr_main-sp.webp`} />
          </picture>
        </div>

        <div className="base-eye-camp__title">
          <h1>
            EYE CAMP<span>Seeing the unknown future with OWNDAYS</span>
          </h1>
        </div>
      </section>
    </Fragment>
  );
};
