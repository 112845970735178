import React from 'react';
import { Lunar2022NewYearOperatingHours } from '../CommonComponents/Lunar2022NewYearOperatingHours';

const Lunar2022NewYearOperatingHoursHTML = () => {
  return (
    <>
      <Lunar2022NewYearOperatingHours />
    </>
  );
};

export default Lunar2022NewYearOperatingHoursHTML;
