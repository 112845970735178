import React, { Fragment, useState } from 'react';

import { MainLineupFreakStore, PointLineupFreakStore, LensColourFreakStore } from './Lineup';
import { GoodsFreaksStore } from './Goods';
import {
  MAIN_LINE_UP_FK2001B_4S,
  PRODUCTS_FK2001B_4S,
  POINTS_LINEUP_FK2001B_4S,
  LENS_COLOUR_FK2001B_4S,
  MAIN_LINE_UP_FK2002N_4S,
  POINTS_LINEUP_FK2002N_4S,
  PRODUCTS_FK2002N_4S,
  MAIN_LINE_UP_FK1001B_4S,
  POINTS_LINEUP_FK1001B_4S,
  PRODUCTS_FK1001B_4S,
  MAIN_LINE_UP_FK1002B_4S,
  POINTS_LINEUP_FK1002B_4S,
  PRODUCTS_FK1002B_4S,
} from './constants';

import './BodyFreaksStore.scss';

export const BodyFreaksStore = ({ baseImgUrl }) => {
  const [selectedKeyFK2001B_4S, setSelectedKeyFK2001B_4S] = useState('C4');
  const [selectedKeyFK2002N_4S, setSelectedKeyFK2002N_4S] = useState('C1');
  const [selectedKeyFK1001B_4S, setSelectedKeyFK1001B_4S] = useState('C1');
  const [selectedKeyFK1002B_4S, setSelectedKeyFK1002B_4S] = useState('C1');

  return (
    <Fragment>
      <section className="body-freaks-store__lineup" id="lineup">
        <div className="base-freaks-store__container">
          <h2 className="base-freaks-store__title">
            商品一覧<strong>LINEUP</strong>
          </h2>

          <div className="body-freaks-store__lineup__frame-section">
            <MainLineupFreakStore baseImgUrl={baseImgUrl} data={MAIN_LINE_UP_FK2001B_4S} />

            <PointLineupFreakStore
              baseImgUrl={baseImgUrl}
              data={POINTS_LINEUP_FK2001B_4S}
              model="FK2001B-4S"
              products={PRODUCTS_FK2001B_4S}
              selectedKey={selectedKeyFK2001B_4S}
              setSelectedKey={setSelectedKeyFK2001B_4S}
            />

            <LensColourFreakStore
              baseImgUrl={baseImgUrl}
              list={LENS_COLOUR_FK2001B_4S}
              model="FK2001B-4S"
            />

            <MainLineupFreakStore
              isRowReversePC
              baseImgUrl={baseImgUrl}
              data={MAIN_LINE_UP_FK2002N_4S}
            />

            <PointLineupFreakStore
              baseImgUrl={baseImgUrl}
              data={POINTS_LINEUP_FK2002N_4S}
              model="FK2002N-4S"
              products={PRODUCTS_FK2002N_4S}
              selectedKey={selectedKeyFK2002N_4S}
              setSelectedKey={setSelectedKeyFK2002N_4S}
            />

            <MainLineupFreakStore baseImgUrl={baseImgUrl} data={MAIN_LINE_UP_FK1001B_4S} />

            <PointLineupFreakStore
              baseImgUrl={baseImgUrl}
              data={POINTS_LINEUP_FK1001B_4S}
              model="FK1001B-4S"
              products={PRODUCTS_FK1001B_4S}
              selectedKey={selectedKeyFK1001B_4S}
              setSelectedKey={setSelectedKeyFK1001B_4S}
            />

            <MainLineupFreakStore
              isRowReversePC
              baseImgUrl={baseImgUrl}
              data={MAIN_LINE_UP_FK1002B_4S}
            />

            <PointLineupFreakStore
              baseImgUrl={baseImgUrl}
              data={POINTS_LINEUP_FK1002B_4S}
              model="FK1002B-4S"
              products={PRODUCTS_FK1002B_4S}
              selectedKey={selectedKeyFK1002B_4S}
              setSelectedKey={setSelectedKeyFK1002B_4S}
            />
          </div>
        </div>
      </section>

      <GoodsFreaksStore baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};
