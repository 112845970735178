import React, { Fragment } from 'react';

import { ABOUT_ELEMENTS, SOCIAL_LINKS } from './constants';

import './FooterKuromiCollection.scss';

export const FooterKuromiCollection = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="footer-kuromi-collection__accessory">
        <div className="base-kuromi-collection__container">
          <div
            className="footer-kuromi-collection__accessory__inner base-kuromi-collection__item-corner-top"
            data-aos="fade-up"
          >
            <img
              alt="Kuromi & Baku"
              className="footer-kuromi-collection__accessory__character lazyload"
              data-aos="fade-right"
              data-aos-delay="100"
              src={`${baseImgUrl}/accessory-character.svg`}
            />

            <h3 className="footer-kuromi-collection__accessory__title">
              Exclusive
              <br />
              Merchandise Set
            </h3>

            <picture className="footer-kuromi-collection__accessory__img">
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/accessory-img-pc.webp`}
                type="image/webp"
              />
              <source
                media="(max-width:767px)"
                srcSet={`${baseImgUrl}/accessory-img-sp.webp`}
                type="image/webp"
              />
              <img
                alt="@lang('specials/kuromi.alt.accessory')"
                className="lazyload"
                src={`${baseImgUrl}/accessory-img-sp.jpg`}
              />
            </picture>
            <p className="footer-kuromi-collection__accessory__detail">
              Every Kuromi collaboration frame comes with an exclusive merchandise set consisting of
              a custom-designed spectacle case and a lens cleaning cloth.
            </p>
            <span className="base-kuromi-collection__item-corner-bottom"></span>
          </div>
        </div>
      </section>

      <section className="footer-kuromi-collection__novelty">
        <h2 className="base-kuromi-collection__title">
          Gift with
          <br />
          Purchase
        </h2>
        <div className="base-kuromi-collection__container">
          <div
            className="footer-kuromi-collection__novelty__inner base-kuromi-collection__item-corner-top"
            data-aos="fade-up"
          >
            <small>Limited Quantities</small>
            <h3 className="footer-kuromi-collection__novelty__title">
              Kuromi Stickers<small>with iridescent pouch</small>
            </h3>

            <img
              alt="@lang('specials/kuromi.alt.sticker')"
              className="footer-kuromi-collection__novelty__img"
              src={`${baseImgUrl}/novelty-img.webp`}
            />

            <p className="footer-kuromi-collection__novelty__detail">
              Receive a complimentary set of Kuromi stickers in an iridescent pouch when you purchase any Kuromi collaboration frame with top-up lens, while stocks last!&nbsp;<span className="footer-kuromi-collection__novelty__note">Limited to one redemption per transaction</span>
            </p>
            <img
              alt="baku"
              className="footer-kuromi-collection__novelty__character lazyload"
              data-aos="fade-left"
              data-aos-delay="100"
              src={`${baseImgUrl}/novelty-character.svg`}
            />
            <img
              alt="Go For It!"
              className="footer-kuromi-collection__novelty__goforit base-kuromi-collection__hide-sp lazyload"
              src={`${baseImgUrl}/novelty-go-for-it.svg`}
            />
            <span className="base-kuromi-collection__item-corner-bottom"></span>
          </div>

          <div className="base-kuromi-collection__button-group">
            <a className="base-kuromi-collection__button" href="/stores/sg">
              <span>SEARCH FOR NEARBY STORES</span>
            </a>
          </div>
        </div>
      </section>

      <section className="footer-kuromi-collection__about">
        <div className="base-kuromi-collection__container">
          <div className="footer-kuromi-collection__about__inner" data-aos="fade-up">
            <img
              alt="Kuromi & Baku"
              className="footer-kuromi-collection__about__character lazyload"
              data-aos="fade-left"
              data-aos-delay="200"
              src={`${baseImgUrl}/about-character.svg`}
            />
            <h3 className="footer-kuromi-collection__about__title">
              Popular Sanrio Characters, Kuromi & Baku
            </h3>
            <p className="footer-kuromi-collection__about__detail">
              Kuromi is a Sanrio character introduced in 2005. She is one of the top fan favourites,
              having secured 3rd place in the 2022 Sanrio Character Ranking. Kuromi is a
              self-proclaimed rival of My Melody. Her best features are her black jester hat and the
              pink skull. Baku is Kuromi’s sidekick who flies around with Kuromi on his back. His
              favourite food is Takoyaki.
            </p>
            <a
              className="base-kuromi-collection__button"
              href="https://www.sanrio.co.jp/characters/kuromi/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>Official Website</span>
            </a>

            {ABOUT_ELEMENTS.map(({ id, src }) => (
              <img
                key={id}
                alt=""
                className={`footer-kuromi-collection__about__el footer-kuromi-collection__about__el--${id} lazyload`}
                src={`${baseImgUrl}${src}`}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="footer-kuromi-collection__share">
        <h3 className="base-kuromi-collection__title">SHARE</h3>
        <ul className="footer-kuromi-collection__share__social">
          {SOCIAL_LINKS.map(({ platform, className, url }) => (
            <li
              key={platform}
              className={`footer-kuromi-collection__share__social-item ${className}`}
            >
              <a href={url} rel="noopener noreferrer" target="_blank">
                {platform}
              </a>
            </li>
          ))}
        </ul>
        <p className="footer-kuromi-collection__share__copyright">
          ©2022 SANRIO CO., LTD. APPROVAL
          <br className="base-kuromi-collection__hide-pc" />
          NO. L633449
        </p>
      </section>
    </Fragment>
  );
};
