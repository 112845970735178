import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="format-detection" content="telephone=no">
<meta name="og:title" content="GUNDAM HEAD CASE [Mobile Suit Gundam 40th Anniversary Merchandise] | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
<meta name="keywords" content="HEAD CASE,GUNDAM,40th Anniversary,Collaboration,spectacles,glasses,eye glasses,sunglasses,OWNDAYS" />
<meta name="og:description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta property="og:title" content="GUNDAM HEAD CASE [Mobile Suit Gundam 40th Anniversary Merchandise]" />
<meta property="og:description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/collabo/gundam/pj-head">
                <meta property="og:image" content="https://www.owndays.com/images/collabo/gundam/pj-head/ogp_1200x630.jpg?1666001661">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
    <link href="https://fonts.googleapis.com/css?family=Source+Serif+Pro|Roboto|Noto+Sans|Open+Sans+Condensed:300,700|Unica+One&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css">
<link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css" />
<link rel="preload" type="text/css" as="style" href="https://www.owndays.com/web/css/slick-theme.css"/>
<link rel="stylesheet" type="text/css" href="https://www.owndays.com/web/css/slick-theme.css" />
<link href="https://www.owndays.com/web/css/bx-slider.css" rel="preload" as="style">
<link href="https://www.owndays.com/web/css/bx-slider.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/collabo-gundam-form-contest.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/collabo-gundam-head.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">GUNDAM HEAD CASE [Mobile Suit Gundam 40th Anniversary Merchandise] | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            GUNDAM HEAD CASE                    </li>
        
            </ul>
</div>


<!-- contents_area -->
<section class="l-gundam l-gundam-head">
    
    
    
    <section class="l-gundam__main">
        <picture>
            <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-sp/scroll.svg">
            <img class="lazyload scroll" data-src="https://www.owndays.com/images/collabo/gundam/pj-sp/scroll-sp.svg" alt="">
        </picture>
        <div class="l-gundam__main-inner">
            <h1><small>GUNDAM HEAD CASE</small>Look at that…<br>it’s on!</h1>
            <img class="lazyload logo" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/logo.svg" alt="GUNDAM × OWNDAYS Wear the GUNDAM">
            <div class="l-gundam__main-title">
                <p>Mobile Suit Gundam 40th Anniversary Merchandise<br><small>©OWNDAYS co., ltd. ©SOTSU・SUNRISE</small></p>
                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/gundam/icon-40th.svg" alt="GUNDAM 40th">
            </div>
                        <div class="l-gundam__time">
                <p><i>Limited Quantities Now Available At Outlets</i></p>
            </div>
                        <a href="#available-shop">Available Outlet</a>
                    </div>
    </section>
    <section class="l-gundam__concept">
        <img class="lazyload gradation" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/gradation.webp" alt="">
        <div class="l-gundam__concept-inner">
            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/concept.webp" alt="">
            <h2>CONCEPT<small></small></h2>
            <p>As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for.</p>
            <div class="l-gundam__concept-movie">
                <a data-fancybox="etc" data-caption="GUNDAM × OFWNDAYS CM" href="https://www.youtube.com/watch?v=EILlLbQysfw" class="play">
                    <img class="lazyload thumbnail" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/movie.jpg" alt="">
                    <img class="lazyload play-mark" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/play.svg" alt="">
                </a>
            </div>
            <div class="l-gundam__concept-point">
                <h3><p>Let’s see…<br>Let’s test the performance<br>of the head case!</p></h3>
                <ul>
                    <li>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/point-1.webp" alt="">
                        <p>Pull out the part attached to the antenna and open the dual monitor (the eyes on the head case) to store a pair of spectacles. When the antenna is returned to original position, a start-up sound is activated and the dual monitor lights up.</p>
                    </li>
                    <li>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/point-3.webp" alt="">
                        <p>Stores 6 pairs of glasses – 2 on each side of the head case, 1 at the back and 1 behind the dual monitor (the eyes on the head case)</p>
                    </li>
                    <li>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/point-2.webp" alt="">
                        <p>Creating a beautifully-made 1:7 scale model with careful attention to detail and intricate finishing that is exclusive to this collaboration. Similarly, the collaboration frames would make for worthy additions to every fan’s collection, having undergone a meticulous process that involved research and development, design and prototyping.</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="l-gundam__concept-slider">
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider1.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider2.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider3.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider4.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider5.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider6.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider7.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider8.webp" alt="">
            </div>
            <div>
                <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_slider9.webp" alt="">
            </div>
        </div>
    </section>
    <section class="l-gundam__products" id="available-shop">
        <p class="l-gundam__products-name"><span>Limited Quantity Available</span>Gundam Head Case<small>CASE 78-2</small></p>
        <p class="c-price c-price__default">S$ 580</p>
        <div class="l-gundam__reservation">
            
            
            
                        <div class="c-btn c-btn--primary c-btn--primary-center l-gundam__form l-gundam__form-btn l-gundam__form-btn--white">
                <span class="corner"></span><a href="/sg/en/collabo/gundam/pj-head/reservation">Order Online</a>
            </div>
                    </div>
    </section>
        <section class="l-gundam__box">
        <div class="l-gundam__box-inner">
            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-head/head_box-image.webp" alt="">
            <div>
                <h2><span>SPECIAL DESIGN BOX</span>The Gundam Head Case will be delivered to you <br class="u-pc-only">in a specially designed box.</h2>
                <ul class="c-list-decoration c-list-decoration--asterisk">
                    <li>This box is a packaging used to protect the product during transit.</li>
                    <li class="th-none">Please note that there will be no exchange in the event the box is damaged or stained.</li>
                </ul>
            </div>
        </div>
    </section>
        <section class="l-gundam__spec">
        <div class="l-gundam__spec-inner">
            <h2>Specifications</h2>
            <ul>
                <li>
                    <h4>Size</h4>
                    <p>Approximately W350 x D340 x H355 mm</p>
                </li>
                <li>
                    <h4>Weight</h4>
                    <p>Approximately 2.9 kg</p>
                </li>
                <li>
                    <h4>Material</h4>
                    <p>ABS Resin</p>
                </li>
                <li>
                    <h4>Battery</h4>
                    <p>3 x AA alkaline batteries (1 set included for trial)</p>
                </li>
                <li>
                    <h4>Storage</h4>
                    <p>6 pairs of eyewear<span class="u-text-comment">* Subject to the size of eyewear</span></p>
                </li>
                <li>
                    <h4>Intended Users</h4>
                    <p>Aged 15 years and above</p>
                </li>
                
                
                
                
            </ul>
            <div class="l-gundam__spec-notes">
                <h5>Cautions</h5>
                <p>○ This is a delicate product that should be handled with care. To reduce the risk of damage or accident, do not drop or exert force. ○ Do not place product on slanted surface. ○ Product is not waterproof. Keep away from all sources of liquid.</p>
            </div>
        </div>
    </section>
    <section class="l-gundam__spec">
        <div class="l-gundam__spec-inner">
            <h2>About Online Order</h2>
            <ul>
                                                
                
                
                
                <li>
                    <h4>Retail Price</h4>
                    <p>S$ 580</p>
                </li>
                            </ul>
        </div>
        <ul class="l-gundam__spec-list c-list-decoration c-list-decoration--asterisk">
                        <li>Not valid in conjunction with other discounts, promotions and/or privileges</li>
            <li>Payment by credit card only and must be settled in full before delivery</li>
            <li>No selection of delivery schedule</li>
            <li>Not available for collection in stores</li>
            <li>No cancellation, refund or exchange once an order is placed</li>
            <li>Should there be any manufacturing defect, please contact our customer service team at 6222 0588 within 7 days of receiving the product</li>
            <li>Resale or transfer is strictly prohibited</li>
            <li>Delivery within Singapore only</li>
            <li>The management reserves the right to amend the terms and conditions at any time without prior notice</li>
                    </ul>
    </section>
        <section class="l-gundam__share">
        <div class="l-gundam__form-share">
            <h3>SHARE</h3>
            <ul>
                <li class="sns-item">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/collabo/gundam/pj-head" target="_blank">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                </li>
                <li class="sns-item">
                    <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/collabo/gundam/pj-head" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="sns-item">
                    <a href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/collabo/gundam/pj-head" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-line"></i>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <picture>
        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/line-bottom.svg">
        <img class="lazyload line" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/line-bottom-sp.svg" alt="">
    </picture>
    <section class="l-gundam__projects">
        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/collabo-logo.svg" alt="GUNDAM × OWNDAYS Wear the GUNDAM">
        <ul>
            <li>
                <a href="/sg/en/collabo/gundam/pj-snap">
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/project1_pc.webp">
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/project1_sp.webp" alt="">
                    </picture>
                </a>
                <div>
                    <h5>PROJECT:<span>01</span></h5>
                    <p>Collaboration Frames</p>
                </div>
            </li>
            <li>
                <a href="/sg/en/collabo/gundam/pj-head">
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/project2_pc.webp">
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/project2_sp.webp" alt="">
                    </picture>
                </a>
                <div>
                    <h5>PROJECT:<span>02</span></h5>
                    <p>GUNDAM HEAD CASE</p>
                </div>
            </li>
                    </ul>
        <div class="c-btn c-btn--primary c-btn--primary-center l-gundam__form l-gundam__form-btn l-gundam__form-btn--white">
            <span class="corner"></span><a href="/sg/en/news/gundam">PROJECT TOP</a>
        </div>
    </section>
</section>








        </main>
            </div>


    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
<script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"></script>
<script src="https://www.owndays.com/web/js/scroll_internal-link.js" defer></script>
<script type="text/javascript" src="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>
<script src="https://www.owndays.com/web/js/slick.js" defer></script>
<script src="https://www.owndays.com/web/js/specials-gundam.js" defer></script>
          
</body>

</html>`;

const GundamAnniversaryHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        description="OWNDAYS collaborates with iconic anime, Mobile Suit Gundam, elevating eyewear with unmatched details exclusive to this range. More than just spectacle storage."
        title="Mobile Suit Gundam 40th Anniversary Gundam Head Case | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default GundamAnniversaryHTML;
