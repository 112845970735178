import React from 'react';
import { CONTACTS_DATA } from '../data';
import Breadcrumb from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './ContactType.scss';

const ContactType = ({ match }) => {
  const { category, type } = match.params;

  // Find the category and type data
  const categoryData = CONTACTS_DATA.categories.find(cat => cat.id === category);
  const typeData = categoryData?.types.find(t => t.id === type);

  if (!categoryData || !typeData) {
    return <p>Type not found.</p>;
  }

  const crumbs = [
    { label: 'Homepage', path: '/sg/en' },
    { label: 'Contacts', path: '#' },
    { label: `${categoryData?.name}`, path: `/sg/en/contacts/${categoryData?.id}` },
    {
      label: `${typeData?.productName ? typeData?.productName : typeData?.name}`,
      path: `/sg/en/contacts/${categoryData?.name}/${typeData?.id}`,
    },
  ];

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <main
        className={`contact-type contact-category--${categoryData?.id} contact-type--${typeData?.id}`}
      >
        <section
          className="contact-type__header"
          style={typeData?.bg ? { backgroundImage: `url(${typeData.bg})` } : undefined}
        >
          <div className="contact-type__container">
            <div className="contact-type__header-grid">
              <div className="contact-type__header-logo">
                <h1>
                  {typeData?.name}
                  <img
                    alt={typeData?.name}
                    loading="lazy"
                    src={`https://static1.lenskart.com/media/owndays/img/contacts/${categoryData?.id}/${typeData?.id}/logo.svg`}
                  />
                </h1>
                {typeData?.tagline && <h3>{typeData?.tagline}</h3>}
                {typeData?.colorCount && <p>{typeData?.colorCount} COLOURES</p>}
              </div>
              <div className="contact-type__header-img">
                <img
                  alt={typeData?.name}
                  loading="lazy"
                  src={`https://static1.lenskart.com/media/owndays/img/contacts/${categoryData?.id}/${typeData?.id}/main.webp`}
                />
                {typeData?.type && (
                  <span>
                    <span>{typeData?.type}</span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <svg preserveAspectRatio="none" viewBox="0 0 1500 50" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0,0 v25 q10,10 20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 v-25 Z"
              fill="#fff"
            ></path>
          </svg>
        </section>
        {typeData?.features && (
          <section className="contact-type__features">
            <div className="contact-type__container">
              <h2>Features</h2>
              <ul>
                {typeData?.features?.map((feature, index) => (
                  <li key={feature?.title}>
                    {feature?.icon && (
                      <img alt={feature?.title} loading="lazy" src={feature?.icon} />
                    )}
                    <h3>
                      <strong>{String(index + 1).padStart(2, '0')}</strong>
                      {feature?.title}
                    </h3>
                    <p>{feature?.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
        {typeData?.lineup && (
          <section className="contact-type__lineup">
            <div className="contact-type__container">
              <h2>Line up</h2>
              <ul className="contact-type__lineup-list">
                {typeData?.lineup?.map(l => (
                  <li key={l?.id}>
                    <img
                      alt={l?.name}
                      className="lens"
                      loading="lazy"
                      src={`https://static1.lenskart.com/media/owndays/img/contacts/${categoryData?.id}/${typeData?.id}/lens-${l?.id}.webp`}
                    />
                    <p className="name">{l?.name}</p>
                    <div className="image">
                      <img
                        alt={l?.name}
                        loading="lazy"
                        src={`https://static1.lenskart.com/media/owndays/img/contacts/${categoryData?.id}/${typeData?.id}/image-${l?.id}.webp`}
                      />
                      {l?.new && <span>New</span>}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
        {typeData?.spec && (
          <section className="contact-type__spec">
            <div className="contact-type__container">
              <h2>Spec</h2>
              <ul className="contact-type__spec-list">
                <li>
                  <h3>Product Name</h3>
                  <p>{typeData?.productName ? typeData?.productName : typeData?.name}</p>
                </li>
                <li>
                  <h3>Water Content</h3>
                  <p>{typeData?.spec?.waterContent}</p>
                </li>
                <li>
                  <h3>Diameter (DIA)</h3>
                  <p>{typeData?.spec?.dia}</p>
                </li>
                <li>
                  <h3>Base Curve (BC)</h3>
                  <p>{typeData?.spec?.bc}</p>
                </li>
                <li>
                  <h3>Oxygen Permeability (DK)</h3>
                  <p>{typeData?.spec?.dk}</p>
                </li>
                <li>
                  <h3>Centre Thickness</h3>
                  <p>
                    {typeData?.spec?.centerThickness} <sup>*1</sup>
                  </p>
                </li>
                <li>
                  <h3>Material</h3>
                  <p>{typeData?.spec?.material}</p>
                </li>
                <li>
                  <h3>UV Inhibitor</h3>
                  <p>{typeData?.spec?.uvInhibitor}</p>
                </li>
                {typeData?.spec?.handingTint && (
                  <li>
                    <h3>Handling Tint</h3>
                    <p>{typeData?.spec?.handingTint}</p>
                  </li>
                )}
                <li>
                  <h3>Pack Size</h3>
                  <p>{typeData?.spec?.packSize}</p>
                </li>
                <li>
                  <h3>Power (PWR)</h3>
                  <p>
                    {typeData?.spec?.pwr} <sup>*2</sup>
                  </p>
                </li>
                <li>
                  <h3>Price</h3>
                  <p>{typeData?.price}</p>
                </li>
              </ul>
              <ul className="contact-type__spec-remark">
                <li>*1　For -3.00D</li>
                <li>*2　Below -6.00 in 0.25 steps / Above -6.00 in 0.50 steps</li>
              </ul>
            </div>
          </section>
        )}
        <section className="contact-type__shops">
          <div className="contact-type__container contact-type__container--sm">
            <h2>Available shops</h2>
            <ul className="contact-type__shops-list">
              <li>
                <a href="/sg/en/shops/907">OWNDAYS 313@somerset</a>
              </li>
              <li>
                <a href="/sg/en/shops/908">OWNDAYS Bedok Mall</a>
              </li>
              <li>
                <a href="/sg/en/shops/909">OWNDAYS nex</a>
              </li>
              <li>
                <a href="/sg/en/shops/928">OWNDAYS Jurong Point</a>
              </li>
              <li>
                <a href="/sg/en/shops/931">OWNDAYS Vivo City</a>
              </li>
              <li>
                <a href="/sg/en/shops/933">OWNDAYS Waterway Point</a>
              </li>
              <li>
                <a href="/sg/en/shops/948">OWNDAYS Compass One</a>
              </li>
              <li>
                <a href="/sg/en/shops/962">OWNDAYS Westgate</a>
              </li>
              <li>
                <a href="/sg/en/shops/968">OWNDAYS Northpoint City</a>
              </li>
              <li>
                <a href="/sg/en/shops/983">OWNDAYS Century Square</a>
              </li>
              <li>
                <a href="/sg/en/shops/1004">OWNDAYS Raffles City</a>
              </li>
              <li>
                <a href="/sg/en/shops/1005">OWNDAYS Causeway Point</a>
              </li>
              <li>
                <a href="/sg/en/shops/920">OWNDAYS ION Orchard</a>
              </li>
              <li>
                <a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a>
              </li>
              <li>
                <a href="/sg/en/shops/1011">OWNDAYS BUGIS JUNCTION</a>
              </li>
              <li>
                <a href="/sg/en/shops/1020">OWNDAYS Takashimaya S.C. Premium Concept Store</a>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactType;
