import React, { useRef, useEffect } from 'react';

export const GoodsModalFreaksStore = ({ baseImgUrl, data, setDataModal, isOpen }) => {
  const modalRef = useRef();

  const { title, subTitle, steps } = data;

  const handleClose = () => {
    setDataModal({ isOpen: false, data });
  };

  useEffect(() => {
    const handleOutsideClick = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setDataModal({ isOpen: false, data });
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [data, isOpen, setDataModal]);

  return (
    <div className="goods-freaks-store__modal">
      <div className="goods-freaks-store__modal__container">
        <div ref={modalRef} className="goods-freaks-store__modal__content">
          <div className="goods-freaks-store__modal__header">
            <img
              alt="close"
              className="goods-freaks-store__modal__header__close-icon"
              src={`${baseImgUrl}/original-goods-close-icon.svg`}
              onClick={handleClose}
            />
            <div className="goods-freaks-store__modal__header-title-layout">
              <h4 className="goods-freaks-store__modal__header-title">{subTitle}</h4>
              <h2 className="goods-freaks-store__modal__header-subtitle">{title}</h2>
            </div>
          </div>

          <div className="goods-freaks-store__modal__body">
            <ul className="goods-freaks-store__modal__images">
              {steps.map((step, index) => (
                <li key={index}>
                  <img alt="" src={`${baseImgUrl}${step.src}`} />
                  <div className="goods-freaks-store__modal__number">
                    <span>{index + 1}</span>
                  </div>
                </li>
              ))}
            </ul>

            <ul className="goods-freaks-store__modal__desc-list">
              {steps.map((step, index) => (
                <li key={index}>
                  <div className="goods-freaks-store__modal__number">
                    <span>{index + 1}</span>
                  </div>
                  <p>{step.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
