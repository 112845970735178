import React, { Fragment, useEffect } from 'react';
import AOS from 'aos';

import {
  HeaderFreaksStore,
  BodyFreaksStore,
  FooterFreaksStore,
} from '../CommonComponents/FreaksStore';

import '../CommonComponents/FreaksStore/BaseFreaksStore.scss';

const FreaksStoreHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/freaks-store';

  useEffect(() => {
    AOS.init({ once: true, duration: 1000 });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <HeaderFreaksStore baseImgUrl={baseImgUrl} />
      <BodyFreaksStore baseImgUrl={baseImgUrl} />
      <FooterFreaksStore baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default FreaksStoreHTML;
