import React from 'react';
import './Logo.scss';
import BlankWidget from '../../components/CategoryListing/Widgets/BlankWidgets';
const MainHeaderLogo = props => {
  const { appLogo } = props;

  const onClickLogo = () => {
    // OD__CLEVERTAP__EVENT (Logo Click)
    if (typeof window.clevertap !== 'undefined') {
      window.clevertap.event.push('Logo Click', {
        pageName: window.location.pathname,
      });
      console.log(`🚀 ~ onClickLogo ~ ${window.location.pathname}:`, window.clevertap.event);
    }
  };

  return (
    <div className="logo">
      {appLogo ? (
        <>
          <a href={`${window.location.origin}/sg/en`} onClick={onClickLogo}>
            <img alt="Logo" className="logo__image" src='https://static.lenskart.com/media/owndays/img/owndays.svg' />
          </a>
        </>
      ) : (
        <div className="blank-logo">
          <BlankWidget rowsArray={['1']} styleObj="" />
        </div>
      )}
    </div>
  );
};

export default MainHeaderLogo;
