import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="description" content="Correct way of using OWNDAYS COLOUR CONTACT LENS contact lenses. To protect the health of your eyes, here’s a guide on how to use the contact lenses correctly." />
    <meta name="keywords" content="OWNDAYS COLOUR CONTACT LENS,How to use,contact lens,1 day,10 prices,original" />
    <meta name="og:title" content="HOW TO USE | OWNDAYS COLOUR CONTACT LENS | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
    <meta name="og:description" content="Correct way of using OWNDAYS COLOUR CONTACT LENS contact lenses. To protect the health of your eyes, here’s a guide on how to use the contact lenses correctly." />
    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/contacts/color-contactlens/how-to-use">
          <meta
    property="og:image"
    content="https://www.owndays.com/images/specials/products/color-contactlens/ogp_1200x630.jpg?1606914699"
  >
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
        <link rel="stylesheet" href="https://use.typekit.net/wib1bvw.css">
    <link rel="stylesheet" href="https://use.typekit.net/euq4tnf.css">
    <link href="https://fonts.googleapis.com/css?family=Noto+Sans" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/specials-color-contactlens-sg.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">HOW TO USE | OWNDAYS COLOUR CONTACT LENS | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
                <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            breadcrumbs.specials-title.how-to-use                    </li>
        
            </ul>
</div>


    <!-- contents_area -->
    <section class="wp l-contactlens__howto" id="wrapper">
        <section class="howto top">
            <h3>HOW TO USE</h3>
            <p>Correct way of using OWNDAYS COLOUR CONTACT LENS contact lenses.<br>To protect the health of your eyes, here’s a guide on how to use the contact lenses correctly.</p>
            <ul class="howto_archive">
                <li>
                    <a href="#step1">
                        <p><strong><small>STEP </small>1</strong>Before lens insertion</p>
                        <div class="line_step"></div>
                        <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step1-1.png" alt="">
                        <i class="cursor"></i>
                    </a>
                </li>
                <li>
                    <a href="#step2">
                        <p><strong><small>STEP </small>2</strong>Opening lens packaging</p>
                        <div class="line_step"></div>
                        <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-3.png" alt="">
                        <i class="cursor"></i>
                    </a>
                </li>
                <li>
                    <a href="#step3">
                        <p><strong><small>STEP </small>3</strong>Inserting the lens</p>
                        <div class="line_step"></div>
                        <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-6.png" alt="">
                        <i class="cursor"></i>
                    </a>
                </li>
                <li>
                    <a href="#step4">
                        <p><strong><small>STEP </small>4</strong>Lens removal</p>
                        <div class="line_step"></div>
                        <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-2.png" alt="">
                        <i class="cursor"></i>
                    </a>
                </li>
            </ul>
        </section>
        <section id="step1">
            <h6>STEP <strong>1</strong></h6>
            <p class="step_tit">Before lens insertion</p>
            <ul class="step_inner">
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step1-1.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>1</span>Trim your fingernails</p>
                        <p>Keep your fingernails short to avoid scratching the lens. Ensure the edges of your fingernails are smooth.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step1-2.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>2</span>Wash your hands</p>
                        <p>Wash your hands with soap before touching the lens. Ensure there is no soap remaining on your hands.<br>Only handle the lens with thoroughly cleaned fingers.</p>
                    </div>
                </li>
            </ul>
        </section>
        <section id="step2">
            <h6>STEP <strong>2</strong></h6>
            <p class="step_tit">Opening lens packaging</p>
            <ul class="step_inner">
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-1.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>1</span>Removing lens from the packaging</p>
                        <p>Remove lens from the packaging box.<br>
                            - Ensure your fingers are dry<br>
                            - Always start with the lens for the right eye to prevent mixing up the two sides
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-2.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>2</span>Detaching a lens blister</p>
                        <p>With the aluminium foil facing up, slowly tear to detach a lens blister from the rest.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-3.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>3</span>Checking the lens blister</p>
                        <p>Shake the lens blister lightly to ensure that the lens is immersed in the storage solution.<br>
                            Do not open if you notice any damage or hole on the blister.<br>
                            Please contact the shop of purchase immediately in such events.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-4.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>4</span>Peeling off the foil</p>
                        <p>While holding the blister tightly, lift the edge of the foil and gradually peel off the whole foil.<br>
                            Take care not to spill the storage solution in the blister.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step2-5.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>5</span>Taking out the lens</p>
                        <p>Using your index finger, gently slide the lens along the side of the blister and bring it up with your finger tip.<br>
                            - Take caution not to scratch the lens with your fingernails
                        </p>
                    </div>
                </li>
            </ul>
        </section>
        <section id="step3">
            <h6>STEP <strong>3</strong></h6>
            <p class="step_tit">Inserting the lens</p>
            <ul class="step_inner">
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-1.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>1</span>Dry the lens</p>
                        <p>Swing the lens lightly to remove any storage solution on the lens surface.<br>
                            - Take caution not to drop the lens
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-2.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>2</span>Place the lens on the fingertip</p>
                        <p>Place the lens on the tip of the index finger of your dominant hand.<br>
                            - The lens may slide off if your fingertip or the lens is wet. Dry your fingers and remove any storage solution on the lens surface
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-3.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>3</span>Check for irregularities on lens</p>
                        <p>Check to ensure the lens is clean and clear, without any deformation, decolouration, stain, scratch or damage.<br>
                            Please contact the shop of purchase immediately if you find the above mentioned irregularities on the lens. Do not use the lens.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-4.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>4</span>Check the lens orientation</p>
                        <p>The inner and outer surface of the lens is different. Wearing the lens inside out may cause discomfort and poor vision.<br>
                            Place the lens on your fingertip and make sure it is in a bowl shape and correctly oriented.
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-5.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>5</span>Keep the eye open</p>
                        <p>Hold the upper eyelid firmly with one finger of your free hand and hold down the lower eyelid with one finger of your applying hand.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-6.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>6</span>Insert the lens</p>
                        <p>Carefully place the lens on your cornea.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-7.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>7</span>Close the eye</p>
                        <p>Release the upper eyelid slowly and blink gently to ensure the lens is in the correct position.<br>
                            - Do not blink too hard as this may cause the lens to fall out
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step3-8.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>8</span>After lens insertion</p>
                        <p>Cover the other eye while looking through the lens to ensure that it is inserted correctly and your vision is clear.</p>
                    </div>
                </li>
            </ul>
        </section>
        <section id="step4">
            <h6>STEP <strong>4</strong></h6>
            <p class="step_tit">Lens removal</p>
            <ul class="step_inner">
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-1.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>1</span>Remove the lens</p>
                        <p>Hold down the lower eyelid with your middle finger and use the forefinger to slide the lens to the lower part of the eye.<br>
                            - Do not remove the lens by force. If the lens does not move, blink gently or apply eye drops to increase the moisture in the eye
                        </p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-2.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>2</span>Pinch the lens</p>
                        <p>Pinch the bottom edge of the lens gently with the tips of your thumb and index finger to remove the lens.<br>
                            - Take caution not to scratch your eye while removing the lens<br>
                            * Discontinue use if you experience any discomfort and consult your Eye Care Professional immediately.
                        </p>
                    </div>
                </li>
            </ul>
            <h6 class="monthly-remove">MONTHLY</h6>
            <ul class="step_inner">
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-3.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>3</span>Rinse</p>
                        <p>After removal, rinse lens with multi-purpose solution.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-4.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>4</span>Rubbing</p>
                        <p>Rub both side of the lens gently in a circular motion for 20 secs.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-5.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>5</span>Disinfection & Storage</p>
                        <p>Fill the case with clean multi-purpose solution and store the lens inside the case for at least 6 hours for disinfection.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-6.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>6</span>Insertion</p>
                        <p>Rinse the lens with multi-purpose solution before insertion onto the eye.</p>
                    </div>
                </li>
                <li>
                    <img src="https://www.owndays.com/images/specials/products/owndays-contactlens/howtouse/step4-7.png" alt="">
                    <div class="howto_disc">
                        <p class="howto_tit"><span>7</span>Case cleaning</p>
                        <p>After insertion, empty the old solution and rinse it with fresh multi-purpose solution. Air dry the case in a dry environment.</p>
                    </div>
                </li>
            </ul>
        </section>
    </section>
    <!-- /contents_area -->
        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
    <script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
    <script src="https://www.owndays.com/web/js/sg/specials-color-contactlens.js" defer></script>
  
</body>

</html>`;

const HowToUseHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        canonical="/contacts/color-contactlens/how-to-use"
        description="Learn how to wear coloured contact lenses with our comprehensive Contact Lens Guide at OWNDAYS Singapore. Achieve a new look with confidence!"
        title="Contact Lens Guide | How To Wear Coloured Contact Lens | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default HowToUseHTML;
