import React, { useState, useEffect } from 'react';
import {
  NEWS_CATEGORIES,
  PRODUCT_NEWS,
  OWNDAYS_MEETS_NEWS,
  CAMPAING_NEWS,
  NEWS_LIST_FILTER,
} from './getNews';
import './BlogNews.scss';
import 'aos/dist/aos.css';
import NewsItem from './NewsItem';

const NEWS_MAP = {
  '#all': NEWS_LIST_FILTER,
  '#owndays-meets': OWNDAYS_MEETS_NEWS,
  '#products': PRODUCT_NEWS,
  '#campaign': CAMPAING_NEWS,
};

export const BlogNews = () => {
  const [currentCategory, setCurrentCategory] = useState('#all');
  const [listNews, setListNews] = useState(NEWS_LIST_FILTER);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setCurrentCategory(hash);
    }
  }, []);

  useEffect(() => {
    setListNews(NEWS_MAP[currentCategory] || NEWS_LIST_FILTER);
  }, [currentCategory]);

  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    listNews.forEach((_, index) => {
      setTimeout(() => {
        setVisibleItems(prev => [...prev, index]);
      }, index * 500);
    });
  }, [listNews]);

  const handleCategoryClick = (e, category) => {
    e.preventDefault();
    setCurrentCategory(category);
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  };

  return (
    <div className="blog-news">
      <section className="blog-news__categories">
        <ul>
          {NEWS_CATEGORIES.map(item => (
            <li
              key={item.name}
              className={currentCategory === item.hash ? 'selected-new-category' : ''}
              onClick={e => handleCategoryClick(e, item.hash)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </section>

      <section className="blog-news__container">
        <div className="blog-news__block blog-news__block--active">
          <ul className="blog-news__list blog-news__list--open">
            {listNews.map((item, index) => (
              <NewsItem key={item.title} index={index} item={item} visibleItems={visibleItems} />
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};
