const stepLens = [{ name: 'Low' }, { name: 'Amount of UV' }, { name: 'High' }];
const lensMaterial = { title: 'Lens Material', value: 'Plastic' };
const baseUrlStore = 'https://www.owndays.com/stores/sg?storeId=';

export const FREAKS_STORE_MENU = [
  {
    id: 'lineup',
    href: '#lineup',
    icon: 'navs-icon-1.webp',
    iconHover: 'navs-icon-1-hover.webp',
    title: 'LINEUP',
    desc: '商品一覧',
    iconSize: { width: 30, height: 10 },
  },
  {
    id: 'goods',
    href: '#goods',
    icon: 'navs-icon-2.webp',
    iconHover: 'navs-icon-2-hover.webp',
    title: 'EXCLUSIVE MERCH',
    desc: 'オリジナルグッズ',
    iconSize: { width: 22, height: 27 },
  },
];

// FK2001B_4S
export const MAIN_LINE_UP_FK2001B_4S = {
  slides: [
    {
      sp: '/lineup_main_slide01_sp.webp',
      pc: '/lineup_main_slide01_pc.webp',
    },
    {
      sp: null,
      pc: '/lineup_main_slide02_pc.webp',
    },
  ],
  title: {
    small: 'Lens colour changes under UV light',
    main: {
      highlight: `2WAY `,
      text: 'Transition Sunglasses',
    },
  },
  subTitle: 'FK2001B-4S',
  message: `Using photochromic lenses, also commonly known as transition lenses, these sunglasses offer versatility for both indoor and outdoor use. The tinted lenses turn clear when indoors or during nighttime.`,
};

export const POINTS_LINEUP_FK2001B_4S = [
  {
    key: 'C4',
    mainList: [
      {
        src: '/glass-lineup-bullet-13-01.webp',
      },
      {
        src: '/glass-lineup-bullet-13-02.webp',
      },
      {
        src: '/glass-lineup-bullet-13-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point01-01.webp',
        desc: 'Native American motifs on the temple wire cores',
      },
      {
        title: 'POINT 02',
        src: '/point01-02.webp',
        desc: 'Diamond-shaped metal piece to evoke a timeless appeal',
      },
      {
        title: 'POINT 03',
        src: '/point01-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: {
      src: '/glass-snap-color01-04.webp',
      tag: 'LENS COLOUR CHANGE',
      color: {
        src: '/circle-darkbrown.webp',
        name: 'DARK BROWN',
      },
    },
  },
  {
    key: 'C5',
    mainList: [
      {
        src: '/glass-lineup-bullet-14-01.webp',
      },
      {
        src: '/glass-lineup-bullet-14-02.webp',
      },
      {
        src: '/glass-lineup-bullet-14-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point14-01.webp',
        desc: 'Native American motifs on the temple wire cores',
      },
      {
        title: 'POINT 02',
        src: '/point14-02.webp',
        desc: 'Diamond-shaped metal piece to evoke a timeless appeal',
      },
      {
        title: 'POINT 03',
        src: '/point14-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: {
      src: '/glass-snap-color01-05.webp',
      tag: 'LENS COLOUR CHANGE',
      color: {
        src: '/circle-brown.webp',
        name: 'BROWN',
      },
    },
  },
  {
    key: 'C6',
    mainList: [
      {
        src: '/glass-lineup-bullet-15-01.webp',
      },
      {
        src: '/glass-lineup-bullet-15-02.webp',
      },
      {
        src: '/glass-lineup-bullet-15-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point15-01.webp',
        desc: 'Native American motifs on the temple wire cores',
      },
      {
        title: 'POINT 02',
        src: '/point15-02.webp',
        desc: 'Diamond-shaped metal piece to evoke a timeless appeal',
      },
      {
        title: 'POINT 03',
        src: '/point15-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: {
      src: '/glass-snap-color01-06.webp',
      tag: 'LENS COLOUR CHANGE',
      color: {
        src: '/circle-grey.webp',
        name: 'DARK GRAY',
      },
    },
  },
];

export const PRODUCTS_FK2001B_4S = [
  {
    src: '/glass-color01-04.webp',
    key: 'C4',
    color: 'BLACK',
    price: 'S$98',
  },
  {
    src: '/glass-color01-05.webp',
    key: 'C5',
    color: 'LIGHT BROWN',
    price: 'S$98',
  },
  {
    src: '/glass-color01-06.webp',
    key: 'C6',
    color: 'CLEAR',
    price: 'S$98',
  },
];

export const LENS_COLOUR_FK2001B_4S = [
  {
    key: 'C4',
    stepImages: [{ src: '/lens-snap-C4-01.webp' }, { src: '/lens-snap-C4-02.webp' }],
    stepLens,
    descList: [{ title: 'Visible Light Transmittance', value: '56~18%' }, lensMaterial],
  },
  {
    key: 'C5',
    stepImages: [{ src: '/lens-snap-C5-01.webp' }, { src: '/lens-snap-C5-02.webp' }],
    stepLens,
    descList: [{ title: 'Visible Light Transmittance', value: '67~33%' }, lensMaterial],
  },
  {
    key: 'C6',
    stepImages: [{ src: '/lens-snap-C6-01.webp' }, { src: '/lens-snap-C6-02.webp' }],
    stepLens,
    descList: [{ title: 'Visible Light Transmittance', value: '62~20%' }, lensMaterial],
  },
];

// FK2002N-4S
export const MAIN_LINE_UP_FK2002N_4S = {
  slides: [
    {
      sp: '/lineup_main_slide02-01_sp.webp',
      pc: '/lineup_main_slide02-01_pc.webp',
    },
    {
      sp: null,
      pc: '/lineup_main_slide02-02_pc.webp',
    },
  ],
  title: {
    small: 'From spectacles to sunglasses in an instant',
    main: {
      prefix: 'Versatile',
      highlight: ` 2-in-1 `,
      text: 'Spectacles',
    },
  },
  subTitle: 'FK2002N-4S',
  message: `The perfect choice for road trips and the outdoors! Made of resin, a material that is durable, lightweight and flexible, this 2-in-1 eyewear is designed to deliver optimal fit and comfort.`,
};

export const POINTS_LINEUP_FK2002N_4S = [
  {
    key: 'C1',
    mainList: [
      {
        src: '/glass-lineup-bullet-04-01.webp',
      },
      {
        src: '/glass-lineup-bullet-04-02.webp',
      },
      {
        src: '/glass-lineup-bullet-04-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point04-01.webp',
        desc: 'Straight temples make it easier to put on and remove the spectacles while the rubber inner lining enhances fit and comfort',
      },
      {
        title: 'POINT 02',
        src: '/point04-02.webp',
        desc: 'Rubber nose pads for better fit and improved comfort',
      },
      {
        title: 'POINT 03',
        src: '/point04-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap and to provide extra breathability',
      },
    ],
    after: null,
  },
  {
    key: 'C2',
    mainList: [
      {
        src: '/glass-lineup-bullet-05-01.webp',
      },
      {
        src: '/glass-lineup-bullet-05-02.webp',
      },
      {
        src: '/glass-lineup-bullet-05-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point05-01.webp',
        desc: 'Straight temples make it easier to put on and remove the spectacles while the rubber inner lining enhances fit and comfort',
      },
      {
        title: 'POINT 02',
        src: '/point05-02.webp',
        desc: 'Rubber nose pads for better fit and improved comfort',
      },
      {
        title: 'POINT 03',
        src: '/point05-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap and to provide extra breathability',
      },
    ],
    after: null,
  },
  {
    key: 'C3',
    mainList: [
      {
        src: '/glass-lineup-bullet-06-01.webp',
      },
      {
        src: '/glass-lineup-bullet-06-02.webp',
      },
      {
        src: '/glass-lineup-bullet-06-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point06-01.webp',
        desc: 'Straight temples make it easier to put on and remove the spectacles while the rubber inner lining enhances fit and comfort',
      },
      {
        title: 'POINT 02',
        src: '/point06-02.webp',
        desc: 'Rubber nose pads for better fit and improved comfort',
      },
      {
        title: 'POINT 03',
        src: '/point06-03.webp',
        desc: 'Diamond-shaped cut-out at the temple tips for attaching strap and to provide extra breathability',
      },
    ],
    after: null,
  },
];

export const PRODUCTS_FK2002N_4S = [
  {
    src: '/glass-color02-01.webp',
    key: 'C1',
    color: 'MATTE BLACK',
    price: 'S$98',
  },
  {
    src: '/glass-color02-02.webp',
    key: 'C2',
    color: 'BROWN DEMI',
    price: 'S$98',
  },
  {
    src: '/glass-color02-03.webp',
    key: 'C3',
    color: 'CLEAR GRAY',
    price: 'S$98',
  },
];

export const LENS_COLOUR_FK2002N_4S = [
  {
    key: 'C1',
    descList: [{ title: 'Visible Light Transmittance', value: '16.23%' }, lensMaterial],
  },
  {
    key: 'C2',
    descList: [{ title: 'Visible Light Transmittance', value: '15.02%' }, lensMaterial],
  },
  {
    key: 'C3',
    descList: [{ title: 'Visible Light Transmittance', value: '19.93%' }, lensMaterial],
  },
];

// FK1001B-4S
export const MAIN_LINE_UP_FK1001B_4S = {
  slides: [
    {
      sp: '/lineup_main_slide03-01_sp.webp',
      pc: '/lineup_main_slide03-01_pc.webp',
    },
    {
      sp: null,
      pc: '/lineup_main_slide03-02_pc.webp',
    },
  ],
  title: {
    small: 'Airy Crown Panto style',
    main: {
      highlight: 'Combination Frame',
    },
  },
  subTitle: 'FK1001B-4S',
  message: `A combination frame in the gentle Crown Panto style, featuring a plastic brim nestled between the lens and the rim. The introduction of negative space at the front of the frame creates a sense of airiness.`,
};

export const POINTS_LINEUP_FK1001B_4S = [
  {
    key: 'C1',
    mainList: [
      {
        src: '/glass-lineup-bullet-07-02.webp',
      },
      {
        src: '/glass-lineup-bullet-07-01.webp',
      },
      {
        src: '/glass-lineup-bullet-07-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point07-01.webp',
        desc: 'An exquisite design with temples adorning intricate milgrain engravings that are commonly used in jewellery making',
      },
      {
        title: 'POINT 02',
        src: '/point07-02.webp',
        desc: 'Milgrain engravings, a common technique used in jewellery making, featured on the nose bridge',
      },
      {
        title: 'POINT 03',
        src: '/point07-03.webp',
        desc: 'Raindrop-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: null,
  },
  {
    key: 'C2',
    mainList: [
      {
        src: '/glass-lineup-bullet-08-02.webp',
      },
      {
        src: '/glass-lineup-bullet-08-01.webp',
      },
      {
        src: '/glass-lineup-bullet-08-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point08-01.webp',
        desc: 'An exquisite design with temples adorning intricate milgrain engravings that are commonly used in jewellery making',
      },
      {
        title: 'POINT 02',
        src: '/point08-02.webp',
        desc: 'Milgrain engravings, a common technique used in jewellery making, featured on the nose bridge',
      },
      {
        title: 'POINT 03',
        src: '/point08-03.webp',
        desc: 'Raindrop-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: null,
  },
  {
    key: 'C3',
    mainList: [
      {
        src: '/glass-lineup-bullet-09-02.webp',
      },
      {
        src: '/glass-lineup-bullet-09-01.webp',
      },
      {
        src: '/glass-lineup-bullet-09-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point09-01.webp',
        desc: 'An exquisite design with temples adorning intricate milgrain engravings that are commonly used in jewellery making',
      },
      {
        title: 'POINT 02',
        src: '/point09-02.webp',
        desc: 'Milgrain engravings, a common technique used in jewellery making, featured on the nose bridge',
      },
      {
        title: 'POINT 03',
        src: '/point09-03.webp',
        desc: 'Raindrop-shaped cut-out at the temple tips for attaching strap',
      },
    ],
    after: null,
  },
];

export const PRODUCTS_FK1001B_4S = [
  {
    src: '/glass-color04-01.webp',
    key: 'C1',
    color: 'BLACK',
    price: 'S$98',
  },
  {
    src: '/glass-color04-02.webp',
    key: 'C2',
    color: 'LIGHT BROWN',
    price: 'S$98',
  },
  {
    src: '/glass-color04-03.webp',
    key: 'C3',
    color: 'CLEAR BEIGE',
    price: 'S$98',
  },
];

// FK1002B-4S
export const MAIN_LINE_UP_FK1002B_4S = {
  slides: [
    {
      sp: '/lineup_main_slide04-01_sp.webp',
      pc: '/lineup_main_slide04-01_pc.webp',
    },
    {
      sp: null,
      pc: '/lineup_main_slide04-02_pc.webp',
    },
  ],
  title: {
    small: 'Easy-fit Boston style',
    main: {
      highlight: 'Metal Frame',
    },
  },
  subTitle: 'FK1002B-4S',
  message: `This minimalist Boston-style metal frame offers a timeless appeal beyond age constraints. Coming in well-balanced proportions, it is a fitting choice for both men and women alike.`,
};

export const POINTS_LINEUP_FK1002B_4S = [
  {
    key: 'C1',
    mainList: [
      {
        src: '/glass-lineup-bullet-10-02.webp',
      },
      {
        src: '/glass-lineup-bullet-10-01.webp',
      },
      {
        src: '/glass-lineup-bullet-10-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point10-01.webp',
        desc: 'Intriguing engravings in beautiful Arabesque patterns adorn the temples, lending a touch of luxury to the frame',
      },
      {
        title: 'POINT 02',
        src: '/point10-02.webp',
        desc: 'Side surface of the rim features engravings of the majestic Arabesque patterns',
      },
      {
        title: 'POINT 03',
        src: '/point10-03.webp',
        desc: 'Ring-shaped skull temples exude a luxurious vibe and cater to strap attachment',
      },
    ],
    after: null,
  },
  {
    key: 'C2',
    mainList: [
      {
        src: '/glass-lineup-bullet-11-02.webp',
      },
      {
        src: '/glass-lineup-bullet-11-01.webp',
      },
      {
        src: '/glass-lineup-bullet-11-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point11-01.webp',
        desc: 'Intriguing engravings in beautiful Arabesque patterns adorn the temples, lending a touch of luxury to the frame',
      },
      {
        title: 'POINT 02',
        src: '/point11-02.webp',
        desc: 'Side surface of the rim features engravings of the majestic Arabesque patterns',
      },
      {
        title: 'POINT 03',
        src: '/point11-03.webp',
        desc: 'Ring-shaped skull temples exude a luxurious vibe and cater to strap attachment',
      },
    ],
    after: null,
  },
  {
    key: 'C3',
    mainList: [
      {
        src: '/glass-lineup-bullet-12-02.webp',
      },
      {
        src: '/glass-lineup-bullet-12-01.webp',
      },
      {
        src: '/glass-lineup-bullet-12-03.webp',
      },
    ],
    points: [
      {
        title: 'POINT 01',
        src: '/point12-01.webp',
        desc: 'Intriguing engravings in beautiful Arabesque patterns adorn the temples, lending a touch of luxury to the frame',
      },
      {
        title: 'POINT 02',
        src: '/point12-02.webp',
        desc: 'Side surface of the rim features engravings of the majestic Arabesque patterns',
      },
      {
        title: 'POINT 03',
        src: '/point12-03.webp',
        desc: 'Ring-shaped skull temples exude a luxurious vibe and cater to strap attachment',
      },
    ],
    after: null,
  },
];

export const PRODUCTS_FK1002B_4S = [
  {
    src: '/glass-color03-01.webp',
    key: 'C1',
    color: 'MATTE BLACK',
    price: 'S$98',
  },
  {
    src: '/glass-color03-02.webp',
    key: 'C2',
    color: 'GOLD',
    price: 'S$98',
  },
  {
    src: '/glass-color03-03.webp',
    key: 'C3',
    color: 'SILVER',
    price: 'S$98',
  },
];

// GOODS
export const POINTS_GOODS = [
  {
    title: 'Pouch',
    src: '/original-goods-item-1.webp',
  },
  {
    title: 'Spectacle Cloth',
    src: '/original-goods-item-2.webp',
  },
  {
    title: 'Strap Card',
    src: '/original-goods-item-3.webp',
  },
];

export const DESIGN_GOODS = [
  {
    src: '/original-goods-slide-1.webp',
    tag: 'Vertical',
  },
  {
    src: '/original-goods-slide-2.webp',
    tag: 'Horizontal',
  },
  {
    src: '/original-goods-slide-3.webp',
    tag: 'Spectacle Chain',
  },
  {
    src: '/original-goods-slide-4.webp',
    tag: 'Handphone Strap',
    modal: {
      title: 'Instruction Manual',
      subTitle: 'Handphone Strap',
      steps: [
        {
          src: '/original-goods-step-1.webp',
          desc: 'Place strap card in the phone cover with the eyelet threaded through the slot for charging port',
        },
        {
          src: '/original-goods-step-2.webp',
          desc: 'Insert handphone into the phone cover',
        },
        {
          src: '/original-goods-step-3.webp',
          desc: 'Attach strap to the eyelet',
        },
      ],
    },
  },
];

export const COLLAPSE_GOODS = [
  {
    title: 'Strap Card',
    list: [
      { desc: 'Do not apply excessive force or impact to the product' },
      { desc: 'Handle handphone with care when attaching or detaching the strap card to it' },
      {
        desc: 'Do not swing the strap as doing so may cause the phone to detach, leading to injuries or damage',
      },
      { desc: 'Do not use if product is damaged or deformed' },
      { desc: 'Keep away from heat and flame and back up data before use' },
      { desc: 'Use only with phone cover that has a charging port opening' },
      {
        desc: 'Not recommended for use on devices aside from handphones (i.e. not to be used on tablets, etc)',
      },
      {
        desc: 'The company does not undertake any responsibility for any data loss and/or damage to the handphone incurred in relation to the use of the product',
      },
    ],
  },
  {
    title: 'Multiway Pouch',
    list: [
      { desc: 'Do not place extremely heavy items or stuff the product beyond its capacity' },
      { desc: 'Placing sharp objects directly into the product may cause damage to the fabric' },
      { desc: 'Moisture, sweat and rubbing may cause discolouration and colour transfer' },
      {
        desc: 'Keep away from flame, heat, direct sunlight and high humidity to avoid deformation and deterioration',
      },
      {
        desc: 'Wipe using a soft cloth when necessary; do not use volatile solvents such as benzine or thinner to clean the product',
      },
      { desc: 'Do not machine wash, tumble dry or iron' },
      { desc: 'Be gentle and avoid swinging the product' },
      {
        desc: 'Discontinue use immediately in case of fabric fraying, tearing, deformation and other damages to the product',
      },
      {
        desc: 'The company does not undertake any responsibility for any damage and/or loss incurred in relation to the use of the product',
      },
    ],
  },
  {
    title: 'Strap',
    list: [
      { desc: 'Ensure metal fittings are securely closed before use<' },
      {
        desc: 'Pulling with excessive force or overloading may cause the metal fittings to stretch and break',
      },
      {
        desc: 'When using the product suspended on the body, exercise caution to avoid getting it caught in machinery',
      },
      {
        desc: 'Discontinue use immediate if you experience any discomfort or allergic reaction on your skin',
      },
      { desc: 'Keep out of reach of children and pet' },
      {
        desc: 'The company does not undertake any responsibility for any damage and/or loss incurred in relation to the use of the product',
      },
    ],
  },
];

// OTHER
export const STORES = [
  {
    link: `${baseUrlStore}OD909`,
    name: 'OWNDAYS nex',
  },
  {
    link: `${baseUrlStore}OD920`,
    name: 'OWNDAYS ION Orchard',
  },
  {
    link: `${baseUrlStore}OD922`,
    name: 'OWNDAYS IMM Outlet',
  },
  {
    link: `${baseUrlStore}OD928`,
    name: 'OWNDAYS Jurong Point',
  },
  {
    link: `${baseUrlStore}OD931`,
    name: 'OWNDAYS Vivo City',
  },
  {
    link: `${baseUrlStore}OD937`,
    name: 'OWNDAYS Tiong Bahru Plaza',
  },
  {
    link: `${baseUrlStore}OD948`,
    name: 'OWNDAYS Compass One',
  },
  {
    link: `${baseUrlStore}OD968`,
    name: 'OWNDAYS Northpoint City',
  },
  {
    link: `${baseUrlStore}OD983`,
    name: 'OWNDAYS Century Square',
  },
];

export const SHARE = [
  {
    link: 'https://www.facebook.com/sharer/sharer.php?u=https://owndays.com/sg/en/news/freaks-store',
    className: 'fab fa-facebook',
  },
  {
    link: 'https://twitter.com/intent/tweet?url=https://owndays.com/sg/en/news/freaks-store',
    className: 'fab fa-twitter',
  },
  {
    link: 'https://line.me/R/msg/text/?https://owndays.com/sg/en/news/freaks-store',
    className: 'fab fa-line',
  },
];
