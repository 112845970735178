export const STORE_DATA = [
  { name: 'OWNDAYS Plaza Singapura', url: 'https://www.owndays.com/sg/en/shops/905' },
  { name: 'OWNDAYS 313@somerset', url: 'https://www.owndays.com/sg/en/shops/907' },
  { name: 'OWNDAYS Bedok Mall', url: 'https://www.owndays.com/sg/en/shops/908' },
  { name: 'OWNDAYS nex', url: 'https://www.owndays.com/sg/en/shops/909' },
  { name: 'OWNDAYS Raffles City', url: 'https://www.owndays.com/sg/en/shops/1004' },
  { name: 'OWNDAYS Bugis+', url: 'https://www.owndays.com/sg/en/shops/915' },
  { name: 'OWNDAYS IMM Outlet', url: 'https://www.owndays.com/sg/en/shops/922' },
  { name: 'OWNDAYS Suntec City', url: 'https://www.owndays.com/sg/en/shops/924' },
  { name: 'OWNDAYS JCube', url: 'https://www.owndays.com/sg/en/shops/926' },
  { name: 'OWNDAYS White Sands', url: 'https://www.owndays.com/sg/en/shops/927' },
  { name: 'OWNDAYS Tiong Bahru Plaza', url: 'https://www.owndays.com/sg/en/shops/937' },
  { name: 'OWNDAYS Compass One', url: 'https://www.owndays.com/sg/en/shops/948' },
  { name: 'OWNDAYS City Square Mall', url: 'https://www.owndays.com/sg/en/shops/961' },
  { name: 'OWNDAYS Westgate', url: 'https://www.owndays.com/sg/en/shops/962' },
  { name: 'OWNDAYS 100AM', url: 'https://www.owndays.com/sg/en/shops/963' },
  { name: 'OWNDAYS Clementi Mall', url: 'https://www.owndays.com/sg/en/shops/965' },
  { name: 'OWNDAYS Northpoint City', url: 'https://www.owndays.com/sg/en/shops/968' },
  { name: 'OWNDAYS SingPost Centre', url: 'https://www.owndays.com/sg/en/shops/972' },
  { name: 'OWNDAYS Seletar Mall', url: 'https://www.owndays.com/sg/en/shops/974' },
  { name: 'OWNDAYS AMK Hub', url: 'https://www.owndays.com/sg/en/shops/976' },
  { name: 'OWNDAYS Bukit Panjang Plaza', url: 'https://www.owndays.com/sg/en/shops/977' },
  { name: 'OWNDAYS Century Square', url: 'https://www.owndays.com/sg/en/shops/983' },
  { name: 'OWNDAYS PLQ Mall', url: 'https://www.owndays.com/sg/en/shops/994' },
  { name: 'OWNDAYS Causeway Point', url: 'https://www.owndays.com/sg/en/shops/1005' },
  { name: 'OWNDAYS Parkway Parade', url: 'https://www.owndays.com/sg/en/shops/1006' },
  { name: 'OWNDAYS BUGIS JUNCTION', url: 'https://www.owndays.com/sg/en/shops/1011' },
];

export const SPECIAL_STORES = {
  ION_JEWEL: [
    { name: 'OWNDAYS ION Orchard', url: 'https://www.owndays.com/sg/en/shops/920' },
    {
      name: 'OWNDAYS Takashimaya S.C Premium Concept Store',
      url: 'https://www.owndays.com/sg/en/shops/1020',
    },
  ],
  OPEN_STORES: [
    { name: 'OWNDAYS Jurong Point', url: 'https://www.owndays.com/sg/en/shops/928' },
    { name: 'OWNDAYS Vivo City', url: 'https://www.owndays.com/sg/en/shops/931' },
    { name: 'OWNDAYS Waterway Point', url: 'https://www.owndays.com/sg/en/shops/933' },
  ],
  MBS: [
    { name: 'OWNDAYS MBS Premium Concept Store', url: 'https://www.owndays.com/sg/en/shops/1007' },
  ],
  JEWEL: [{ name: 'OWNDAYS Jewel Changi', url: 'https://www.owndays.com/sg/en/shops/989' }],
};

export const generateShopsData = (defaultHours, specialHours) => {
  return [
    ...STORE_DATA.map(store => ({ ...store, ...defaultHours })),
    ...SPECIAL_STORES.ION_JEWEL.map(store => ({ ...store, ...specialHours.ION_JEWEL_PATTERN })),
    ...SPECIAL_STORES.OPEN_STORES.map(store => ({ ...store, ...specialHours.OPEN_PATTERN })),
    ...SPECIAL_STORES.MBS.map(store => ({ ...store, ...specialHours.MBS_PATTERN })),
    ...SPECIAL_STORES.JEWEL.map(store => ({ ...store, ...specialHours.JEWEL_PATTERN })),
  ];
};

export const generateOperatingDates = dates => ({
  lunarEve: {
    date: dates.lunarEve,
    label: 'Eve of Lunar New Year',
  },
  day1: {
    date: dates.day1,
    label: 'Lunar New Year Day 1',
  },
  day2: {
    date: dates.day2,
    label: 'Lunar New Year Day 2',
  },
  day3: {
    date: dates.day3,
    label: 'Lunar New Year Day 3',
  },
});
