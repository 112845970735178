import React from 'react';

export const ContactInfo = () => {
  return (
    <dialog className="l-header__modal l-header__modal--hidden" id="contact">
      <p className="l-header__modal-title">Contact Us</p>
      <div className="contact-information">
        <a href="tel:6222-0588" id="header_modal_contact">
          <span className="icon-tel"></span>6222-0588
        </a>
      </div>
      <p>
        <span>Operating Hours</span> <span>10:00 - 19:00</span>
      </p>
      <div className="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
      </div>
      <p className="c-attention"></p>
    </dialog>
  );
};
