import React from 'react';
import { Lunar2021NewYearOperatingHours } from '../CommonComponents/Lunar2021NewYearOperatingHours';

const Lunar2021NewYearOperatingHoursHTML = () => {
  return (
    <>
      <Lunar2021NewYearOperatingHours />
    </>
  );
};

export default Lunar2021NewYearOperatingHoursHTML;
