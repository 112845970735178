import React, { Fragment } from 'react';

import { HeaderEyeCamp, BodyEyeCamp, FooterEyeCamp } from '../CommonComponents/EyeCamp';

import '../CommonComponents/EyeCamp/BaseEyeCamp.scss';

const EyeCampHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/eye-camp';

  return (
    <Fragment>
      <HeaderEyeCamp baseImgUrl={baseImgUrl} />
      <BodyEyeCamp baseImgUrl={baseImgUrl} />
      <FooterEyeCamp baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default EyeCampHTML;
