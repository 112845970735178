import React, { Fragment, useState, useEffect } from 'react';
import Slider from 'react-slick';

import { HISTORY } from './constants.jsx';

import './FooterEyeCamp.scss';

export const FooterEyeCamp = ({ baseImgUrl }) => {
  const [selectedHistory, setSelectedHistory] = useState({
    index: null,
    currentRow: 0,
    nextRowFirstItemIndex: 3,
    item: null,
    isOpen: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const slideHistorySetting = {
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    dots: true,
    fade: false,
    draggable: true,
    infinite: true,
    swipe: true,
    touchMove: true,
  };

  const onSelectedHistory = (index, currentRow, nextRowFirstItemIndex, isMoreDetail) => {
    if (!isMoreDetail) {
      return;
    }

    setSelectedHistory(prevSelectedHistory => ({
      index,
      currentRow,
      nextRowFirstItemIndex,
      item: HISTORY[index],
      isOpen: prevSelectedHistory.index === index ? !prevSelectedHistory.isOpen : true,
    }));
  };

  const onUnSelectedHistory = () => {
    setSelectedHistory({
      index: null,
      currentRow: 0,
      nextRowFirstItemIndex: 3,
      item: null,
      isOpen: false,
    });
  };

  const closeIcon = (
    <div className="footer-eye-camp__history-detail__close" onClick={onUnSelectedHistory}>
      <svg viewBox="0 0 20 20" xmlns="https://www.w3.org/2000/svg">
        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
      </svg>
    </div>
  );

  const renderDetail = data => {
    if (!data || !selectedHistory.isOpen) {
      return null;
    }

    const { id, title, detail } = data;
    const detailClass = selectedHistory.isOpen
      ? 'footer-eye-camp__history-detail__card-detail--show'
      : 'footer-eye-camp__history-detail__card-detail--hide';

    return (
      <li className={`footer-eye-camp__history-detail__card-detail ${detailClass}`}>
        {closeIcon}

        <h3 className="footer-eye-camp__history-detail__header">
          <span className="footer-eye-camp__history-detail__header--country">{title.country}</span>
          {title.city}
          <span className="footer-eye-camp__history-detail__header--date">{detail.date}</span>
        </h3>

        <Slider {...slideHistorySetting} className="footer-eye-camp__history-detail__custom-slider">
          {[...Array(10)].map((_, index) => (
            <img
              key={index}
              alt={`Slide ${index + 1}`}
              src={`${baseImgUrl}/history/${id}/slide_${String(index + 1).padStart(2, '0')}.webp`}
            />
          ))}
        </Slider>

        <div className="footer-eye-camp__history-detail__message">{detail.message}</div>

        {detail.opinions && (
          <ul className="footer-eye-camp__history-detail__opinions">
            {detail.opinions.map(opinion => {
              return (
                <li key={opinion.name}>
                  <div className="footer-eye-camp__history-detail__opinions__header">
                    <img alt={opinion.name} src={`${baseImgUrl}/history/${id}/${opinion.icon}`} />

                    <div>
                      <h4>{opinion.name}</h4>
                      <p>
                        <img alt={opinion.name} src={`${baseImgUrl}/flags/${opinion.flag}.webp`} />
                        {opinion.role}
                      </p>
                    </div>
                  </div>

                  <p className="footer-eye-camp__history-detail__opinions__comment">
                    {opinion.desc}
                  </p>
                </li>
              );
            })}
          </ul>
        )}

        {detail.vdo && (
          <div className="footer-eye-camp__history-detail__iframe-container">
            <iframe
              allowFullScreen
              frameBorder={0}
              height="100%"
              src={detail.vdo}
              title={`Eye camp video ${id}`}
              width="100%"
            />
          </div>
        )}
      </li>
    );
  };

  const getGridLayout = () => {
    if (windowWidth < 768) return 2;
    if (windowWidth >= 768 && windowWidth < 1024) return 3;

    return 4;
  };

  const renderHistoryList = () => {
    const gridLayout = getGridLayout();

    const historyItems = HISTORY.map((item, index) => {
      const currentRow = Math.floor(index / gridLayout);
      const nextRowFirstItemIndex = (currentRow + 1) * gridLayout;

      const shouldRenderDetail =
        index === selectedHistory.nextRowFirstItemIndex - 1 ||
        (index === HISTORY.length - 1 && index < selectedHistory.nextRowFirstItemIndex - 1);

      return (
        <React.Fragment key={item.id}>
          <li
            id={item.id}
            onClick={() =>
              onSelectedHistory(index, currentRow, nextRowFirstItemIndex, item.isMoreDetail)
            }
          >
            <a>
              <div
                className={
                  item.isMoreDetail
                    ? `footer-eye-camp__history__card ${
                        selectedHistory.index === index &&
                        selectedHistory.isOpen &&
                        'footer-eye-camp__history__card--clicked'
                      }`
                    : 'footer-eye-camp__history__card-no-more'
                }
              >
                <img
                  alt=""
                  src={`${baseImgUrl}/history/${item.id}/thumb.${
                    item.id > 202110 ? 'webp' : 'jpg'
                  }`}
                />
                <div>
                  <span>{item.title.country}</span>
                  {item.title.date}
                  <p>{item.title.city}</p>
                </div>
                <span className="footer-eye-camp__history__plus"></span>

                <img
                  alt=""
                  className={`${
                    selectedHistory.index === index && selectedHistory.isOpen
                      ? 'footer-eye-camp__history__icon-search footer-eye-camp__history__icon-search--show'
                      : 'footer-eye-camp__history__icon-search'
                  } `}
                  src={`${baseImgUrl}/search.svg`}
                ></img>
              </div>
            </a>
          </li>

          {shouldRenderDetail && renderDetail(selectedHistory.item)}
        </React.Fragment>
      );
    });

    return <ul className="footer-eye-camp__history__list">{historyItems}</ul>;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedHistory) {
      const detailsElement = document.querySelector(
        '.footer-eye-camp__history-detail__card-detail--show'
      );
      if (detailsElement) {
        detailsElement.style.maxHeight = '100%';
        detailsElement.style.transition = 'max-height 0.5s ease-out';
      }
    }
  }, [selectedHistory]);

  return (
    <Fragment>
      <section className="footer-eye-camp__history">
        <div className="footer-eye-camp__history__container">
          <h2 className="footer-eye-camp__history__title">HISTORY</h2>

          {renderHistoryList()}
        </div>
      </section>
    </Fragment>
  );
};
