import React from 'react';
import Redirect from '../CommonComponents/Redirect/Redirect.js';
import PolicyHTML from '../own-days/html/PolicyHTML.jsx';
import PolicyWithEmailHTML from '../own-days/html/PolicyWithEmailHTML.jsx';
import ResultSubmitHTML from '../own-days/html/ResultSubmitHTML.jsx';
import EyeCampHTML from '../own-days/html/EyeCampHTML';
import Articles from '../own-days/views/Articles';
import SG001 from '../own-days/views/ArticleDetails/SG001.jsx';
import SG002 from '../own-days/views/ArticleDetails/SG002.jsx';
import SG003 from '../own-days/views/ArticleDetails/SG003.jsx';
import NewsPage from '../own-days/html/News.jsx';
import BlackFriday2024 from '../own-days/views/news/BlackFriday2024';
import KuromiCollectionHTML from '../own-days/html/KuromiCollectionHTML.jsx';
import ContactCategory from '../own-days/views/contacts/ContactCategory';
import ContactType from '../own-days/views/contacts/ContactType';
import HowToUse from '../own-days/views/contacts/HowToUse';
import FreaksStoreHTML from '../own-days/html/FreaksStoreHTML.jsx';
import AirHTML from '../own-days/html/AirHTML.jsx';
import InformationHTML from '../own-days/html/InformationHTML.jsx';
import ToaPayohStore from '../own-days/html/ToaPayohStore.jsx';
import Shops1048 from '../own-days/views/information/Shops1048.jsx';

const DeactivateBlackFriday2024 = () => {
  const currentDate = new Date();
  const deactivateDate = new Date('2024-12-02T16:00:00Z'); // SG 2024-12-03T00:00:00

  if (currentDate >= deactivateDate) {
    return <Redirect to="/news" />;
  }
  return <BlackFriday2024 />;
};

const commonRoutes = [
  // PDPA Routes
  { path: '/(sg/en)?/pdpa/completed', component: ResultSubmitHTML },
  { path: '/(sg/en)?/pdpa', component: PolicyHTML },
  { path: '/(sg/en)?/pdpa2', component: PolicyWithEmailHTML },

  // Articles Routes
  { path: '/(sg/en)?/articles/sg-001', component: SG001 },
  { path: '/(sg/en)?/articles/sg-002', component: SG002 },
  { path: '/(sg/en)?/articles/sg-003', component: SG003 },
  { path: '/(sg/en)?/articles', exact: true, component: Articles },

  // News Routes
  { path: '/(sg/en)?/news/black-friday-2024', component: DeactivateBlackFriday2024 },
  { path: '/(sg/en)?/news/kuromi', component: KuromiCollectionHTML },
  {
    path: '/(sg/en)?/news/freaks-store',
    component: FreaksStoreHTML,
  },
  { path: '/(sg/en)?/news/air', component: AirHTML },
  { path: '/(sg/en)?/news', exact: true, component: NewsPage },

  // Company Routes
  { path: '/(sg/en)?/company/eyecamp', component: EyeCampHTML },

  // Contacts Routes
  { path: '/(sg/en)?/contacts/:category', exact: true, component: ContactCategory },
  { path: '/(sg/en)?/contacts/:category/how-to-use', exact: true, component: HowToUse },
  { path: '/(sg/en)?/contacts/:category/:type', component: ContactType },

  // Information Routes
  { path: '/(sg/en)?/information', exact: true, component: InformationHTML },
  { path: '/(sg/en)?/information/shops/1043', component: ToaPayohStore },
  { path: '/(sg/en)?/information/shops/1048', component: Shops1048 },

  // Redirect Routes
  { path: '/home-try-on-program-*', component: () => <Redirect to="/bookeyetest" /> },
  {
    path: '/(home-eye-check-up.html|home-eye-check-up-for-rs-5-plus-get-a-rs-1200-voucher.html|home-try-on-program.html|eyetest)',
    component: () => <Redirect to="/bookeyetest" />,
  },
  { path: '/(sg/en)?/news/clear-sunglasses', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/news/cinnamoroll', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/news/enhanced-lens-offer', component: () => <Redirect to="/" /> },
  { path: '/(sg/en)?/news/airultem', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/eco2xy', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/memorymetal', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/owndays-plus', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/owndays-snap', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/sunglasses', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/eyeglasses/brands/snap.html', component: () => <Redirect to="/brands" /> },
  {
    path: '/(sg/en)?/eyeglasses/brands/Owndaysplus.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/AIRUltem.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/MemoryMetal.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/AIRFIT.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/eyeglasses/brands/eco2xy.html',
    component: () => <Redirect to="/brands" />,
  },
  {
    path: '/(sg/en)?/news/huawei',
    component: () => <Redirect to="/news/huawei2" />,
  },
  {
    path: '/(sg/en)?/news/owndays-plus2023',
    component: () => <Redirect to="/news/owndays-plus" />,
  },
  {
    path: '/(sg/en)?/news/christmas-happenings',
    component: () => <Redirect to="/news" />,
  },
  {
    path: '/(sg/en)?/news/pokemon',
    component: () => <Redirect to="/brands" />,
  },
  { path: '/news/free-myopia-control-lens', component: () => <Redirect to="/news" /> },
  {
    path: '/(sg/en)?/contacts/clear-contactlens/daily-advance',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/contacts/clear-contactlens/monthly-advance',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg',
    component: () => <Redirect to="/contacts/clear-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/daily',
    component: () => <Redirect to="/contacts/clear-contactlens/daily" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/monthly',
    component: () => <Redirect to="/contacts/clear-contactlens/monthly" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/daily-advance',
    component: () => <Redirect to="/contacts/clear-contactlens/daily-advance" />,
  },
  {
    path: '/(sg/en)?/news/owndays-contactlens-sg/monthly-advance',
    component: () => <Redirect to="/contacts/clear-contactlens/monthly-advance" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens',
    component: () => <Redirect to="/contacts/color-contactlens" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/natural',
    component: () => <Redirect to="/contacts/color-contactlens/natural" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/horoscope',
    component: () => <Redirect to="/contacts/color-contactlens/horoscope" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/petal',
    component: () => <Redirect to="/contacts/color-contactlens/petal" />,
  },
  {
    path: '/(sg/en)?/news/color-contactlens/velvet',
    component: () => <Redirect to="/contacts/color-contactlens/velvet" />,
  },
  {
    path: '/(sg/en)?/news/ortho-k-lens',
    component: () => <Redirect to="/contacts/orthok-contactlens" />,
  },
  { path: '/(sg/en)?/news/vivocity-event', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/eyeglasses/brands.html', component: () => <Redirect to="/brands" /> },
  { path: '/(sg/en)?/news/ortho-k-webinar', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/campaign/black-friday.html', component: () => <Redirect to="/news" /> },
  { path: '/(sg/en)?/sg-gold-rush', component: () => <Redirect to="/news" /> },
];

export default commonRoutes;
