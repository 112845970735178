import React from 'react';
import PropTypes from 'prop-types';

export const LunarNewYearTable = ({ shopsData, operatingDates }) => {
  return (
    <div className="scroll">
      <table className="sheet">
        <thead>
          <tr>
            <th>Store</th>
            {Object.values(operatingDates).map(({ date, label }) => (
              <th key={date}>
                {date}
                <br />
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shopsData.map(shop => (
            <tr key={shop.name}>
              <td className="shop-name">
                <a href={shop.url}>{shop.name}</a>
              </td>
              <td>{shop.lunarEve}</td>
              <td className={shop.day1 === 'Closed' ? 'h_bk' : ''}>{shop.day1}</td>
              <td className={shop.day2 === 'Closed' ? 'h_bk' : ''}>{shop.day2}</td>
              <td>{shop.day3}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

LunarNewYearTable.propTypes = {
  shopsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      lunarEve: PropTypes.string.isRequired,
      day1: PropTypes.string.isRequired,
      day2: PropTypes.string.isRequired,
      day3: PropTypes.string.isRequired,
    })
  ).isRequired,
  operatingDates: PropTypes.object.isRequired,
};
