import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="Collaboration Frames [GUNDAM x OWNDAYS] | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="A crossover between OWNDAYS SNAP, one of the best-selling collections in OWNDAYS, and the popular television anime series Mobile Suit Gundam." />
<meta name="keywords" content="Collaboration Frames,GUNDAM,40th Anniversary,Collaboration,spectacles,glasses,eye glasses,sunglasses,OWNDAYS,SNAP" />
<meta name="og:description" content="A crossover between OWNDAYS SNAP, one of the best-selling collections in OWNDAYS, and the popular television anime series Mobile Suit Gundam." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta property="og:title" content="Collaboration Frames [GUNDAM x OWNDAYS]" />
<meta property="og:description" content="A crossover between OWNDAYS SNAP, one of the best-selling collections in OWNDAYS, and the popular television anime series Mobile Suit Gundam." />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/collabo/gundam/pj-snap">
                <meta property="og:image" content="https://www.owndays.com/images/collabo/gundam/pj-snap/ogp_1200x630.jpg?1666001674">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
    <link href="https://www.owndays.com/web/css/collabo-gundam-form-contest.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/collabo-gundam-snap.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">Collaboration Frames [GUNDAM x OWNDAYS] | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
  

<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Collaboration Frames                    </li>
        
            </ul>
</div>


<!-- contents_area -->
<section class="l-gundam l-gundam-snap">
    
    
    
    <section class="l-gundam__main">
        <picture>
            <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/gundam/scroll.svg">
            <img class="lazyload scroll" data-src="https://www.owndays.com/images/specials/products/gundam/scroll-sp.svg" alt="">
        </picture>
        <div class="l-gundam__main-inner">
            <h1>
                <picture>
                    <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/gundam/logo.svg">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/gundam/logo-sp.svg" alt="GUNDAM × OWNDAYS Wear the GUNDAM">
                </picture>
            </h1>
            <div class="l-gundam__main-title">
                <p>Mobile Suit Gundam 40th Anniversary Collaboration Frames<br><small>©OWNDAYS co., ltd. ©SOTSU・SUNRISE</small></p>
                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/gundam/icon-40th.svg" alt="GUNDAM 40th">
                <div class="l-gundam__time">
                    <p class="u-mr__0"></p>
                </div>
                                <a href="#available-shop">Available Outlet</a>
                <p class="l-gundam__main-title--sg">SOLD OUT IN-STORE AND ONLINE</p>
                            </div>
        </div>
    </section>
    <section class="l-gundam__character">
        <ul class="l-gundam__character-list">
            <li>
                <a href="#parallax-01">
                    <div>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1.webp" alt="GMD1001T-9A C1">
                        <p>GUNDAM<small>Model</small></p>
                    </div>
                </a>
            </li>
            <li>
                <a href="#parallax-02">
                    <div>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2.webp" alt="GMD1001T-9A C2">
                        <p><small>CHAR’S</small>ZAKU II<small>Model</small></p>
                    </div>
                </a>
            </li>
            <li>
                <a href="#parallax-03">
                    <div>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3.webp" alt="GMD1001T-9A C3">
                        <p>ZAKU II<small>Model</small></p>
                    </div>
                </a>
            </li>
            <li>
                <a href="#parallax-04">
                    <div>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4.webp" alt="GMD1001T-9A C4">
                        <p>DOM<small>Model</small></p>
                    </div>
                </a>
            </li>
        </ul>
    </section>
    <section class="l-gundam__concept">
        <div class="l-gundam__concept-inner">
            <h2>CONCEPT<small>CONCEPT</small></h2>
            <p>A crossover between OWNDAYS SNAP, one of the best-selling collections in OWNDAYS, and the popular television anime series Mobile Suit Gundam.<br><br>Featuring frames inspired by the motifs of 4 mobile suits, namely Gundam from the Earth Federation Forces as well as Char’s Zaku II, Zaku II and Dom from the Principality of Zeon. Made of lightweight stainless steel, the frames are treated with a matte coating to give them an added premium touch.</p>
            <div class="l-gundam__concept-snap">
                <div>
                    <h3>【What is OWNDAYS SNAP?】</h3>
                    <p>OWNDAYS SNAP is a collection of frames that can be converted into sunglasses using a coloured snap-on lens called SNAP LENS. There are built-in magnets that allow the SNAP LENS to be attached onto an OWNDAYS SNAP frame effortlessly.</p>
                    <p class="u-text-comment">* Each frame comes with 1 SNAP LENS.</p>
                </div>
                <ul>
                    <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/snap2.webp" alt=""></li>
                    <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/snap1.webp" alt=""></li>
                    <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/snap3.webp" alt=""></li>
                </ul>
            </div>
        </div>
    </section>
    <div class="l-gundam__name l-gundam__name--right">
        <h2>GUNDAM</h2>
        <p>Model</p>
    </div>
    <section class="fix-gundam l-gundam__parallax" id="parallax-01" data-enllax-ratio="-0.1">
        <img class="lazyload gradation" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/gradation.webp" alt="">
    </section>
    <section class="l-gundam__gundam l-gundam__content">
        <div class="l-gundam__content-inner">
            <img class="lazyload frame" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1.webp" alt="GMD1001T-9A C1">
            <h3><small>OWNDAYS SNAP</small>GUNDAM Model</h3>
            <p>Replicating Gundam with its 3 iconic colours – metallic silver for the front, white for the temples and blue for the temple tips. A metal emblem of the Earth Federation Forces is set on the underside of the temple tip while the model number, RX-78-2, is laser-engraved onto the reverse side of the temple.</p>
            <div class="l-gundam__products">
                <div class="l-gundam__products--left">
                    <div class="l-gundam__products-view gundam-view">
                        <div class="is-tab-show">
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_01.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_02.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_03.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_04.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_05.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_06.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_07.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_08.webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="l-gundam__products--right">
                    <ul class="l-gundam__products-thumbnail gundam-thumbnail">
                        <li class="is-active"><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_01.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_02.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_03.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_04.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_05.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_06.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_07.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C1_thumb_08.webp" alt=""></li>
                    </ul>
                    <p class="u-text-comment">* Grey polarised SNAP LENS included</p>
                    <div class="l-gundam__products-set case">
                        <p><strong>GUNDAM Model</strong><small>Exclusive Spectacle Case</small></p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/case-blue.webp" alt="">
                    </div>
                    <div class="l-gundam__products-set cloth">
                        <p>Cleaning Cloth</p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/cloth-gundam.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="l-gundam__information">
            <div class="l-gundam__information-inner">
                <div>
                    <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/logo-gundam.svg" alt="">
                    <div>
                        <h4><small>OWNDAYS SNAP</small>GUNDAM Model</h4>
                        <p>GDM1001T-9A COL1</p>
                        <p class="c-price c-price__default">S$ 198</p>
                        <p class="u-text-comment">* Not valid for any discount, promotion and/or privilege</p>
                    </div>
                </div>
                <ul>
                    <li>
                                            </li>
                    
                    
                    
                    
                    
                    
                    
                    
                                    </ul>
            </div>
        </div>
    </section>
    <div class="l-gundam__name char-zaku">
        <h2>CHAR’S<br>ZAKU II</h2>
        <p>Model</p>
    </div>
    <section class="fix-char-zaku l-gundam__parallax" id="parallax-02" data-enllax-ratio="-0.1">
        <img class="lazyload gradation" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/gradation.webp" alt="">
    </section>
    <section class="l-gundam__char-zaku l-gundam__content">
        <div class="l-gundam__content-inner">
            <img class="lazyload frame" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2.webp" alt="GMD1001T-9A C2">
            <h3><small>OWNDAYS SNAP</small>CHAR’S ZAKU II Model</h3>
            <p>Replicating Char’s Zaku II with the front and temple tips in matte black and red temples to denote the machine customised for Char. A metal emblem of Char’s Zaku II is set on the underside of the temple tip while the model number, MS-06S, is laser-engraved onto the reverse side of the temple.</p>
            <div class="l-gundam__products">
                <div class="l-gundam__products--left">
                    <div class="l-gundam__products-view char-zaku-view">
                        <div class="is-tab-show">
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_01.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_02.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_03.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_04.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_05.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_06.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_07.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_08.webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="l-gundam__products--right">
                    <ul class="l-gundam__products-thumbnail char-zaku-thumbnail">
                        <li class="is-active"><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_01.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_02.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_03.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_04.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_05.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_06.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_07.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C2_thumb_08.webp" alt=""></li>
                    </ul>
                    <p class="u-text-comment">* Grey polarised SNAP LENS included</p>
                    <div class="l-gundam__products-set case">
                        <p><strong>CHAR’S ZAKU II Model</strong><small>Exclusive Spectacle Case</small></p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/case-green.webp" alt="">
                    </div>
                    <div class="l-gundam__products-set cloth">
                        <p>Cleaning Cloth</p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/cloth-char-zaku.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="l-gundam__information">
            <div class="l-gundam__information-inner">
                <div>
                    <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/logo-char-zaku.svg" alt="">
                    <div>
                        <h4><small>OWNDAYS SNAP</small>CHAR’S ZAKU II Model</h4>
                        <p>GDM1001T-9A COL2</p>
                        <p class="c-price c-price__default">S$ 198</p>
                        <p class="u-text-comment">* Not valid for any discount, promotion and/or privilege</p>
                    </div>
                </div>
                <ul>
                    <li>
                                            </li>
                    
                    
                    
                    
                    
                    
                    
                    
                                    </ul>
            </div>
        </div>
    </section>
    <div class="l-gundam__name l-gundam__name--right">
        <h2>ZAKU II</h2>
        <p>Model</p>
    </div>
    <section class="fix-zaku l-gundam__parallax" id="parallax-03" data-enllax-ratio="-0.1">
        <img class="lazyload gradation" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/gradation.webp" alt="">
    </section>
    <section class="l-gundam__zaku l-gundam__content">
        <div class="l-gundam__content-inner">
            <img class="lazyload frame" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3.webp" alt="GMD1001T-9A C3">
            <h3><small>OWNDAYS SNAP</small>ZAKU II Model</h3>
            <p>Replicating Zaku II with the front and temple tips in matte moss green and temples in a vivid shade of green. A metal emblem of Principality of Zeon Forces is set on the underside of the temple tip while the model number, MS-06, is laser-engraved onto the reverse side of the temple.</p>
            <div class="l-gundam__products">
                <div class="l-gundam__products--left">
                    <div class="l-gundam__products-view zaku-view">
                        <div class="is-tab-show">
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_01.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_02.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_03.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_04.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_05.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_06.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_07.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_08.webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="l-gundam__products--right">
                    <ul class="l-gundam__products-thumbnail zaku-thumbnail">
                        <li class="is-active"><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_01.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_02.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_03.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_04.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_05.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_06.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_07.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C3_thumb_08.webp" alt=""></li>
                    </ul>
                    <p class="u-text-comment">* Grey polarised SNAP LENS included</p>
                    <div class="l-gundam__products-set case">
                        <p><strong>ZAKU II Model</strong><small>Exclusive Spectacle Case</small></p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/case-green.webp" alt="">
                    </div>
                    <div class="l-gundam__products-set cloth">
                        <p>Cleaning Cloth</p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/cloth-zaku.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="l-gundam__information">
            <div class="l-gundam__information-inner">
                <div>
                    <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/logo-zaku.svg" alt="">
                    <div>
                        <h4><small>OWNDAYS SNAP</small>ZAKU II Model</h4>
                        <p>GDM1001T-9A COL3</p>
                        <p class="c-price c-price__default">S$ 198</p>
                        <p class="u-text-comment">* Not valid for any discount, promotion and/or privilege</p>
                    </div>
                </div>
                <ul>
                    <li>
                                            </li>
                    
                    
                    
                    
                    
                    
                    
                    
                                    </ul>
            </div>
        </div>
    </section>
    <div class="l-gundam__name">
        <h2>DOM</h2>
        <p>Model</p>
    </div>
    <section class="fix-dom l-gundam__parallax" id="parallax-04" data-enllax-ratio="-0.1">
        <img class="lazyload gradation" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/gradation.webp" alt="">
    </section>
    <section class="l-gundam__dom l-gundam__content u-pb__50">
        <div class="l-gundam__content-inner">
            <img class="lazyload frame" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4.webp" alt="GMD1001T-9A C4">
            <h3><small>OWNDAYS SNAP</small>DOM Model</h3>
            <p>Replicating Dom with the front in a matte dark grey shade, temple tips in matte black and temples in bright purple. A metal emblem of Principality of Zeon Forces is set on the underside of the temple tip while the model number, MS-09, is laser-engraved onto the reverse side of the temple.</p>
            <div class="l-gundam__products">
                <div class="l-gundam__products--left">
                    <div class="l-gundam__products-view dom-view">
                        <div class="is-tab-show">
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_01.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_02.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_03.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_04.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_05.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_06.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_07.webp" alt="">
                        </div>
                        <div>
                            <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_08.webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="l-gundam__products--right">
                    <ul class="l-gundam__products-thumbnail dom-thumbnail">
                        <li class="is-active"><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_01.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_02.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_03.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_04.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_05.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_06.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_07.webp" alt=""></li>
                        <li><img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/GMD1001T-9A_C4_thumb_08.webp" alt=""></li>
                    </ul>
                    <p class="u-text-comment">* Grey polarised SNAP LENS included</p>
                    <div class="l-gundam__products-set case">
                        <p><strong>DOM Model</strong><small>Exclusive Spectacle Case</small></p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/case-green.webp" alt="">
                    </div>
                    <div class="l-gundam__products-set cloth">
                        <p>Cleaning Cloth</p>
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/cloth-dom.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="l-gundam__information">
            <div class="l-gundam__information-inner">
                <div>
                    <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/logo-dom.svg" alt="">
                    <div>
                        <h4><small>OWNDAYS SNAP</small>DOM Model</h4>
                        <p>GDM1001T-9A COL4</p>
                        <p class="c-price c-price__default">S$ 198</p>
                        <p class="u-text-comment">* Not valid for any discount, promotion and/or privilege</p>
                    </div>
                </div>
                <ul>
                    <li>
                                            </li>
                    
                    
                    
                    
                    
                    
                    
                    
                                    </ul>
            </div>
        </div>
    </section>
                <section class="l-gundam__shops u-pb__120" id="available-shop">
        <h3>Outlets Available</h3>
        <p class="u-text-center">All collaboration frames are sold out in-store and online.</p>
    </section>
        <section class="l-gundam__share">
        <div class="l-gundam__form-share">
            <h3>SHARE</h3>
            <ul>
                <li class="sns-item">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/collabo/gundam/pj-snap" target="_blank">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                </li>
                <li class="sns-item">
                    <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/collabo/gundam/pj-snap" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-twitter"></i>
                    </a>
                </li>
                <li class="sns-item">
                    <a href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/collabo/gundam/pj-snap" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-line"></i>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <picture>
        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/line-bottom.svg">
        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/line-bottom-sp.svg" class="line" alt="">
    </picture>
    <section class="l-gundam__projects">
        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/collabo-logo.svg" alt="GUNDAM × OWNDAYS Wear the GUNDAM">
        <ul>
            <li>
                <a href="/sg/en/collabo/gundam/pj-snap">
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/project1_pc.webp">
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/project1_sp.webp" alt="">
                    </picture>
                </a>
                <div>
                    <h5>PROJECT:<span>01</span></h5>
                    <p>Collaboration Frames</p>
                </div>
            </li>
            <li>
                <a href="/sg/en/collabo/gundam/pj-head">
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/collabo/gundam/pj-snap/project2_pc.webp">
                        <img class="lazyload" data-src="https://www.owndays.com/images/collabo/gundam/pj-snap/project2_sp.webp" alt="">
                    </picture>
                </a>
                <div>
                    <h5>PROJECT:<span>02</span></h5>
                    <p>GUNDAM HEAD CASE</p>
                </div>
            </li>
                    </ul>
        <div class="c-btn c-btn--primary c-btn--primary-center l-gundam__form l-gundam__form-btn l-gundam__form-btn--white">
            <span class="corner"></span><a href="/sg/en/news/gundam">PROJECT TOP</a>
        </div>
    </section>
</section>
        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/smoothscroll/1.4.4/SmoothScroll.min.js"></script>
<script src="https://www.owndays.com/web/js/scroll_internal-link.js" defer></script>
<script src="https://www.owndays.com/web/js/jquery.enllax.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-gundam.js" defer></script>
  
</body>

</html>`;

const GundamHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        description="Step into the world of GUNDAM with our exclusive eyewear collection. These iconic frames blend style and fandom seamlessly. Own a piece of the GUNDAM universe – shop now!"
        title="Gundam X OWNDAYS | Collaboration Frames | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default GundamHTML;
