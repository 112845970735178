import React, { Fragment, useState } from 'react';
import Slider from 'react-slick';

import {
  kuromiProductsSetting,
  NAV_COLORS_KUROMI,
  NAV_COLORS_KUROMI_2,
  NAV_COLORS_BAKU,
  NAV_COLORS_BAKU_2,
  SR1005B_2A_C1_PRODUCTS,
  SR1005B_2A_C2_PRODUCTS,
  SR1005B_2A_POINTS,
  SR1006B_2A_C1_PRODUCTS,
  SR1006B_2A_C2_PRODUCTS,
  SR1006B_2A_POINTS,
  SR2001B_2A_C1_PRODUCTS,
  SR2001B_2A_C2_PRODUCTS,
  SR2001B_2A_POINTS,
  SR2002B_2A_C1_PRODUCTS,
  SR2002B_2A_C2_PRODUCTS,
  SR2002B_2A_POINTS,
} from './constants';

import './BodyKuromiCollection.scss';

export const BodyKuromiCollection = ({ baseImgUrl }) => {
  const [kuromiProducts, setKuromiProducts] = useState(SR1005B_2A_C1_PRODUCTS);
  const [kuromiProducts2, setKuromiProducts2] = useState(SR2001B_2A_C1_PRODUCTS);
  const [bakuProducts, setBakuProducts] = useState(SR1006B_2A_C1_PRODUCTS);
  const [bakuProducts2, setBakuProducts2] = useState(SR2002B_2A_C1_PRODUCTS);

  const renderNavColors = (colors, characterName) => {
    return (
      <ul className="base-kuromi-collection__color" data-title="COLOUR">
        {colors.map(color => (
          <li
            key={color.name}
            onClick={() => {
              if (characterName === 'kuromi') {
                if (color.name === 'black') {
                  setKuromiProducts(SR1005B_2A_C1_PRODUCTS);
                } else {
                  setKuromiProducts(SR1005B_2A_C2_PRODUCTS);
                }
              }

              if (characterName === 'kuromi2') {
                if (color.name === 'white-e3') {
                  setKuromiProducts2(SR2001B_2A_C1_PRODUCTS);
                } else {
                  setKuromiProducts2(SR2001B_2A_C2_PRODUCTS);
                }
              }

              if (characterName === 'baku') {
                if (color.name === 'purple-cd') {
                  setBakuProducts(SR1006B_2A_C1_PRODUCTS);
                } else {
                  setBakuProducts(SR1006B_2A_C2_PRODUCTS);
                }
              }

              if (characterName === 'baku2') {
                if (color.name === 'white-e3') {
                  setBakuProducts2(SR2002B_2A_C1_PRODUCTS);
                } else {
                  setBakuProducts2(SR2002B_2A_C2_PRODUCTS);
                }
              }
            }}
          >
            <div className={`base-kuromi-collection__color__item--${color.name}`}></div>
          </li>
        ))}
      </ul>
    );
  };

  const renderLineUpAbout = (characterName, message) => {
    const fileType = characterName === 'kuromi' ? 'webp' : 'svg';

    return (
      <div className="body-kuromi-collection__lineup__top base-kuromi-collection__container">
        <h3
          className="body-kuromi-collection__lineup__top-name"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          <span>
            <img alt={`${characterName} model`} src={`${baseImgUrl}/${characterName}-name.svg`} />
          </span>
          <small>Model</small>
        </h3>

        <picture className="body-kuromi-collection__lineup__top-character" data-aos="fade-left">
          <source
            media="(min-width:768px)"
            srcSet={`${baseImgUrl}/${characterName}-pc.${fileType}`}
          />
          <img alt={characterName} src={`${baseImgUrl}/${characterName}-sp.${fileType}`} />
        </picture>

        <p
          className="body-kuromi-collection__lineup__top-detail"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          {message}
        </p>
      </div>
    );
  };

  const renderPoints = points => {
    return (
      <ul className="body-kuromi-collection__lineup__point base-kuromi-collection__container">
        {points.map((point, index) => (
          <li key={point.alt} data-aos="fade-up" data-aos-delay="100">
            <div className="body-kuromi-collection__lineup__point-img-wrap">
              <h4 className="body-kuromi-collection__lineup__point-title">
                <small>POINT</small> 0{index + 1}
              </h4>
              <img alt={point.alt} src={`${baseImgUrl}/products/${point.src}`} />
            </div>
            <p>{point.desc}</p>
          </li>
        ))}
      </ul>
    );
  };

  const renderLineupHeader = (productNo, productTitle, productCode, navColors, characterName) => {
    return (
      <div
        className="body-kuromi-collection__lineup__slider-header base-kuromi-collection__container"
        data-aos="fade-up"
      >
        <div>
          <div className="body-kuromi-collection__lineup__slider-product-no">
            <h3>{productNo}</h3>
          </div>
          <div className="body-kuromi-collection__lineup__slider-product-title">
            <h4>{productTitle}</h4>
          </div>
          <p className="body-kuromi-collection__lineup__slider-product-code">{productCode}</p>
        </div>

        <div className="base-kuromi-collection__hide-sp">
          {renderNavColors(navColors, characterName)}
        </div>
      </div>
    );
  };

  const renderSlider = (products, color) => {
    return (
      <Slider
        {...kuromiProductsSetting}
        className={`body-kuromi-collection__lineup__slider-product-list body-kuromi-collection__lineup__slider-product-list--${color}`}
      >
        {products?.map((item, index) => (
          <li
            key={`${item.alt} ${index}`}
            className="body-kuromi-collection__lineup__slider-product-inner"
          >
            <div className="base-kuromi-collection__item-corner-top">
              <img alt={item.alt} src={`${baseImgUrl}/products/${item.src}`} />
              <span className="base-kuromi-collection__item-corner-bottom"></span>
            </div>
          </li>
        ))}
      </Slider>
    );
  };

  const renderButton = label => {
    return (
      <div className="base-kuromi-collection__container">
        <a disabled className="base-kuromi-collection__button" href="">
          <span>{label}</span>
        </a>
      </div>
    );
  };

  return (
    <Fragment>
      <section className="body-kuromi-collection__lineup">
        <h2 className="base-kuromi-collection__title">LINEUP</h2>

        <div className="body-kuromi-collection__lineup__content body-kuromi-collection__lineup--pink">
          {renderLineUpAbout(
            'kuromi',
            <>
              She looks punk but is actually quite girly!?
              <br />
              Spectacle frames modelled after Kuromi, with motifs that draw reference from
              characteristics such as her jester hat and black devil tail featured across the frame
              body.
            </>
          )}

          <div className="body-kuromi-collection__lineup__slider">
            {renderLineupHeader('#kuromi 01', 'Polygon', 'SR1005B-2A', NAV_COLORS_KUROMI, 'kuromi')}
            {renderSlider(kuromiProducts, 'pink')}

            <div className="base-kuromi-collection__center base-kuromi-collection__hide-pc">
              {renderNavColors(NAV_COLORS_KUROMI, 'kuromi')}
            </div>

            {renderPoints(SR1005B_2A_POINTS)}
            {renderButton('SOLD OUT')}
          </div>

          <div className="body-kuromi-collection__lineup__slider">
            {renderLineupHeader(
              '#kuromi 02',
              'Boston',
              'SR2001B-2A',
              NAV_COLORS_KUROMI_2,
              'kuromi2'
            )}
            {renderSlider(kuromiProducts2, 'pink')}

            <div className="base-kuromi-collection__center base-kuromi-collection__hide-pc">
              {renderNavColors(NAV_COLORS_KUROMI_2, 'kuromi2')}
            </div>

            {renderPoints(SR2001B_2A_POINTS)}
            {renderButton('SOLD OUT')}
          </div>
        </div>

        <div className="body-kuromi-collection__lineup__content body-kuromi-collection__lineup--purple">
          {renderLineUpAbout(
            'baku',
            <>
              Spectacle frames inspired by Baku, Kuromi’s sidekick who is always by her side.
              <br />
              The frame design incorporates references to the striking shape of Baku’s eyes and ears
              as well as his favourite food, Takoyaki.
            </>
          )}

          <div className="body-kuromi-collection__lineup__slider">
            {renderLineupHeader('#baku 01', 'Polygon', 'SR1006B-2A', NAV_COLORS_BAKU, 'baku')}
            {renderSlider(bakuProducts, 'purple')}

            <div className="base-kuromi-collection__center base-kuromi-collection__hide-pc">
              {renderNavColors(NAV_COLORS_BAKU, 'baku')}
            </div>

            {renderPoints(SR1006B_2A_POINTS)}
            {renderButton('SOLD OUT')}
          </div>

          <div className="body-kuromi-collection__lineup__slider">
            {renderLineupHeader('#baku 02', 'Wellington', 'SR2002B-2A', NAV_COLORS_BAKU_2, 'baku2')}
            {renderSlider(bakuProducts2, 'purple')}

            <div className="base-kuromi-collection__center base-kuromi-collection__hide-pc">
              {renderNavColors(NAV_COLORS_BAKU_2, 'baku2')}
            </div>

            {renderPoints(SR2002B_2A_POINTS)}
            {renderButton('SOLD OUT')}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
