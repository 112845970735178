import React from 'react';
import './EmailServerFailureNotice.scss';
import { ContactInfo } from '../ContactInfo';
import { SnsFooter } from '../SnsFooter';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export const EmailServerFailureNotice = () => {
  const shareUrl = '/sg/en/information/217';
  const BREADCRUMBS = [
    { path: '/sg/en/information', label: 'See all the Information' },
    { path: '#', label: 'Email Server Failure Notice' },
  ];

  return (
    <>
      <ContactInfo />
      <main className="email-server-failure">
        <Breadcrumbs crumbs={BREADCRUMBS} />

        <div className="email-server-failure__container">
          <section className="p-information p-information-details email-server-failure__content--inner">
            <div className="p-information-details__content">
              <h1>Email Server Failure Notice</h1>

              <div className="p-information-details__inner">
                <p>
                  The email server failure that occurred at the following time has been resolved.
                </p>

                <h3>Server Downtime</h3>
                <p>11 November 2021, 4:30pm to 12 November 2021, 10:00am</p>

                <h3>Services Affected</h3>
                <ul className="c-list-decoration">
                  <li>All emails pertaining to your online orders</li>
                  <li>Membership registration emails</li>
                  <li>Email to reset your password</li>
                  <li>All emails pertaining to your eye test appointment</li>
                </ul>

                <p>
                  The system is now fully restored and you will be able to receive email
                  notifications as usual.
                </p>
                <p>
                  We apologise for the inconvenient caused and thank you for your kind
                  understanding.
                </p>
              </div>

              <SnsFooter shareUrl={shareUrl} />

              <a className="back-button" href="/sg/en/information">
                <i className="fas fa-chevron-left"></i>Back
              </a>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
