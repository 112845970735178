import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="#03 AKIYO NOGUCHI - OWNDAYS MEETS | OWNDAYS ONLINE STORE - OPTICAL SHOP)" />
    <meta name="description" content="#03 AKIYO NOGUCHI -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path." />
    <meta name="keywords" content="AKIYO NOGUCHI,OWNDAYS MEETS,glasses,spectacles,optical shop,eyeglasses,OWNDAYS,frame,sunglasses,eyewear Singapore" />
    <meta name="og:description" content="#03 AKIYO NOGUCHI -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path." />
    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/owndays-meets-201402">
          <meta property="og:image" content="https://www.owndays.com/images/specials/owndays-meets/201402/ogp_1200x630.jpg?1553872749">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
        <link href="https://www.owndays.com/web/css/owndays-meets.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title"> #03 AKIYO NOGUCHI - OWNDAYS MEETS | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
        <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            #03 AKIYO NOGUCHI - OWNDAYS MEETS                    </li>
        
            </ul>
</div>

    <div class="l-owndays-meets">
            <section class="p-main">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/owndays-meets/201402/01_imgcnv-2880x1620.webp">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201402/01_imgcnv-768x615.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h1 class="p-main__title-default meets-title">
                <img src="https://www.owndays.com/images/specials/owndays-meets/owndays-meets.png" alt="OWNDAYS MEETS">
                <span class="meets-title__vol">vol. 03</span>
                <span class="meets-title__name"><span><span>Noguchi Akiyo<span>A professional free climber</span></span></span></span>
            </h1>
        </div>
    </section>
        <div class="l-content--inner">
            <p class="l-owndays-meets__information">    OWNDAYS MEETS’s third guest is the world renowned Japanese climber Akiyo Noguchi. We spoke to her about the path she has taken as a top climber aiming to be the best in the world, and what the road ahead has in store for her.</p>
        </div>
        <section class="l-owndays-meets__profile">
                <div>
        <figure><img src="https://www.owndays.com/images/specials/owndays-meets/201402/profile_imgcnv.webp" alt="Noguchi Akiyo"></figure>
        <div>
            <div>
                <h2 class="profile__name">Noguchi Akiyo<span></span></h2>
                <p class="profile__job">A professional free climber</p>
            </div>
        </div>
    </div>
    <p class="profile__text">    Born on May 30th in 1989, Akiyo had her first encounter with free climbing on a family holiday to Guam when she was in the fifth grade of elementary school. Holding off competition from high school and junior high school students to win the All-Japan Youth Championships the following year, she quickly made her mark on the scene.<br>After this she continued to shine in tournaments in Japan and overseas and was the first Japanese female to win the Climbing World Cup bouldering event in 2008, becoming the overall champion for that year. She continued to shine in 2009 at the pinnacle of the climbing world and achieved the same feat again, becoming the first Japanese person to manage to do so.<br>Competing mainly in the Climbing World Cup and other such events around the world, she is without a doubt a prominent climber on the global climbing scene and the pride of Japan.</p>
    <p class="profile__link">    <a href="http://akiyonoguchi.com/wp_new/" target="_blank">Official Web Site</a>
</p>
        </section>
        <section class="l-owndays-meets__interview">
            <div class="l-content--inner">
                <div class="interview__movie">
                    <a href="" data-video="https://www.youtube.com/embed/jvQaddbpcak?loop=1&playlist=jvQaddbpcak" data-toggle="modal" data-target="#videoModal">
                        <img src="https://www.owndays.com/images/specials/owndays-meets/201402/movie_imgcnv.webp" alt="OWNDAYS MEETS Noguchi Akiyo()">
                    </a>
                    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" area-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe width="560" height="315" src="" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                    </div>
                    <h3 class="interview__question"><span><span>How did you originally get into climbing?</span></span></h3>
    <p class="interview__answer">When I was eleven years old in the fifth grade of elementary school I went on a summer holiday to Guam with my family, and in a game arcade that we went to there just happened to be a climbing wall. We all had a go on it for a bit of fun, and that was the start for me. I got really obsessed with climbing after that and just wanted to keep doing it when I got back to Japan. I’ve been doing it ever since.</p>
    <h3 class="interview__question"><span><span>Do you remember how you felt the first time you tried your hand at climbing?</span></span></h3>
    <p class="interview__answer">I do indeed remember it, I enjoyed it a lot. My family actually runs a cattle farm, and when I was a kid I used to really love climbing the trees there, climbing up onto the roof of the barns, playing around with the cows, things like that. So the first time I tried climbing I suppose it didn’t actually feel like the first time, it was much the same kind of feeling I got from playing around the house, but I remember it being so much fun. Also, when I started there were problems (climbing routes) for children and problems for adults. I got really frustrated because although I could fly up the problems for kids I couldn’t do the ones for adults, but I felt that I want to keep trying and that at some point in the future I would be able to climb the difficult ones too. The simple act of climbing is great fun in itself. When you are in the middle of it you concentrate so much that you don’t think about anything else so time passes really quickly. I just kept getting more and more into it.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201402/02_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>Do you remember the first time you won a competition?</span></span></h3>
    <p class="interview__answer">I do, yes. I managed to win the All-Japan Youth Championships (a competition for people younger than 20 years old) when I was in the sixth grade of elementary school, but back then there weren’t many kids climbing so it didn’t require any particularly great effort and didn’t get much of a sense of achievement from it.<br>When I was in junior high school my Dad converted an old barn into a climbing gym for me and that’s where I used to train. At first it wasn’t really serious training, but that changed when I became a high school student and got the chance to appear in the Climbing World Cup. The first time I took part in the Climbing World Cup was when I was 16. I was initially convinced that I would come last, but I managed to make it all the way to the final and ended up finishing third. This made me feel that the effort I made had been reflected in the results and gave me a great feeling of satisfaction, so after that I set myself the target of becoming the best and started to enjoy training.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201402/03_imgcnv.webp" alt="">
    </div>
    <h3 class="strong">“Exploring an unknown road”</h3>
    <h3 class="interview__question"><span><span>At what point did you decide that you wanted to be a professional?</span></span></h3>
    <p class="interview__answer">At what point did you decide that you wanted to be a professional?</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201402/04_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>The whole climbing scene has started to take off over the past few years, right?</span></span></h3>
    <p class="interview__answer">12 or 13 years ago when I took it up, ‘climbing’ was still a relatively new word in Japan and not many people had heard of it (we use the English word you see) so you had to start with an explanation of what it was when you talked to anyone about it. But now if you tell people that you do climbing they know exactly what you are talking about and usually respond by telling you that they’ve seen a climbing gym near a particular train station or they have seen it on TV, which makes me very happy. Back in the day I had almost no friends who climbed, but I have a lot more now.</p>
    <h3 class="interview__question"><span><span>What kind of path are you going to take from now as a climber?</span></span></h3>
    <p class="interview__answer">The climbing scene is slowly but surely gaining popularity and starting to become known, so I want to take it forward and help it to grow. I’ve learned a lot of things from climbing and have so many great memories from it that I just want to keep climbing for as long as I can and share the fun and excitement with as many people as possible.<small class="u-db u-text-right u-mt__10">Interview : January 2014</small></p>
            </div>
        </section>
        <section class="l-owndays-meets__guestselect">
                <div class="l-content--inner">
        <h2>
            Guest Select
            <a href="/sg/en/products?productLines[]=16">AIR Ultem</a>
        </h2>
        <ul class="guestselect__photos">
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201402/glasses_imgcnv.webp" alt="AIR Ultem/OT2025"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201402/05_imgcnv.webp"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201402/06_imgcnv.webp"></div></li>
        </ul>
        <p>    As soon as I saw these glasses I felt they would suit me. I don’t have bad eyes but I enjoy wearing glasses for fashion, and I chose the ones I did here because they go well with the training clothes I’m wearing today. It’s good to select the design and colour depending on how you look on the day, so it would be nice to have many pairs.</p>
        <div class="guestselect__product">
            <dl>
                <dt>P/No.</dt><dd>OT2025</dd>
                <dt>Colour</dt><dd>C1 Matte Black</dd>
            </dl>
        </div>
        
        
        
    </div>
        </section>
        <section id="latestpost" class="l-owndays-meets__latestpost">
    <div class="l-content--inner">
        <h2>Latest post</h2>
        <!-- 最新の記事2つ -->
                <div class="latestpost__list">
            <a href="/sg/en/news/owndays-meets-201905">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201905/list-main.webp" alt="HIROTADA OTOTAKE / Writer">
                <div>
                    <p class="latestpost__list-vol">vol. 19</p>
                    <p class="latestpost__list-name">HIROTADA OTOTAKE / Writer</p>
                </div>
            </a>
            <a href="/sg/en/news/owndays-meets-201808">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201901/list-main.webp" alt="KOMATSU MIWA / Contemporary Artist">
                <div>
                    <p class="latestpost__list-vol">vol. 18</p>
                    <p class="latestpost__list-name">KOMATSU MIWA / Contemporary Artist</p>
                </div>
            </a>
        </div>
                <div class="c-btn c-btn--primary c-btn--primary-center u-mt__30">
            <a href="/sg/en/news#owndays-meets">Back to list</a>
        </div>
    </div>
</section>
    </div>
        </main>
            </div>


    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
    <script src="https://www.owndays.com/web/js/movie-modal.js" defer></script>
    <script>
        $(document).ready(function () {
            autoPlayYouTubeModal();
        });
    </script>
 
</body>

</html>`;

const OwndaysMeetsAkiyoNohuchiHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        canonical="/news/owndays-meets/201402"
        description="Dive deep into Keisuke Okunoya's world of fashion and design. Discover his eyewear choices in this edition of 'OWNDAYS MEETS'."
        title="OWNDAYS MEETS: #04 KEISUKE OKUNOYA | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OwndaysMeetsAkiyoNohuchiHTML;
