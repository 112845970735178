import React, { useEffect } from 'react';
import './UploadPrescription.scss';
import * as productAction from '../../../../actionCreators/product';
import { useDispatch, useSelector, connect } from 'react-redux';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { addToCart } from '../../../../actionCreators/cart';
import { showGlobalHeaderFooter } from '../../../../actionCreators/header';
import { bindActionCreators } from 'redux';
import { odPushGA4Event } from '../../../../utils/gaFour';
import DottedLine from '../../../../../static/assets/svg/dotted.svg';
import ProductPreview from '../../../CommonComponents/ProductPreview/ProductPreview';

const UploadPrescription = props => {
  const productId = props?.match?.params?.id;
  const history = props?.history;
  const showGlobalHeaderFooter = props?.showGlobalHeaderFooter;
  const dispatch = useDispatch();
  const product = useSelector(state => state?.product?.productData?.result);
  const {
    WHAT_ABOUT_MY_EYE_POWER,
    YOU_CAN_SUBMIT_POWER_AFTER_PAYMENT_STEP,
    CONTACT_US_PHONE,
    PHONE_ASSISTANCE,
    POWER_SUBMITTED_TIME,
    NO_ADDITIONAL_CHARGES,
    CONTINUE,
    I_HAVE_READ_AND_UNDERSTOOD_THE_TERMS_OF_POWER_SUBMISSION,
  } = useSelector(state => state?.locale);

  const { userInfo, platform } = props;

  const setUploadPrescriptionHeight = () => {
    const headerHeight = window.document.getElementById('header')?.offsetHeight;
    const uploadPrescription = window.document.getElementById('upload-prescription');
    if (headerHeight && uploadPrescription) {
      uploadPrescription.style.minHeight = `calc(100vh - ${Math.floor(headerHeight)}px)`;
    }
  };

  useEffect(() => {
    showGlobalHeaderFooter({ header: true, footer: false });

    setUploadPrescriptionHeight();

    if (productId) {
      dispatch(productAction.getProductData(productId));
    }

    odPushGA4Event('page_view', {
      screenName: 'submit-power-later-page',
      userInfo,
      platform,
    });

    window.clevertapScreenName = 'prescription-upload-type-page';
    return () => {
      window.clevertapScreenName = null;
    };
  }, []);

  useEffect(() => {
    setUploadPrescriptionHeight();
  });

  const handleSelectLenses = () => {
    if (typeof window.clevertap !== 'undefined') {
      window.clevertap.event.push('cta_click ', {
        cta_name: 'select-lenses-and-purchase',
        cta_flow_and_page: window.clevertapScreenName,
      });
    }

    dispatch(addToCart({ productId, powerType: 'Frame Only' }));
    history.push('/cart');
  };

  if (product) {
    return (
      <div className="od-upload-prescription-d" id="upload-prescription">
        <ProductPreview description={product?.description} src={product?.image} />
        <div className="od-upload-prescription-d__right">
          <div className="od-upload-prescription-card">
            <h4 className="od-upload-prescription-card__title">{WHAT_ABOUT_MY_EYE_POWER}</h4>
            <p className="od-upload-prescription-card__para">
              {YOU_CAN_SUBMIT_POWER_AFTER_PAYMENT_STEP}
            </p>
            <div className="od-upload-prescription-card__upload">
              <a
                className="od-upload-prescription-card__upload-item"
                href={`tel:${CONTACT_US_PHONE}`}
              >
                <img
                  alt="Phone Assistance"
                  src="https://static.lenskart.com/media/desktop/img/pdp/phone.png"
                />
                <div>{PHONE_ASSISTANCE}</div>
              </a>
              <div className="od-upload-prescription-card__upload-item">
                <img
                  alt="Phone Assistance"
                  src="https://static.lenskart.com/media/desktop/img/pdp/upload_prescription.png"
                />
                <div>Upload Prescription</div>
              </div>
            </div>
            <img alt="dotted line" className="od-upload-prescription-line" src={DottedLine} />
            <p className="od-upload-prescription-card__para">{POWER_SUBMITTED_TIME}</p>
            <img alt="dotted line" className="od-upload-prescription-line" src={DottedLine} />
            <p className="od-upload-prescription-card__para od-font-bold">
              <strong>{NO_ADDITIONAL_CHARGES}</strong>
            </p>
          </div>

          <div className="od-upload-prescription-d__footer">
            <p className="od-upload-prescription-d__footer__acceptTerms">
              {I_HAVE_READ_AND_UNDERSTOOD_THE_TERMS_OF_POWER_SUBMISSION}
            </p>
            <ButtonOwnDays
              fontClass="od-font-bold"
              mode="grey"
              style={{ margin: '0 auto', marginBotton: 30, width: 360, height: 56 }}
              onClick={handleSelectLenses}
            >
              <div className="od-upload-prescription-d__continueBtn">
                <div>{CONTINUE}</div>
                <i className="fa fa-chevron-right"></i>
              </div>
            </ButtonOwnDays>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  userInfo: state.common.userInfo,
  platform: state.DTM.platform,
});

const mapDispatchToProps = dispatch => {
  return {
    showGlobalHeaderFooter: bindActionCreators(showGlobalHeaderFooter, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPrescription);
