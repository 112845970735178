import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="Official OWNDAYS Facebook Account｜OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                    <meta name="description" content="Breeze through the in-store registration process when you link your OWNDAYS account to Facebook. You can also retrieve your warranty cards and prescription details from your mobile phone anytime, anywhere!">
                                            <meta name="keywords" content="Official OWNDAYS Facebook Account,Guide,glasses,spectacles,optical shop,online store,eyeglasses,OWNDAYS,frame,sunglasses,pc lenses">
                                                                                                        <meta property="og:description" content="Breeze through the in-store registration process when you link your OWNDAYS account to Facebook. You can also retrieve your warranty cards and prescription details from your mobile phone anytime, anywhere!">
                                            <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/account/facebook">
        <meta name="og:image" content="https://www.owndays.com/images/account/facebook/sg/ogp_1200x630.jpg" />
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css">
<link rel="stylesheet" href="https://www.owndays.com/web/css/line.css">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    Official OWNDAYS Facebook Account｜OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>

<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
<div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/account/facebook">Official OWNDAYS Facebook Account</a>
                                                                                </li>
        
            </ul>
</div>


<section class="l-account-service">

    <ul class="nav nav-tabs nav-justified" role="tablist" id="serviceTabs">
        <li class="nav-item" role="presentation">
            <a class="nav-link whatsapp-tab" href="/sg/en/account/whatsapp">WhatsApp</a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link active facebook-tab" href="/sg/en/account/facebook">Facebook</a>
        </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane" id="whatsapp" role="tabpanel">
            <section class="l-line l-whatsapp">
                <section class="l-line__main l-content--inner">
                    <img src="https://www.owndays.com/images/account/whatsapp/whatsapp-main.svg" alt="" class="u-pc-only">
                    <div>
                        <h1><img src="https://www.owndays.com/images/account/whatsapp/sg/whatsapp-icon.svg" alt="With WhatsApp">You can now enjoy <br class="u-sp-only">more convenience <br class="u-pc-only">and better shopping experience at OWNDAYS</h1>
                        <img src="https://www.owndays.com/images/account/whatsapp/whatsapp-main.svg" alt="" class="u-sp-only">
                        <p>
                            Breeze through the in-store registration process when you link your OWNDAYS account to WhatsApp.<br>
                            You can also retrieve your warranty cards and prescription details from your mobile phone anytime, anywhere!
                        </p>
                    </div>
                </section>
                <hr>
                <section class="l-line__howto l-content--inner">
                    <h2>Steps to link <br class="u-sp-only">OWNDAYS account to WhatsApp</h2>
                    <div class="l-line__howto-qr">
                        <div>
                            <h3>Let’s first connect on WhatsApp!</h3>
                            <p class="u-pc-only">Scan QR code to find the <strong>official OWNDAYS SINGAPORE account </strong> on WhatsApp.</p>
                            <div class="c-btn c-btn--fullwidth c-btn__line u-sp-only">
                                <a href="https://api.whatsapp.com/send/?phone=6598214268&text&app_absent=0"><i class="fab fa-whatsapp"></i>Add friend now</a>
                            </div>
                            <span class="u-text-comment u-text-comment--normal u-sp-only u-text-center">* Tap here to open in WhatsApp</span>
                        </div>
                        <img src="https://www.owndays.com/images/account/whatsapp/sg/qr.webp" alt="" class="u-pc-only">
                    </div>
                    <ul class="l-line__howto-flow">
                        <li>
                            <span>1</span>
                            <img src="https://www.owndays.com/images/account/whatsapp/sg/screen-1.webp" alt="">
                            <h4>Connect</h4>
                            <p>Scan QR code to find the official OWNDAYS SINGAPORE account on WhatsApp.</p>
                        </li>
                        <li>
                            <span>2</span>
                            <img src="https://www.owndays.com/images/account/whatsapp/sg/screen-2.webp" alt="">
                            <h4>Send message</h4>
                            <p>Send out a message with the word “Menu”.</p>
                        </li>
                        <li>
                            <span>3</span>
                            <img src="https://www.owndays.com/images/account/whatsapp/sg/screen-3.webp" alt="">
                            <h4>Tap “My Account”</h4>
                            <p>Select “My Account” from the menu and follow the on-screen instructions to login.</p>
                        </li>
                        <li>
                            <span>4</span>
                            <img src="https://www.owndays.com/images/account/whatsapp/sg/screen-4.webp" alt="">
                            <h4>Log in</h4>
                            <p>Log in with your OWNDAYS account credentials. Please register an OWNDAYS membership account if you do not have one.</p>
                        </li>
                    </ul>
                    <hr>
                    <div class="l-line__howto-note c-list-decoration c-list-decoration--asterisk">
                        <p>Your purchase record may not be found if there is a discrepancy between the information provided in-store previously and when you registered for your OWNDAYS account.</p>
                    </div>
                </section>
                <section class="l-line__function">
                    <h2><span>Link your OWNDAYS account</span>for added convenience</h2>
                    <div class="l-content--inner">
                        <ul>
                            <li>
                                <a href="#digital-id">
                                    <img src="https://www.owndays.com/images/account/whatsapp/link-icon-1.svg" alt="">
                                    <p>Digital<br>Member ID</p>
                                </a>
                            </li>
                            <li>
                                <a href="#warranty">
                                    <img src="https://www.owndays.com/images/account/whatsapp/link-icon-3.svg" alt="">
                                    <p>Warranty and<br>after-sales services</p>
                                </a>
                            </li>
                            <li>
                                <a href="#prescription">
                                    <img src="https://www.owndays.com/images/account/whatsapp/link-icon-4.svg" alt="">
                                    <p>Prescription<br>records</p>
                                </a>
                            </li>
                        </ul>
                        <div class="l-line__function-block l-line__function-block--left" id="digital-id">
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Digital Member ID</h3>
                                <img src="https://www.owndays.com/images/account/whatsapp/sg/image-1.webp" alt="">
                            </div>
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Digital Member ID</h3>
                                <h4>Save time and effort without having to re-enter your particulars at the store</h4>
                                <p>Skip the registration process by simply presenting your membership number for your next in-store purchase.</p>
                            </div>
                        </div>
                        <div class="l-line__function-block l-line__function-block--right" id="warranty">
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Warranty and<br>after-sales services</h3>
                                <h4>Retrieve your purchase records and warranty cards from your mobile phone</h4>
                                <p>Link your OWNDAYS account to WhatsApp and you will be able to retrieve your purchase records and warranty cards anytime, anywhere. There is no need to keep physical copies anymore.</p>
                            </div>
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Warranty and after-sales services</h3>
                                <img src="https://www.owndays.com/images/account/whatsapp/sg/image-2.webp" alt="">
                            </div>
                        </div>
                        <div class="l-line__function-block l-line__function-block--left" id="prescription">
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Prescription records</h3>
                                <img src="https://www.owndays.com/images/account/whatsapp/sg/image-3.webp" alt="">
                            </div>
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Prescription records</h3>
                                <h4>Want to know the lens power of your OWNDAYS spectacles?<br>Just look it up via WhatsApp!</h4>
                                <p>Your prescription records will automatically be saved in your account after eye check. You can then retrieve them anytime, anywhere and use them for online purchases.</p>
                            </div>
                        </div>
                        <p class="l-line__function-message">Be notified about sales,<br>product launches and other updates!</p>
                    </div>
                </section>
                <section class="l-line__information l-content--inner u-text-center">
                    <h3>Stay tuned for more functions that will be released <br>on the official OWNDAYS SINGAPORE WhatsApp account!</h3>
                </section>
            </section>


        </div>
        <div class="tab-pane active" id="facebook" role="tabpanel">
            <section class="l-line l-facebook">
                <section class="l-line__main l-content--inner">
                    <img src="https://www.owndays.com/images/account/facebook/facebook-main.svg" alt="" class="u-pc-only">
                    <div>
                        <h1><img src="https://www.owndays.com/images/account/facebook/facebook-icon.svg" alt="With WhatsApp">You can now enjoy <br class="u-sp-only">more convenience <br class="u-pc-only">and better shopping experience at OWNDAYS</h1>
                        <img src="https://www.owndays.com/images/account/facebook/facebook-main.svg" alt="" class="u-sp-only">
                        <p>
                            Breeze through the in-store registration process when you link your OWNDAYS account to Facebook.<br>
                            You can also retrieve your warranty cards and prescription details from your mobile phone anytime, anywhere!
                        </p>
                    </div>
                </section>
                <hr>
                <section class="l-line__howto l-content--inner">
                    <h2>Steps to link <br class="u-sp-only">OWNDAYS account to Facebook</h2>
                    <ul class="l-line__howto-flow">
                        <li>
                            <span>1</span>
                            <img src="https://www.owndays.com/images/account/facebook/sg/screen-1.webp" alt="">
                            <h4>Search Page</h4>
                            <p>Search for OWNDAYS SINGAPORE in Facebook and tap the “Send Message” button.</p>
                        </li>
                        <li>
                            <span>2</span>
                            <img src="https://www.owndays.com/images/account/facebook/sg/screen-2.webp" alt="">
                            <h4>Get Started</h4>
                            <p>Tap the “Get Started” button found at the bottom right of the screen.</p>
                        </li>
                        <li>
                            <span>3</span>
                            <img src="https://www.owndays.com/images/account/facebook/sg/screen-3.webp" alt="">
                            <h4>Tap “My Account”</h4>
                            <p>Select “My Account” from the menu. Tap “Login” if this is your first time accessing the profile.</p>
                        </li>
                        <li>
                            <span>4</span>
                            <img src="https://www.owndays.com/images/account/facebook/sg/screen-4.webp" alt="">
                            <h4>Log in</h4>
                            <p>Log in with your OWNDAYS account credentials. Please register an OWNDAYS membership account if you do not have one.</p>
                        </li>
                    </ul>
                    <hr>
                    <div class="l-line__howto-note c-list-decoration c-list-decoration--asterisk">
                        <p>Your purchase record may not be found if there is a discrepancy between the information provided in-store previously and when you registered for your OWNDAYS account.</p>
                    </div>
                </section>
                <section class="l-line__function">
                    <h2><span>Link your OWNDAYS account</span>for added convenience</h2>
                    <div class="l-content--inner">
                        <ul>
                            <li>
                                <a href="#digital-id">
                                    <img src="https://www.owndays.com/images/account/facebook/link-icon-1.svg" alt="">
                                    <p>Digital<br>Member ID</p>
                                </a>
                            </li>
                            <li>
                                <a href="#warranty">
                                    <img src="https://www.owndays.com/images/account/facebook/link-icon-3.svg" alt="">
                                    <p>Warranty and<br>after-sales services</p>
                                </a>
                            </li>
                            <li>
                                <a href="#prescription">
                                    <img src="https://www.owndays.com/images/account/facebook/link-icon-4.svg" alt="">
                                    <p>Prescription<br>records</p>
                                </a>
                            </li>
                        </ul>
                        <div class="l-line__function-block l-line__function-block--left" id="digital-id">
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Digital Member ID</h3>
                                <img src="https://www.owndays.com/images/account/facebook/sg/image-1.webp" alt="">
                            </div>
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Digital Member ID</h3>
                                <h4>Save time and effort without having to re-enter your particulars at the store</h4>
                                <p>Skip the registration process by simply presenting your membership number for your next in-store purchase.</p>
                            </div>
                        </div>
                        <div class="l-line__function-block l-line__function-block--right" id="warranty">
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Warranty and<br>after-sales services</h3>
                                <h4>Retrieve your purchase records and warranty cards from your mobile phone</h4>
                                <p>Link your OWNDAYS account to Facebook and you will be able to retrieve your purchase records and warranty cards anytime, anywhere. There is no need to keep physical copies anymore.</p>
                            </div>
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Warranty and after-sales services</h3>
                                <img src="https://www.owndays.com/images/account/facebook/sg/image-2.webp" alt="">
                            </div>
                        </div>
                        <div class="l-line__function-block l-line__function-block--left" id="prescription">
                            <div class="l-line__function-block-image">
                                <h3 class="u-sp-only">Prescription records</h3>
                                <img src="https://www.owndays.com/images/account/facebook/sg/image-3.webp" alt="">
                            </div>
                            <div class="l-line__function-block-text">
                                <h3 class="u-pc-only">Prescription records</h3>
                                <h4>Want to know the lens power of your OWNDAYS spectacles?<br>Just look it up via Facebook!</h4>
                                <p>Your prescription records will automatically be saved in your account after eye check. You can then retrieve them anytime, anywhere and use them for online purchases.</p>
                            </div>
                        </div>
                        <p class="l-line__function-message">Be notified about sales,<br>product launches and other updates!</p>
                    </div>
                </section>
                <section class="l-line__information l-content--inner u-text-center">
                    <h3>Stay tuned for more functions that will be released <br>on the official OWNDAYS Facebook account!</h3>
                </section>
            </section>

        </div>
    </div>


    <section class="l-line l-facebook l-line__membership">
        <img src="https://www.owndays.com/images/account/sg/main_image.webp" alt="" class="l-line__membership-bg">
        <div class="l-content--inner">
            <div class="l-line__membership-details">
                <ul class="l-line__membership-list">
                    <li>
                        <div class='l-line__membership-item'>
                            <img src="https://www.owndays.com/images/account/icon-point.svg" alt="">
                            <h5>Earn Points</h5>
                        </div>
                    </li>
                    <li>
                        <div class='l-line__membership-item'>
                            <img src="https://www.owndays.com/images/account/icon-refer.svg" alt="">
                            <h5>Referral Perks</h5>
                        </div>
                    </li>
                    <li>
                        <div class='l-line__membership-item'>
                            <img src="https://www.owndays.com/images/account/icon-rank.svg" alt="">
                            <h5>Level Up for Higher Points Rebates</h5>
                        </div>
                    </li>
                    <li>
                        <div class='l-line__membership-item'>
                            <img src="https://www.owndays.com/images/account/icon-function.svg" alt="">
                            <h5>Members-Only Benefits</h5>
                        </div>
                    </li>
                </ul>
                <div class="c-btn c-btn--blue--secondary">
                    <a href="/sg/en/account">More Details / Sign Up</a>
                </div>
            </div>
            <div class="l-line__membership-title">
                <h2>Exclusive Perks <br class="u-pc-only">& Convenience</h2>
                <p>OWNDAYS Membership<br>Programme</p>
                <div class="c-btn c-btn--blue--secondary">
                    <a href="/sg/en/account">More Details / Sign Up</a>
                </div>
            </div>
        </div>
    </section>
</section>

        </main>
            </div>

    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>


<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"></script>
<script>
    $('.l-line__function a[href^="#"]').click(function() {
        var speed = 700;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top + -150;
        $("html, body").animate({
            scrollTop: position
        }, speed, "swing");
        return false;
    });
</script>
<script>
    // $(function() {
    //     var hash = document.location.hash;
    //     var prefix = "tab-";
    //     if (hash) {
    //         $('#serviceTabs > li a[href=' + hash.replace(prefix, "") + ']').tab('show');
    //     }
    //     $('#serviceTabs a').on('shown.bs.tab', function(e) {
    //         window.location.hash = e.target.hash.replace("#", "#" + prefix);
    //     });
    // })


    // #(ハッシュ)指定されたタブを表示する
    var hash = document.location.hash;

    if (hash) {
        $('.nav-tabs a[href="' + hash + '"]').tab('show');
    }
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        e.target // newly activated tab
        e.relatedTarget // previous active tab
    });
</script>
</body>

</html>`;

const FacebookAccountHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        description="Breeze through the in-store registration process when you link your OWNDAYS account to Facebook. Access warranty cards and prescriptions on your mobile anytime."
        title="Official OWNDAYS Facebook Account｜OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default FacebookAccountHTML;
