import React, { useEffect } from 'react';
import './HTO.scss';
import { apiPath } from '../../../../config';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { connect } from 'react-redux';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const HTO = props => {
  const { dataLocale } = props;

  const {
    HTO_TITLE,
    HTO_DESCRIPTION,
    HTO_CTA,
    HTO_BENEFITS_TITLE,
    HTO_BENEFITS_CONVENIENT,
    HTO_BENEFITS_NO_WAITING,
    HTO_BENEFITS_FAST,
    HTO_STEPS_TITLE,
    HTO_STEPS_SELECT_STORE_TITLE,
    HTO_STEPS_SELECT_STORE_DESCRIPTION,
    HTO_STEPS_BOOKING_TITLE,
    HTO_STEPS_BOOKING_DESCRIPTION,
    HTO_STEPS_BOOKED_TITLE,
    HTO_STEPS_BOOKED_DESCRIPTION,
    HTO_STEPS_SIGN_IN_HINT,
  } = dataLocale;

  useEffect(() => {
    if (typeof window.clevertap !== 'undefined') {
      // OD__CLEVERTAP__EVENT (Page View)
      window.clevertap.event.push('Page View', {
        Page: 'book-eye-test',
        Title: 'Book an Eye Test | OWNDAYS ONLINE STORE - OPTICAL SHOP',
        URL: window.location.href,
      });

      // OD__CLEVERTAP__EVENT (Clicked on a Product Category)
      window.clevertap.event.push('Clicked on a Product Category', {
        Page: 'book-eye-test',
        Title: 'Book an Eye Test | OWNDAYS ONLINE STORE - OPTICAL SHOP',
        URL: window.location.href,
      });
    }
  }, []);

  return (
    <main className="hto">
      <MetaTagsManager
        canonical="/bookeyetest"
        description="Schedule an Eye Test at any of our OWNDAYS outlet located across Singapore. Uncover your visual acuity and explore eyewear solutions tailored to your needs."
        title="Book an Eye Test | OWNDAYS ONLINE STORE - OPTICAL SHOP"
      />
      <section className="hto-hero">
        <div className="hto-hero__layout">
          <img
            alt="HTO"
            className="hto-hero__image"
            src="https://static.lenskart.com/media/owndays/img/bookeyetest/main.webp"
          />
          <h1 className="hto-hero__title od-font-bold">{HTO_TITLE}</h1>
          <p className="hto-hero__description od-font-reg">{HTO_DESCRIPTION}</p>
          <ButtonOwnDays
            classes="hto-hero__button"
            fontClass="od-font-reg"
            fontSize="20px"
            mode="grey"
            px="120px"
            py="30px"
            onClick={() => {
              window.location.href = apiPath?.storeUrl;
            }}
          >
            {HTO_CTA}
          </ButtonOwnDays>
        </div>
      </section>

      <section className="hto-benefits">
        <div className="hto-benefits__layout">
          <h2 className="hto-benefits__title od-font-bold">{HTO_BENEFITS_TITLE}</h2>
          <div className="hto-benefits__list">
            <div className="hto-benefits__item">
              <img
                alt="HTO benefit"
                className="hto-benefits__item-image"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/HTO/hto-modern.webp"
              />
              <p className="hto-benefits__item-description od-font-reg">
                {HTO_BENEFITS_CONVENIENT}
              </p>
            </div>
            <div className="hto-benefits__item">
              <img
                alt="HTO benefit"
                className="hto-benefits__item-image"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/HTO/hto-no-lines.webp"
              />
              <p className="hto-benefits__item-description od-font-reg">
                {HTO_BENEFITS_NO_WAITING}
              </p>
            </div>
            <div className="hto-benefits__item">
              <img
                alt="HTO benefit"
                className="hto-benefits__item-image"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/HTO/hto-fast.webp"
              />
              <p className="hto-benefits__item-description od-font-reg">{HTO_BENEFITS_FAST}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="hto-steps">
        <div className="hto-steps__header">
          <div className="hto-steps__header-line" />
          <h2 className="hto-steps__title od-font-bold">{HTO_STEPS_TITLE}</h2>
          <div className="hto-steps__header-line" />
        </div>
        <div className="hto-steps__layout">
          <div className="hto-steps__list">
            <div className="hto-steps__item">
              <img
                alt="Select store"
                className="hto-steps__item-image"
                src="https://static.lenskart.com/media/owndays/img/bookeyetest/easy-booking1.webp"
              />
              <h3 className="hto-steps__item-title od-font-bold">{HTO_STEPS_SELECT_STORE_TITLE}</h3>
              <p className="hto-steps__item-description od-font-reg">
                {HTO_STEPS_SELECT_STORE_DESCRIPTION}
              </p>
            </div>
            <div className="hto-steps__item">
              <img
                alt="Enter booking details"
                className="hto-steps__item-image"
                src="https://static.lenskart.com/media/owndays/img/bookeyetest/easy-booking2.webp"
              />
              <h3 className="hto-steps__item-title od-font-bold">{HTO_STEPS_BOOKING_TITLE}</h3>
              <p className="hto-steps__item-description od-font-reg">
                {HTO_STEPS_BOOKING_DESCRIPTION}
              </p>
            </div>
            <div className="hto-steps__item">
              <img
                alt="Booking completed"
                className="hto-steps__item-image"
                src="https://static.lenskart.com/media/owndays/img/bookeyetest/easy-booking3.webp"
              />
              <h3 className="hto-steps__item-title od-font-bold">{HTO_STEPS_BOOKED_TITLE}</h3>
              <p className="hto-steps__item-description od-font-reg">
                {HTO_STEPS_BOOKED_DESCRIPTION}
              </p>
            </div>
          </div>
          <ButtonOwnDays
            classes="hto-steps__button"
            fontClass="od-font-reg"
            fontSize="20px"
            mode="grey"
            px="120px"
            py="30px"
            onClick={() => {
              window.location.href = apiPath?.storeUrl;
            }}
          >
            {HTO_CTA}
          </ButtonOwnDays>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HTO);
