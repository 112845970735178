import React, { Fragment } from 'react';

import './BodyEyeCamp.scss';

export const BodyEyeCamp = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="body-eye-camp__message base-eye-camp__container--inner">
        <div className="base-eye-camp__row">
          <div className="base-eye-camp__col body-eye-camp__message--logo">
            <div>
              <img alt="logo owndays" src={`${baseImgUrl}/logo_owndays.svg`} />
              <h2>
                For all we will encounter <br className="base-eye-camp__hide--sp" />
                in the future
                <span>Sharing the vision with the world.</span>
              </h2>
            </div>
          </div>
          <div className="base-eye-camp__col body-eye-camp__message--text">
            <h3>
              We at OWNDAYS have a dream – “To enrich the life of everyone who has a relationship
              with OWNDAYS.”
            </h3>
            <p>
              It is believed that about four billion people in the world need visual correction.
              However, half of them are unable to get their vision corrected due to the lack of eye
              care facilities as well as financial difficulties.
              <br />
              <br />
              It saddens us to learn that so many people have to endure the inconveniences and
              agonies caused by poor vision so we begin pondering what we can do for them.
              <br />
              <br />
              It is, of course, impossible to change the world or help every single person in need.
              <br />
              <br />
              However, we know that there would be no improvement to the situation if we do nothing.
              <br />
              <br />
              Through OWNDAYS glasses, we hope to bring people a clearer and a more beautiful vision
              before their eyes.
            </p>
          </div>
        </div>
      </section>

      <section className="body-eye-camp__eye-camp" id="eye-camp">
        <div className="base-eye-camp__bg">
          <picture>
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/csr_sub-pc.webp`} />
            <img alt="" src={`${baseImgUrl}/csr_sub-sp.webp`} />
          </picture>
        </div>

        <div className="body-eye-camp__eye-camp__title">
          <h3>
            OWNDAYS
            <br />
            Eye
            <br />
            Camp
            <span>Sharing the vision with the world.</span>
          </h3>
        </div>
      </section>

      <section className="body-eye-camp__message base-eye-camp__container--inner">
        <div className="base-eye-camp__row">
          <div className="base-eye-camp__col body-eye-camp__message--text body-eye-camp__eye-camp__message">
            <h3>
              In OWNDAYS, part of our profits goes towards giving{' '}
              <br className="base-eye-camp__hide--sp" />
              out free glasses to people in developing countries.
            </h3>
            <p>
              The OWNDAYS Eye Camp is conducted every 3 months in partnership with local
              non-governmental organizations in developing countries. Volunteer doctors conduct eye
              checks and carry out eye health evaluations to screen for signs of eye diseases.
              <br />
              <br />
              OWNDAYS then makes the glasses using the prescriptions obtained from the eye checks.
              Not only that, OWNDAYS staff even help the recipients with the adjustment of the
              glasses to ensure the glasses sit well on their faces.
            </p>
          </div>

          <div className="base-eye-camp__col body-eye-camp__message--image">
            <img alt="OWNDAYS EYE CAMP" src={`${baseImgUrl}/photo_campflag.webp`} />
          </div>
        </div>
      </section>
    </Fragment>
  );
};
