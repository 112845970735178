// Breadcrumbs.jsx
import React from 'react';
import { pathLocale } from '../../../utils/path';

import './Breadcrumbs.scss'; // optional, for custom styling

const Breadcrumbs = ({ crumbs }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb--2">
        {crumbs.map((crumb, index) => (
          <li
            key={index}
            aria-current={index === crumbs.length - 1 ? 'page' : undefined}
            className={`breadcrumb-item ${index === crumbs.length - 1 ? 'active' : ''}`}
          >
            {index < crumbs.length - 1 && crumb.path !== '#' ? (
              <a href={crumb.path === '/' ? pathLocale : crumb.path}>{crumb.label}</a>
            ) : (
              crumb.label
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
