import React, { Fragment, useEffect, useState } from 'react';

import { RECOMMENDED } from './constants';

import './FooterAir.scss';

export const FooterAir = ({ baseImgUrl }) => {
  const [position, setPosition] = useState('fixed');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.querySelector('.base-air')?.offsetHeight || 0;

      if (scrollTop + windowHeight >= documentHeight) {
        setPosition('static');
      } else {
        setPosition('fixed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <section className="footer-air__recommended" id="recommended">
        <div className="base-air__container">
          <h2>Optional Lenses</h2>
          <ul>
            {RECOMMENDED.map(({ src, title, desc, link }) => (
              <li key={title}>
                <img alt={title} src={`${baseImgUrl}/recommended${src}`} />
                <h3>{title}</h3>
                <p>{desc}</p>
                <a href={link}>More Details</a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="footer-air__navs-sticky">
        <div
          className="footer-air__navs-sticky__inner"
          style={{
            position,
          }}
        >
          <div className="base-air__container">
            <div className="footer-air__navs-sticky__actions">
              <div className="base-air__hide--pc">
                <a
                  className="base-air__btn base-air__btn--primary"
                  href="/sg/en/eyeglasses/brands/air.html"
                >
                  See All AIR Frames
                </a>
              </div>

              <div className="base-air__hide--sp">
                <a
                  className="base-air__btn base-air__btn--primary"
                  href="/sg/en/eyeglasses/brands/air.html"
                >
                  See All AIR Frames
                </a>
              </div>

              <a className="base-air__btn base-air__btn--secondary" href="/stores/sg">
                Search For <br className="base-air__hide--pc" />
                Nearest Stores
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
