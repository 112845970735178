import React from 'react';

const NewsItem = ({ item, index, visibleItems }) => {
  const { slug, title, image, category } = item;

  return (
    <li
      key={index}
      style={{
        opacity: visibleItems.includes(index) ? 1 : 0,
        transform: visibleItems.includes(index) ? 'translateY(0)' : 'translateY(40px)',
        transition: `opacity 0.6s ease-out, transform 0.6s ease-out`,
        transitionDelay: `${index * 0.2}s`,
      }}
    >
      <a href={slug}>
        <figure>
          <div className="blog-news__list-thumbnail">
            <img alt={title} loading="lazy" src={image} />
          </div>
          <figcaption>
            <h4 className="blog-news__list-category">{category}</h4>
            <p className="blog-news__list-title">{title}</p>
            <p className="blog-news__list-view-more">VIEW MORE</p>
          </figcaption>
        </figure>
      </a>
    </li>
  );
};

export default NewsItem;
