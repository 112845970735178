import React, { Fragment } from 'react';

const pClassName = 'footer-eye-camp__history-detail__message__text';

const renderOpinion = (icon, name, flag, role, desc) => {
  return {
    icon,
    name,
    flag,
    role,
    desc,
  };
};

export const HISTORY = [
  {
    id: '202408',
    tag: '#202408',
    title: {
      date: '2024.08',
      country: 'PHILIPPINES',
      city: 'Marikina',
    },
    isMoreDetail: true,
    detail: {
      date: '2024.08.16',
      message: (
        <Fragment>
          <p className={pClassName}>
            A second Eye Camp was held in Marikina City, Philippines in 2024. Originally planned as
            an annual event, it was brought forward this year due to earnest appeal from the local
            charity organisations.
          </p>
          <p className={pClassName}>
            The City of Marikina, with a population of approximately 456,000, is located in the
            National Capital Region of the Philippines and is famous for its shoemaking industry.
            Widely known as the “Shoe Capital of the Philippines”, many local brands proudly produce
            shoes that are labelled “Made in Marikina”. The city takes great pride in its shoemaking
            craftsmanship and history and is home to the Philippine International Footwear Centre
            and the Shoe Museum. Despite the city’s economic success, poverty still persists in some
            areas.
          </p>
          <p className={pClassName}>
            According to 2012 data, 13.4% of households have income below the poverty line and 5.6%
            are earning below the food poverty line. In this Eye Camp, we focused specifically on
            215 individuals who are living below the food poverty line. For many, it was their first
            time wearing spectacles and it left them with a renewed sense of hope, knowing their
            daily lives would improve with clearer vision. Their joy and gratitude were beyond what
            words could describe.
          </p>
          <p className={pClassName}>
            We hope that these corporate social responsibility initiatives will continue to deepen
            our ties with the local community and help to improve the quality of life even in small
            ways, beyond just providing spectacles. OWNDAYS will continue to contribute to the needs
            of the community.
          </p>
        </Fragment>
      ),
      opinions: [
        renderOpinion(
          'icon_Ronel.webp',
          'Ronel',
          'ph',
          'Optician',
          'I was selected to represent my store in this Eye Camp. Being chosen by my colleagues demonstrated their trust in my abilities and it gave me a great sense of motivation. It was incredibly rewarding seeing the joy and gratitude on the faces of people who received spectacles that fitted perfectly and improved their vision. This experience helped me reaffirm the importance of our role in providing essential eye care services and the responsibilities I have in my work. I’m grateful for this opportunity and I look forward to taking part in more events where we can continue to make a positive impact.'
        ),
        renderOpinion(
          'icon_Dyan.webp',
          'Dyan',
          'ph',
          'Optician',
          'This Eye Camp gave me the opportunity to connect with people from diverse backgrounds and hear their personal stories and challenges. This direct interaction not only highlighted the value of our efforts but also deepened my understanding of the positive impact of corporate social responsibility. Through this Eye Camp, I felt a strong desire to continue contributing to society in different ways. It has also motivated me to further improve my skills so I can provide better eye care services. Thank you very much!'
        ),
        renderOpinion(
          'icon_Andrea.webp',
          'Andrea',
          'ph',
          'Marketing Officer',
          'While promoting the brand is undoubtedly one of the primary roles of the marketing team, I believe that our commitment to providing clearer vision and supporting the community to improve people’s lives are equally important. It was my first time participating in this initiative and witnessing it firsthand was truly eye-opening, not only as a professional but also as an individual. There were elderly people who hadn’t had an eye exam in years and children who lit up with joy when they could read the letters on the eye chart for the first time. Experiencing moments like these reminded me that our spectacles are truly “life-enhancing tools”. This initiative isn’t just about distributing spectacles; it’s about giving the gift of vision, something many of us take for granted.'
        ),
      ],
    },
  },
  {
    id: '202405',
    tag: '#202405',
    title: {
      date: '2024.05',
      country: 'PHILIPPINES',
      city: 'Sambag II',
    },
    isMoreDetail: true,
    detail: {
      date: '2024.05.15',
      message: (
        <Fragment>
          <p className={pClassName}>
            On 15 May 2024, OWNDAYS held its CSR initiative, the OWNDAYS Eye Camp, in Sambag II,
            Cebu, Philippines. During the event, the company distributed free spectacles to
            approximately 200 underprivileged residents.
          </p>
          <p className={pClassName}>
            Located in Cebu City, Sambag II is home to about 12,000 residents. The people of Sambag
            primarily speak Cebuano and are composed of various ethnic groups, including Visayan,
            Tagalog and other indigenous peoples. The area is known for its rich history, vibrant
            culture and strong community spirit. However, about 80% of the residents live below the
            poverty line. This situation has worsened due to the COVID-19 pandemic from 2020 to 2023
            and Super Typhoon Odette in December 2021. As a result, obtaining food and basic
            necessities has become difficult, leaving little to no means to afford spectacles. In
            addition, many residents are not aware of their vision problems.
          </p>
          <p className={pClassName}>
            The OWNDAYS staff travelled to Sambag II to personally deliver spectacles made for about
            200 residents. For many, it was their first time wearing spectacles and their faces were
            filled with surprise and joy. In particular, many were looking forward to an improved
            quality of life and work with clearer vision. Despite the language barrier, the crew
            members were happy being able to communicate with the Cebuano-speaking residents with
            the help of an interpreter.
          </p>
          <p className={pClassName}>
            It was a meaningful opportunity for us to gain a deeper understand of the significance
            of giving back to society.
          </p>
        </Fragment>
      ),
      opinions: [
        renderOpinion(
          'icon_Nagao.webp',
          'Nagao',
          'ph',
          'Regeonal Manager',
          'The local people were incredibly warm and their kindness deeply touched my heart. However, the contrast in living conditions compared to Japan was immediately noticeable. Unpaved roads, simple housing, limited infrastructure and unpleasant odours were prevalent. Exposed power lines were strung around like spider webs, water had to be drawn by hoses and unsanitary conditions were widespread. Despite these challenges, the people enjoy their daily lives and help each other and this has left a lasting impression on me. A particularly memorable moment for me was when an elderly woman, who had poor eyesight since childhood, put on a proper pair of spectacles for the first time. I will never forget her initial expression of astonishment and confusion which soon turned into great joy at being able to finally enjoy clear vision. After the distribution, I walked around the venue. Seeing everyone with their spectacles, smiling and saying “thank you” was such an invaluable experience that can never be replaced.'
        ),
        renderOpinion(
          'icon_Nina.webp',
          'Nina',
          'tw',
          'Optical Technician',
          'Seeing the joy and satisfaction in the people’s eyes as they experienced clear vision for the first time with the spectacles we distributed, I realised just how significant a single pair of spectacles can be in someone’s life. Taking part in this Eye Camp not only brought me immense joy in helping others but also deepened my appreciation for the importance of eye health. When I return to Taiwan, I hope to share this moving experience with more people so that everyone can understand the positive impact of our efforts. I truly believe that if all of us come together, we can make a huge difference in the world. I will continue to participate in OWNDAYS Eye Camp and support those in need moving forward.'
        ),
        renderOpinion(
          'icon_Jammie.webp',
          'Jammie',
          'ph',
          'Marketing Manager',
          'Many of the recipients face poor living conditions and hardships, including losing their homes in a fire last April. Despite their severe difficulties, they welcomed us warmly. I could sense a strong resilience in their community despite the challenges they face. One of the most heartwarming moments was seeing how spectacles could instantly change someone’s life. There was an elderly person whose face lit up with joy when he could finally see clearly with his new spectacles. He mentioned that he could not afford to buy new spectacles or have regular eye tests. This experience made me appreciate the significance of OWNDAYS Eye Camp even more.'
        ),
        renderOpinion(
          'icon_Hiramoto.webp',
          'Hiramoto',
          'jp',
          'Sales Staff',
          'As I walked through the town, I saw power lines hanging down and poorly maintained roads. Initially, I thought the local people were living in hardship but seeing the situation firsthand changed my perception drastically. I was shocked by how different the living conditions were compared to Japan. What surprised me the most was seeing the children running around energetically and the local people being so welcoming and smiling all the time. Even though we couldn’t communicate verbally, I could still feel their warmth and energy. Their radiant smiles after putting on the spectacles and experiencing clear vision for the first time in their lives filled me with a great sense of pride in the work we do.'
        ),
        renderOpinion(
          'icon_Bennett.webp',
          'Bennett',
          'ph',
          'Assistant Regional Manager',
          'I was previously involved in the Eye Camp in Myanmar, helping with the eye tests so I was delighted that I could participate in distributing the spectacles this time. In Cebu, very few Filipinos speak English. Most of them responded with a smile or a thumbs-up and we forged a bond through high-fives and fist-pumps. The weather was hot that day and the local people even fanned me when they saw me perspiring. It was such a heartwarming gesture.'
        ),
        renderOpinion(
          'icon_Tabata.webp',
          'Tabata',
          'jp',
          'Sales Assistant',
          'It seemed that many houses were crammed into narrow alleys, with everyone living in densely packed quarters. The smell was a little strong, probably due to wastewater flowing through the alleys. Nevertheless, the local people greeted outsiders like us with big smiles. The children ran around and played in the alleys. Unlike in Japan where most people live more independently, it appears that people there support and help each other. I think it is important to first recognise that this way of life exists. Instead of solely relying on information on social media or the internet, it is crucial to see and experience it firsthand.'
        ),
      ],
      vdo: 'https://www.youtube.com/embed/fCeJGVBw1ag',
    },
  },
  {
    id: '202403',
    tag: '#202403',
    title: {
      date: '2024.03',
      country: 'SINGAPORE',
      city: 'Kampung AWWA & AWWA School @ Napiri',
    },
    isMoreDetail: true,
    detail: {
      date: '2024.03.12-2024.03.14',
      message: (
        <Fragment>
          <p className={pClassName}>
            The OWNDAYS Eye Camp is a key Corporate Social Responsibility programme that highlights
            our commitment to sharing vision with the world. In March 2024, an OWNDAYS Eye Camp was
            held in Singapore in partnership with AWWA Ltd (“AWWA”). AWWA was founded in 1970 by a
            group of volunteers supporting low-income families, and has since evolved into one of
            Singapore's largest multi-service social service agencies. AWWA’s multi-professional
            team supports the integration and inclusion of children with developmental needs,
            families with complex social issues, the elderly, and persons with additional needs so
            that they may live out their various dreams and aspirations.
          </p>
          <p className={pClassName}>
            Held at Kampung AWWA and AWWA School @ Napiri across a span of 3 days from 12 March to
            14 March 2024, we set up professional eye test stations at the venues and deployed a
            crew of 20 staff volunteers to conduct eye tests for a total of 211 persons with
            additional needs, seniors, and families in need. The crew consisted of staff from the
            Singapore headquarters as well as opticians and retail staff from the various stores.
            With the eye test records, we then returned to distribute the prescription glasses. Over
            180 pairs of prescription glasses were distributed at this Eye Camp.
          </p>
          <p className={pClassName}>
            As the recipients put on their new spectacles, their faces erupted in bright, wide
            smiles. The ability to see with good clarity not only filled their hearts with happiness
            but also brought immense satisfaction to the staff volunteers.
          </p>
          <p className={pClassName}>
            Witnessing their delight was profoundly heartwarming and has deeply inspired us to
            continue our mission of bringing the gift of sight to the underprivileged communities
            around the world.
          </p>
        </Fragment>
      ),
      opinions: [
        renderOpinion(
          'icon_Canny.webp',
          'Canny',
          'sg',
          'Senior Optician Trainer',
          'The goal of this Eye Camp was to help the elderly and children with special needs see the world more clearly by providing them free spectacles. I am honoured to be a part of this Eye Camp. It has made me realise that there are indeed many people in the society that need help, especially the elderly in wheelchairs and those with hearing impairments. When the recipients received their spectacles, they thanked us by saying, “Thank you OWNDAYS”, “Thank you for your patience” and “Thank you. You are so kind”. Hearing these words deeply touched me. Kindness is truly a beautiful thing.'
        ),
        renderOpinion(
          'icon_Derek.webp',
          'Derek',
          'sg',
          'Area Manager',
          'The Eye Camp left me with a profound sense of gratitude and fulfilment. Seeing the smiles on the faces of the recipients was truly heartwarming. It was particularly moving to witness the reactions of those who had never worn spectacles before. Their look of joy and amazement made every effort worth it. It also served as a reminder of why we do what we do. The Eye Camp is not just about giving out spectacles. It is about making a genuine difference to people’s lives. I would definitely love to be a part of the Eye Camp again.'
        ),
        renderOpinion(
          'icon_Shawn.webp',
          'Shawn',
          'sg',
          'Shop Manager',
          'I was very excited when I first heard that we would be organising an Eye Camp in Singapore and I raised my hand for it right away. During the Eye Camp, I was responsible for conducting eye tests and providing spectacles to children with special needs. For most people, getting spectacles is easy. However, these children might not even know what good vision should be. Patience and quick thinking were essential as we worked to build trust and perform the necessary eye tests. One particular moment that stood out to me was when a young girl cried non stop as she was too scared. Her brother then contacted their mother at work and after a comforting conversation, the girl finally agreed to go through with the eye test. This was one of the most rewarding experiences of my career. Helping these children see clearly was a true privilege and I hope they can now experience life differently and truly feel empowered to “OWN ‘their’ DAYS”!'
        ),
        renderOpinion(
          'icon_Erick.webp',
          'Erick',
          'sg',
          'Shop Manager',
          'Participating in the Eye Camp was an incredibly fulfilling experience! It was truly rewarding being able to provide eye tests and spectacles to students and families in need. Seeing the joy and relief on their faces when they received their new spectacles was priceless. It is amazing to think that something as simple as a pair of spectacles can make such a significant difference in someone’s life. This Eye Camp has reinforced the importance of vision care and reminded me of the impact we can have when we come together for a common cause. I am grateful to have been a part of it and I look forward to future opportunities to contribute in such meaningful ways.'
        ),
        renderOpinion(
          'icon_Jesphine.webp',
          'Jesphine',
          'sg',
          'Eyewear Ambassador',
          'This was my first time participating in Eye Camp and I think it was a very meaningful experience that had definitely made me feel the great significance of helping others. Besides helping those in need gain clearer vision, our efforts would also go a long way in enabling the recipients to work and live more confidently. I believe that even small actions can have huge impacts and helping others is a powerful way to contribute to a more equitable society.'
        ),
      ],
    },
  },
  {
    id: '202312',
    tag: '#202312',
    title: {
      date: '2023.12',
      country: 'NEPAL',
      city: 'Baglung',
    },
    isMoreDetail: true,
    detail: {
      date: '2023.12.06',
      message: (
        <Fragment>
          <p className={pClassName}>
            The OWNDAYS Eye Camp this time was held at Galkot, a municipality in Baglung District of
            Nepal.
          </p>
          <p className={pClassName}>
            Baglung District is one of the 77 districts in Nepal. It is approximately 5 hours’ drive
            away from the city of Pokhara, through steep mountainous roads. Due to such geographic
            constraints, access to medical care is extremely difficult for people living in Baglung.
            They have to travel through mountainous roads by car for 2 hours to get spectacles at a
            nearby town. After eye test, it would take up to 20 days before their spectacles are
            ready for collection. Since commuting around town is harder for children and the
            elderly, vision correction naturally takes a back seat in terms of priority.
          </p>
          <p className={pClassName}>
            This Eye Camp, OWNDAYS made about 320 pairs of spectacles and the staff headed on-site
            to personally hand the spectacles over to the recipients. This was the first time since
            the Covid-19 pandemic that the staff were able to visit the venue in person to do
            spectacle fitting for the recipients. Nepali is the native language widely spoken in the
            Baglung area. Driven by their desire to give back to their home country through the
            skills and knowledge they have acquired at OWNDAYS, 2 staff members of Nepalese origin
            participated in the Eye Camp, taking the lead in strengthening ties with the local
            community.
          </p>
          <p className={pClassName}>
            Among those who received the spectacles, some were bewildered as it was their first time
            putting on spectacles, while others accepted the spectacles delightfully. Despite being
            just a short stay, it was a priceless time spent together.
          </p>
        </Fragment>
      ),
      opinions: [
        renderOpinion(
          'icon_Milan.jpg',
          'Milan',
          'jp',
          'Store Manager',
          'I participated in the Eye Camp at India right after joining OWNDAYS. That was the catalyst that inspired me to propose and plan for an Eye Camp in my home country of Nepal which is now the fifth Eye Camp held there. I would like to express my gratitude to the volunteer staff who travelled to such remote places to deliver spectacles and to OWNDAYS for making this possible.'
        ),
        renderOpinion(
          'icon_Prakash.jpg',
          'Prakash',
          'jp',
          'Sales Staff',
          'I believe making others happy is also a way to make oneself happy. I joined this Eye Camp because I wanted to contribute something to my home country. When I was handing out the spectacles, an elderly man in his 70s told me that he could now see like he did when he was 30 years old thanks to the spectacles. The smiles and words of gratitude are memories I will never forget. I will continue to do my best with the valuable experience I gained through Eye Camp and the new-found respect for the local community.'
        ),
        renderOpinion(
          'icon_Nagai.jpg',
          'Nagai',
          'jp',
          'AI Development Manager',
          'I have been involved in volunteer activities in Africa with the Japan Overseas Cooperation Volunteers so Eye Camp was one of the reasons I decided to join OWNDAYS. Despite being in poverty, people there have a cheerful vibe and that really stuck with me. If you are struggling in life right now, I would encourage you to join Eye Camp. I hope all the staff of OWNDAYS would come to know that the company they are working for is doing such meaningful activities.'
        ),
        renderOpinion(
          'icon_Sasahara.jpg',
          'Sasahara',
          'jp',
          'Sales Staff',
          'During the trip, we saw the local people performing cremation. The deceased was burnt near a river and the ashes being thrown into the water. As someone who has only been exposed to Japanese funeral culture, it was such a shocking sight I could not even describe the feeling in words. Experiencing a different culture has made me realise that what we see as normal may not be the same in other countries. I also happened to spend my birthday during the Eye Camp. It gave me the opportunity to understand the differences in cultures and the reality of life and death. This has made me appreciate things that I have taken for granted and to cherish my daily life from now on.'
        ),
        renderOpinion(
          'icon_Greta.jpg',
          'Greta',
          'tw',
          'Store Manager',
          'I’m a staff from OWNDAYS Taiwan and I’m the only one from the Taiwan team in this Eye Camp. After travelling through bumpy mountainous roads, we found ourselves in a remote area with underdeveloped transportation and infrastructure. Despite this, the people there embrace life with bright smiles. This was something I had never imagined seeing. What struck me the most was that even though we speak different languages, we were able to communicate through gestures. I was showered with many smiles, compliments and heartfelt hugs and that made the experience truly unforgettable!'
        ),
      ],
      vdo: 'https://www.youtube.com/embed/r3tb85k71fk',
    },
  },
  {
    id: '202212',
    tag: '#202212',
    title: {
      date: '2022.12',
      country: 'IRAQ',
      city: 'Tobzawa',
    },
    isMoreDetail: true,
    detail: {
      date: '2022.12.20',
      message: (
        <Fragment>
          <p className={pClassName}>
            As with the year before, OWNDAYS Eye Camp was held in Iraq in partnership with NPO IVY.
          </p>
          <p className={pClassName}>
            The school we visited this time was in Tobzawa Village, one of the most impoverished
            areas in Erbil that is approximately 36km away from the city centre. The residents there
            is a mix of Arab and Kurdish people. There is no hospital in the village and essential
            services such as water and electricity are also lacking. Since public transport in Iraq
            and KRG is underdeveloped, residents need to travel by taxi, the most expensive mode of
            transportation, to the city centre to access medical facilities.
            <br />
            Due to the Covid-19 pandemic, OWNDAYS staff were unable to travel to the venue in person
            so the spectacles were entrusted to the local IVY staff.
          </p>
          <p className={pClassName}>
            Due to its circumstance, wearing spectacles is not a common practice in Iraq even for
            those with poor eyesight. As a result, many children were puzzled when they put on
            spectacles for the first time. A total of 208 pairs of spectacles were handed out this
            Eye Camp and the experience had certainly created a positive impact on the children’s
            education.
          </p>
          <p className={pClassName}>
            Moving forward, we will continue to expand the scope of activities conducted within the
            framework of the OWNDAYS Eye Camp.
          </p>
        </Fragment>
      ),
    },
  },
  {
    id: '202110',
    tag: '#202110',
    title: {
      date: '2021.10',
      country: 'IRAQ',
      city: 'Kaiyara',
    },
    isMoreDetail: true,
    detail: {
      date: '2021.10.29',
      message: (
        <Fragment>
          <p className={pClassName}>
            The latest OWNDAYS Eye Camp was held in the town of Kaiyara in Iraq, with cooperation
            from the NPO IVY. This is the first OWNDAYS Eye Camp ever hosted in the Middle East.
            <br />
            As was the case last year, the impact from the spread of the new coronavirus infectious
            disease prevented OWNDAYS personnel from making the trip overseas. Instead, the
            eyeglasses prepared for distribution were consigned to IVY staff members.
          </p>
          <p className={pClassName}>
            Kaiyara Town in Mosul City was occupied by the Islamic State in Iraq (ISIL) through
            2016. In the subsequent operation to recapture the region, a large number of local
            public facilities were destroyed. <br />
            The school building of Mashriq Elementary School, utilized as the site for this Eye
            Camp, was destroyed by ISIL during the occupation. After this area was liberated, the
            school was rebuilt with solicited donations.
          </p>
          <p className={pClassName}>
            Our program here was organized to conduct eyesight tests and distribute eyeglasses to
            local children and their guardians, elderly persons and others living in the neighboring
            area.
            <br />
            For example, children forced to wear broken eyeglasses because they are unable to
            purchase new ones. Children unable to obtain any eyeglasses up to now. Senior citizens
            continuing to use reading glasses not fitting their own visual needs.
            <br />
            Every recipient of new eyeglasses could be seen smiling at the joy of seeing clearly
            again. This was the first time that overseas products had been delivered to this town
            following its liberation. That further increased the joy of the participants.
          </p>
          <p className={pClassName}>
            Going forward, OWNDAYS has determined to advance it business to Middle Eastern
            countries.
            <br />
            That will be accompanied with ongoing expansion of new activities, with OWNDAYS Eye Camp
            programs in the Middle East continued to be organized.
          </p>
        </Fragment>
      ),
    },
  },
  {
    id: '202011',
    tag: '#202011',
    title: {
      date: '2020.11',
      country: 'MYANMAR',
      city: 'Pin Laung,Pin Pgyit,Yauk Sauk',
    },
    isMoreDetail: true,
    detail: {
      date: '2020.10.29-11.18',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 19th OWNDAYS Eye Camp was held in two regions of Shan State in Myanmar – Pin Laung
            and Yauk Sauk.
          </p>
          <p className={pClassName}>
            In organizing these activities, the impact of the spread of the new coronavirus
            infectious disease was a major factor. The situation made it extremely difficult to
            conduct the traditional approach of OWNDAYS staff members from various countries
            gathering at local sites to distribute the eyeglasses. Instead, the 448 eyeglasses
            produced for this occasion were presented by local doctors and charity group workers,
            following thorough infection control measures.
          </p>
          <p className={pClassName}>
            In Pin Laung, about four and a half hours by car from Taunggyi City (the base of our
            operation), eyeglasses were distributed in the three villages of Yimi, Pinglun and
            Tigyit. Under administrative instructions, the program was spread over five days, with
            no more than 30 persons allowed to gather at once. In Yauk Sauk, about a two-hour drive
            from Taunggyi, distribution took place in three locations –Pin Pgyit and Yadanar Pone
            villages, and Magin Monastery.
          </p>
          <p className={pClassName}>
            Though the impact of the pandemic has created unprecedented conditions over the past
            year, it is exactly at such times that cooperation takes on greater importance. Our aim
            is to bring smiles to the faces of as many people in Myanmar as possible, while
            conveying our appreciation to the entire local population. We likewise look forward to
            the recovery of security and a democratic political system for all Myanmar citizens at
            the earliest possible time.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/_ef9e71NrqI',
    },
  },
  {
    id: '201912',
    tag: '#201912',
    title: {
      date: '2019.12',
      country: 'PHILIPPINES',
      city: 'Sapang Bato, Pampanga',
    },
    isMoreDetail: true,
    detail: {
      date: '2019.12.25',
      message: (
        <Fragment>
          <p className={pClassName}>
            OWNDAYS Eye Camp is a way of giving back to our fellow Filipinos. Last December 25,
            2019, we had our 5th Eye Camp in the Philippines – aiming to help our less fortunate
            kababayans from Pampanga and shared with them the gift of sight with the help of
            Apl.de.ap’s team.
          </p>
          <p className={pClassName}>
            OWNDAYS Volunteers, including OWNDAYS CEO, Shuji Tanaka, celebrated their Christmas with
            the families from Sapang Bato, Pampanga, Philippines. There was a total of 863
            participants of the Christmas Party and a total of 150 pairs of glasses were given to
            those who needed them. The children who attended were also given a total of 1,000 school
            supplies to support their education. Participants and the volunteers enjoyed the
            Christmas Party that was held at the residence of Mr. Apl.de.ap.
          </p>
          <p className={pClassName}>
            As we conclude the year 2019, OWNDAYS assures everyone that we will continue to dedicate
            ourselves to sharing the gift of good vision to those in need through Eye Camp.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/iMtj8K82OLo',
    },
  },
  {
    id: '201911',
    tag: '#201911',
    title: {
      date: '2019.11',
      country: 'MYANMAR',
      city: 'Pekon, Shan',
    },
    isMoreDetail: true,
    detail: {
      date: '2019.11.09 & 2019.11.11',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 18th OWNDAYS Eye Camp was held in Pekon, a town in Shan State, Myanmar.
          </p>
          <p className={pClassName}>
            People in Pekon have limited access to healthcare facilities as it is difficult getting
            to the cities where the main medical institutions are situated. Despite this, Pekon
            rarely receives relief from charities so the people there were delighted when we visited
            them for the OWNDAYS Eye Camp.
          </p>
          <p className={pClassName}>
            This Eye Camp, we distributed approximately 400 pairs of glasses to people of all ages,
            from children to the elderly. The glasses were fitted with prescription lenses catered
            to each recipient’s needs and there were both reading glasses and those for viewing far
            distances. One by one, the Eye Camp crew carefully adjusted the frame fitting for the
            recipients and checked to ensure their vision was clear and comfortable.
          </p>
          <p className={pClassName}>
            Similar to previous ones, this OWNDAYS Eye Camp was made possible as a result of the
            combined effort from various parties. This included our customers around the world whose
            purchase constituted a portion of the funding for the project, the committee members who
            liaised with local doctors and schools, the local doctors who conducted eye examination
            and provided us with the prescription details and finally the OWNDAYS staff in Japan who
            had taken great care in putting the glasses together. As to the Eye Camp crew members,
            we completed the final step of handing over the glasses to the beneficiaries. Beyond
            being visual aids, these glasses embodied the thoughts of everyone involved in the
            project, their well-wishes to the needy people of Pekon and our aspiration of making the
            world look more beautiful through improved vision.
          </p>
          <p className={pClassName}>
            This OWNDAYS Eye Camp gave us an opportunity to establish a closer bond with the people
            in Myanmar. Moving forward, we will continue to bring our glasses to those in need so
            they can experience the joy of having brighter, clearer vision.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/th5yDomvP3Y',
    },
  },
  {
    id: '201908',
    tag: '#201908',
    title: {
      date: '2019.08',
      country: 'PHILIPPINES',
      city: 'Tondo, Manila',
    },
    isMoreDetail: true,
    detail: {
      date: '2019.08.07',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 2019 OWNDAYS Eye Camp in the Philippines was organised in collaboration with Lions
            Clubs International, a non-profit service organization. This time, the Eye Camp was held
            in Tondo, Manila, one of the most densely populated places in the Philippines where
            people often face difficulties in accessing adequate supply of water, housing and proper
            sanitation.
            <br />
            Besides, the provision of education and health services as well as employment
            opportunities are also lacking in the area.
          </p>
          <p className={pClassName}>
            This Eye Camp, we gave out over 200 pairs of prescription glasses to individuals who are
            troubled by poor vision but are unable to afford glasses. In addition, we donated school
            supplies, schoolbags, shoes, toys and snacks to the local children.
          </p>
          <p className={pClassName}>
            We hope our effort through the OWNDAYS Eye Camp could help to brighten up people’s lives
            and create a positive impact in the society. In line with our philosophy of “enriching
            the lives of people associated with OWNDAYS”, we will continue to dedicate ourselves to
            helping the less fortunate and to sharing the vision with the world through OWNDAYS Eye
            Camp.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/HFo_QOe9Dd8',
    },
  },
  {
    id: '201907',
    tag: '#201907',
    title: {
      date: '2019.07',
      country: 'THAILAND',
      city: 'Huai Sai, Chianmai',
    },
    isMoreDetail: true,
    detail: {
      date: '2019.07.03',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 17th OWNDAYS Eye Camp took place in Thailand. It was the first Eye Camp in the
            country.
          </p>
          <p className={pClassName}>
            The venue, Kalyanamitsuk Centre, is a rehabilitation centre located in a sub-district of
            Huai Sai, Chiang Mai. It provides physical therapy for the elderlies and people with
            disabilities from all over Chiang Mai.
          </p>
          <p className={pClassName}>
            We distributed reading glasses and helped the centre residents with cleaning,
            maintenance and proper fitting of their glasses. As the Buddhist Lent Day was
            approaching, we also brought along Buddhists scriptures for them.
          </p>
          <p className={pClassName}>
            The joy of having a good, clear vision is beyond words can describe. Through the OWNDAYS
            Eye Camp, we have come to recognise the importance of helping people around the world
            improve their vision so as to enrich their lives.
          </p>
          <p className={pClassName}>
            To this end, we have dedicated a portion of our profits towards helping the less
            privileged through the OWNDAYS Eye Camp. We will continue our efforts in sharing vision
            with the world with OWNDAYS glasses.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/kx_KAs6X4pQ',
    },
  },
  {
    id: '201902',
    tag: '#201902',
    title: {
      date: '2019.02',
      country: 'MYANMAR',
      city: 'Inle, Taunggyi',
    },
    isMoreDetail: true,
    detail: {
      date: '2019.02.28 & 2019.03.01',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 16th OWNDAYS Eye Camp was held in Myanmar, a first in the country.
          </p>
          <p className={pClassName}>
            We distributed prescription glasses to people from around Taunggyi and those living in
            Inle Lake at the two designated distribution venues. We also gave out stationery and
            sports equipment to orphans living in a temple at Inle Lake.
          </p>
          <p className={pClassName}>
            We have distributed prescription glasses around the world, starting from India to Nepal,
            the Philippines and Myanmar this time. Many people in Myanmar, be it children or adults,
            are deprived of the chance to enjoy a clear vision.
          </p>
          <p className={pClassName}>
            “Sharing the vision with the world.”
            <br />
            This is the motto of the OWNDAYS Eye Camp. To this end, we will continue travelling
            across borders to bring prescription glasses to those in need.
            <br />
            Instead of simply sending prescription glasses over, we choose to hand the glasses out
            to the recipients in person in order to witness and share their joy of finally having
            crystal clear vision.
          </p>
          <p className={pClassName}>
            We will continue to give out prescription glasses to the less privileged individuals in
            Myanmar so as to enrich their lives and help them see a clearer future.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/81PhesMrPnU',
    },
  },
  {
    id: '201810',
    tag: '#201810',
    title: {
      date: '2018.10',
      country: 'NEPAL',
      city: 'Jogimara, Dhadin',
    },
    isMoreDetail: true,
    detail: {
      date: '2018.10.25',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 15th OWNDAYS Eye Camp was held in Dhading District of Nepal.
          </p>
          <p className={pClassName}>
            From the capital city, Kathmandu, we drove 3 hours through winding, rocky roads to get
            to Jogimara where we distributed prescription glasses to the local people. We also gave
            out stationery and toys to children studying at a primary school situated within steep
            mountainous terrain.
            <br />
            Being our 4th Eye Camp there and having interacted with the local people several times,
            we have come to forged a stronger bond with both the adults and the children in Nepal.
            It was a great experience which we could not have acquired in our day-to-day lives at
            home and it had definitely contributed to our personal growth.
          </p>
          <p className={pClassName}>
            People living in mountainous regions often face issues such as poor accessibility and
            the lack of daily necessities. Besides, there are still many underprivileged people
            around the world who are unable to afford glasses or any form of visual corrective aid.
            <br />
            We have dedicated a portion of our profits to help these underprivileged people through
            the OWNDAYS Eye Camp. Guided by our philosophy of enriching the lives of people
            associated with OWNDAYS, we will continue to travel across borders to distribute
            prescription glasses and to share vision with the world.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/JcAIDMKY64g',
    },
  },
  {
    id: '201803',
    tag: '#201803',
    title: {
      date: '2018.03',
      country: 'NEPAL',
      city: 'Malekhu, Beni Ghat',
    },
    isMoreDetail: true,
    detail: {
      date: '2018.03.18',
      message: (
        <Fragment>
          <p className={pClassName}>The 12th OWNDAYS Eye Camp was held in Nepal.</p>
          <p className={pClassName}>
            There were 2 distribution venues this time. We first distributed prescription glasses at
            Malekhu, a small town that is an hour’s drive away from the capital city of Kathmandu.
            After which, we headed to Town Beni Ghat where we gave out toys and stationery to the
            children living there.
          </p>
          <p className={pClassName}>
            Being situated in the mountainous inland areas, economic growth in Malekhu and Beni Ghat
            has been slow. Public facilities are inadequate and supplies are usually limited due to
            transportation constraints. Many living in both towns face difficulties in obtaining
            daily necessities.
            <br />
            Through OWNDAYS Eye Camp, we were able to provide support, albeit just a small amount,
            to the needy people.
          </p>
          <p className={pClassName}>
            We have dedicated a portion of our global profits to help people who are less
            privileged. By doing so, we hope to enrich their lives. To us, each OWNDAYS Eye Camp is
            a reinforcement of the importance of our work.
            <br />
            By visiting local communities and giving out relief supplies to the needy people in
            person, we hope we would be able to enrich the lives of these people.
          </p>
          <p className={pClassName}>
            To this end, we will continue to travel across borders to help people in need through
            the OWNDAYS Eye Camp.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/yZEgdk1N_vE',
    },
  },
  {
    id: '201710',
    tag: '#201710',
    title: {
      date: '2017.10',
      country: 'NEPAL',
      city: 'Tandi, Chitwan',
    },
    isMoreDetail: true,
    detail: {
      date: '2017.10.06',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 11th OWNDAYS Eye Camp was held in Tandi, a city in the Chitwan District of Nepal.
            <br />
            From the capital city, Kathmandu, we travelled across steep mountains with prescription
            glasses prepared for needy local people who could not afford glasses. We also brought
            along books and toys that would be given out in a local school.
          </p>
          <p className={pClassName}>
            There are many ways to contribute to the society. For instance, one could simply send
            financial or material aids from home. However, in OWNDAYS, we choose to visit local
            communities in perso　n because we would like to provide the same level of service and
            quality to the recipients as we would to our customers back home. As we hand over our
            glasses, we are able to help the recipients adjust the glasses at the same time.
          </p>
          <p className={pClassName}>
            This time, we also raised donations in the form of an in-house virtual currency called
            OWNDAYS Miles. The donations were exchanged for toys and given out to children from poor
            families.
            <br />
            The OWNDAYS Eye Camp allows us to meet the local people face to face and to pass them
            our glasses personally. It is a reminder to us on how we could do our part for people in
            the world as a company.
          </p>
          <p className={pClassName}>
            In line with our corporate philosophy of enriching the lives of people related to
            OWNDAYS, we have pledged a portion of our profits to help needy people who are burdened
            by poor eyesight.
            <br />
            We will continue to provide prescription glasses to those in need through OWNDAYS Eye
            Camp.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/8vtbc7w9WnQ',
    },
  },
  {
    id: '201706',
    tag: '#201706',
    title: {
      date: '2017.06',
      country: 'TAIWAN',
      city: 'Datong Dist., Taipei City',
    },
    isMoreDetail: true,
    detail: {
      date: '2017.06.10',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 10th OWNDAYS Eye Camp was held in Taiwan. This was the first time the project was
            conducted there. We collaborated with Breeze Charity Foundation and distributed
            prescription glasses to the children in need.
          </p>
          <p className={pClassName}>
            We met many children from low income families who were unable to afford eyeglasses. This
            encounter has reminded us yet again that OWNDAYS is more than just an eyewear retailer.
            We are also helping to enrich the lives of others by bringing them a clearer, brighter
            future.
          </p>
          <p className={pClassName}>
            This OWNDAYS Eye Camp in Taiwan has brought us new encounters and reminded us the
            importance of having good vision. Let’s continue making a difference by sharing the
            vision with the world.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/U-Uz9q0sFHY',
    },
  },
  {
    id: '201705',
    tag: '#201705',
    title: {
      date: '2017.05',
      country: 'PHILIPPINES',
      city: 'Cabalawan, Tacloban City',
    },
    isMoreDetail: true,
    detail: {
      date: '2017.03.27 & 05.23',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 9th OWNDAYS Eye Camp was held in Cabalawan, Tacloban City, the Philippines in
            collaboration with Lions Club International and The Gift of Sight by Robinsons Malls.
          </p>
          <p className={pClassName}>
            In November 2013, the once beautiful city, formerly known as “The Beautiful City By The
            Bay”, was wrecked by the deadly Typhoon Haiyan. 4 years on, many people are still living
            in makeshift homes. The road to the city’s revival seems slow and difficult.
          </p>
          <p className={pClassName}>
            This time, we gave out eyeglasses to the needy people living in Cabalawan district of
            Tacloban. We also brought pictures books and toys for the children. We hope our OWNDAYS
            glasses could bring a little sunshine and joy to their lives.
          </p>
          <p className={pClassName}>
            In line with our philosophy of “enriching the lives of people associated with OWNDAYS”,
            we will continue to dedicate ourselves to helping the less fortunate and to sharing the
            vision with the world through OWNDAYS Eye Camp.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/Uyv2gmE1mIU',
    },
  },
  {
    id: '201612',
    tag: '#201612',
    title: {
      date: '2016.12',
      country: 'INDIA',
      city: 'Ayanar nagar village',
    },
    isMoreDetail: false,
  },
  {
    id: '201611',
    tag: '#',
    title: {
      date: '2016.11',
      country: 'PHILIPPINES',
      city: 'Pandacan, Manila',
    },
    isMoreDetail: true,
    detail: {
      date: '2016.11.08 & 23',
      message: (
        <Fragment>
          <p className={pClassName}>
            The 7th OWNDAYS Eye Camp was held in the Philippines. In partnership with ABS-CBN
            Lingkod Kapamilya Foundation Inc., the Eye Camp Team identified towns with the greatest
            need for vision correction before embarking on the mission. This was the first Eye Camp
            in the country and would definitely not be the last.
            <br />
            We distributed prescription eyeglasses to 200 children and adults. The recipients shared
            stories about the difficulties they experienced every day and how OWNDAYS Eye Camp could
            change their lives. We were very excited that we could help give them a clearer and more
            beautiful vision.
          </p>
          <p className={pClassName}>
            Through the OWNDAYS Eye Camp, we have met many people who require eyeglasses but are
            unable to obtain a pair due to financial difficulties. From children to adults, we hope
            to continue these efforts in enriching the lives of people through our eyeglasses. What
            we do day by day is more than merely selling eyeglasses, it is to give others the
            ability to see clearly. We are grateful to have another heart-warming experience in the
            OWNDAYS Eye Camp. In line with our mission of sharing vision with the world, we strive
            to continue making a difference to the world.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/eDG7RHFein0',
    },
  },
  {
    id: '201608',
    tag: '#201608',
    title: {
      date: '2016.08',
      country: 'INDIA',
      city: 'Madurai, Tamil Nadu',
    },
    isMoreDetail: true,
    detail: {
      date: '2016.08.24 - 25',
      message: (
        <Fragment>
          <p className={pClassName}>
            One year since the first OWNDAYS Eye Camp. This was our 6th Eye Camp and we returned to
            Madurai, India, the place where it was first held.
          </p>
          <p className={pClassName}>
            There were 4 distribution venues this time. We distributed glasses to the residents in
            Andar Kottaram Village, Ambethkar Nagar Village, Kathakinaru Village as well as students
            in a school in Kathakinaru Village.
            <br />
            At present, there are many people who require glasses but are unable to obtain a pair.
            We wish to do our part to help improve this situation no matter how small our effort may
            be. One person at a time, we hope from the bottom of our hearts that our glasses would
            put a smile on the Indian people.
          </p>
          <p className={pClassName}>
            In line with our mission to enrich the lives of everyone related to OWNDAYS, we will
            continue to do our part in order to share the wonders and joy of “being able to see
            clearly” with the world.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/0baXlYCP_zc',
    },
  },
  {
    id: '201604',
    tag: '#201604',
    title: {
      date: '2016.04',
      country: 'JAPAN',
      city: 'Kumamoto',
    },
    isMoreDetail: false,
  },
  {
    id: '201603',
    tag: '#201603',
    title: {
      date: '2016.03',
      country: 'NEPAL',
      city: 'Tokha, Kathmandu',
    },
    isMoreDetail: true,
    detail: {
      date: '2016.03.11',
      message: (
        <Fragment>
          <p className={pClassName}>
            In 2015, Nepal was hit by a deadly earthquake of an estimated 7.8 magnitude. It was a
            dreadful disaster that brought many buildings down in shatters, led to landslides and
            even triggered an avalanche.
            <br />
            With the thought of helping people in affected areas acquire good vision while they
            rebuild their homeland, we headed to Kathmandu, the capital city of Nepal for the 4th
            installment of the OWNDAYS Eye Camp.
          </p>
          <p className={pClassName}>
            This time, we brought along approximately 150 pairs of glasses with customised
            prescriptions for the recipients and we called upon OWNDAYS staff from around the world
            to go on this expedition in Nepal.
            <br />
            As we handed out the glasses, the Nepali people also gave us their widest smiles in
            return. Some put their palms together in a prayer like gesture while others held and
            shook our hands. Despite differences in language, we could feel their immense happiness
            and gratitude upon receiving the glasses.
          </p>
          <p className={pClassName}>
            We hope our efforts of giving out glasses to those who cannot afford it could contribute
            to the rebuilding of Nepal.
            <br />
            Everyone should have a chance to enjoy a clear vision and we aim to make this happen. We
            hope to enrich the lives of people around us through the gift of sight, step by step,
            one person after another.
          </p>
        </Fragment>
      ),
      vdo: 'https://www.youtube.com/embed/vBt08cpSXQE',
    },
  },
  {
    id: '201509',
    tag: '#201509',
    title: {
      date: '2015.09',
      country: 'INDIA',
      city: 'Madurai, Tamil Nadu',
    },
    isMoreDetail: true,
    detail: {
      date: '2015.09.04',
      message: (
        <Fragment>
          <p className={pClassName}>
            6,800 km from Japan.
            <br />
            In the rural parts of India, facilities available for eye examinations are often
            inadequate. As a result, many people living in these areas are unable to correct their
            vision.
            <br />
            To help these people overcome the problems and inconveniences caused by poor vision,
            OWNDAYS organized a volunteer expedition to Sakkimangalam Village, located in Madurai, a
            major city in the state of Tamil Nadu in southern India, where staff from the different
            countries came together to deliver glasses to the local communities there. Approximately
            100 pairs of prescription glasses were given out to people from 6 to 80 years old.
          </p>
          <p className={pClassName}>
            We cannot help but to reminisce in the joy we saw on these people’s faces when they
            could finally see clearly with their new glasses. Indeed, the joy of having good vision
            transcends borders and cultural differences.
            <br />
            From children to adults, we hope to continue our efforts in enriching the lives of
            people around us through OWNDAYS glasses. Whether it is one person or one thousand
            people, we hope we can make a difference to their lives.
          </p>
        </Fragment>
      ),
    },
  },
  {
    id: '201508',
    tag: '#201508',
    title: {
      date: '2015.08',
      country: 'INDIA',
      city: 'India',
    },
    isMoreDetail: false,
  },
  {
    id: '201507',
    tag: '#201507',
    title: {
      date: '2015.07',
      country: 'INDIA',
      city: 'Town Panjayat',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'Tsuyama General Gymnasium',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'Ishinomaki Junior High School ',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'HigashiMatsushima Municipal Daiichi Junior High School',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'Yamoto Daini Junior High School',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'Omagari Elementary School',
    },
    isMoreDetail: false,
  },
  {
    id: '201104',
    tag: '#201104',
    title: {
      date: '2011.04',
      country: 'JAPAN',
      city: 'Tagajo City Cultural Centre',
    },
    isMoreDetail: false,
  },
  {
    id: '201103',
    tag: '#201103',
    title: {
      date: '2011.03',
      country: 'JAPAN',
      city: 'Tagajo City Cultural Centre',
    },
    isMoreDetail: false,
  },
];
